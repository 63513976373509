

import React, { useEffect } from 'react'
import '../../styles/Banner.css'
import './chasma_Lexy.css';
import AnimatedCardsLexy from './chasma_animate_Lexy_cards'
import ServiceCardsLexy from './chasma _Lexy_service_cards';
import GetredycardLexy from './chasma_getredypink_Lexy_card';
import { Seo } from '../SEO/seo';
import Banner from '../chasma_analytics/Banner';

const ChasmaLexy = () => {

    useEffect(() => {
        document.title = 'Chasma Lexy';
    }, []);

    return (
        <>

            <Seo
                title= "Chatbots for HR | Artificial Intelligence in HR | Chasma  Place"
                description= "Exploring for an HR virtual assistant to automate most of your HR process? Learn how Chasma Lexy can extend your HR technology capability & improve your employee experience. "
                type= "website"
                name= "Chasma Lexy"
                url= 'https://chasma.io/chasmaapps/chasmalexy'
                image='https://chasma.io/static/media/chasma-Lexy.e926c064.png'
            />
            <Banner heading='Chasma Lexy' imgURL='chasma-Lexy' caption='AI-Enabled Virtual Assistant for Human Capital Management Applications'/>
     
            <AnimatedCardsLexy />
            <ServiceCardsLexy />
            <GetredycardLexy />



        </>
    )
}

export default ChasmaLexy