

import React, { useEffect } from 'react'
import '../../styles/Banner.css'
import './chasma_events.css';
import AnimatedCardEvents from './chasma_animate_events_cards';
import ServiceCardEvents from './chasma _events_service_cards';
import GetredycardEvents from './chasma_getredypink_events_card';

import Banner from '../chasma_analytics/Banner';
import { Seo } from '../SEO/seo';






const ChasmaEvents = () => {

    useEffect(() => {
        document.title = 'Chasma Events';
    }, []);

    return (
        <>

            <Seo
                title= "Event Management Automation| ILT Administration and Management | Chasma Place"
                description= " Automate invitations, RSVPs, details and directions for any size gathering and your ILT administration & management with Chasma Event."
                type= "website"
                name= "Chasma Events"
                url= 'https://chasma.io/chasmaapps/chasmaevent'
                image='https://chasma.io/static/media/chasma-events.acb4c221.png'
            />
            <Banner heading='Chasma Events' imgURL='chasma-events' caption='Agile administration for gatherings of any size'/>
           
            <AnimatedCardEvents />
            <ServiceCardEvents />
            <GetredycardEvents />



        </>
    )
}

export default ChasmaEvents