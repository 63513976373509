import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/chasma_home_section3.css'
import Icon1 from '../../assets/icon1.png'
import Icon2 from '../../assets/icon2.png'
import Icon3 from '../../assets/icon3.png'
import Icon4 from '../../assets/icon4.png'
import Icon5 from '../../assets/icon5.png'
import Icon6 from '../../assets/icon6.png'
import Icon7 from '../../assets/icon7.png'
import Icon8 from '../../assets/icon8.png'
import Icon9 from '../../assets/icon9.png'


// Images
import LinesImg from '../../assets/LinesImg.png'
import plusImagesLR from '../../assets/plusImagesLR.png'
import { Container } from 'react-bootstrap';

const Section3 = () => {
    return (
        <>
            <div className=' backgroundColor'>
                <Container className='container pt-lg-3 last p-2 py-5 px-lg-0 px-3 '>
                    <Row className='justify-content-center align-items-center'>

                        <Col md={8} lg={6} className='col-12 text-white'>
                            <div className='text-center pt-2 px-lg-5 '>
                                <p className='py-3  fs-6 fw-bold'>Designed for your people</p>
                                <h2 className='fw-bold'>Design And Digitize The
                                    Workplace Of The Future</h2>

                            </div></Col>
                    </Row>
                    <Row className='justify-content-center align-items-center pt-5'>

                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_home px-4 pt-5">
                                    <div className=" d-flex align-items-center">

                                        <div className=''><img src={Icon1} alt="Pre-configured
                                            Dashboard Designs-icon" className='' /></div>   <div className="fs-5 px-3 fw-bold">Pre-configured
                                            Dashboard Designs</div>

                                    </div>
                                    <p className="py-1">Personalized canvases and dashboards to build-user centered portals and landing pages.</p>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_home px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={Icon2} alt="Pre-configured
                                            Dashboard Designs-icon" className='' />
                                        </div>
                                        <div className="fs-5 px-3 fw-bold">User-friendly Design
                                            Interface</div>
                                    </div>
                                    <p className="py-3">Drag and drop widgets and components -- no programming.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_home px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''><img src={Icon3} alt="Pre-configured
                                            Dashboard Designs-icon" className='' /> </div>   <div className="fs-5 px-3 fw-bold">Operational Reporting
                                            Tools</div>

                                    </div>
                                    <p className="py-3">Pre-configured dashboards -- ready to deploy in your HR systems.
                                    </p>
                                </div>
                            </div>

                        </Col>

                        {/*------------ first 3 coloms ------------------*/}
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 py-lg-5 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_home px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''><img src={Icon4} alt="Pre-configured
                                            Dashboard Designs-icon" className='' /> </div>   <div className="fs-5 px-3 fw-bold">Data Visualizations</div>

                                    </div>
                                    <p className="py-3">Visualizations with the metrics that matter most to your executives.</p>
                                </div>
                            </div>

                        </Col>

                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3  py-lg-5 my-lg-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_home px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''><img src={Icon5} alt="Pre-configured
                                            Dashboard Designs-icon" className='' /> </div>   <div className="fs-5 px-3 fw-bold">AI-enhanced User Experience</div>

                                    </div>
                                    <p className="py-3">User centered experience using Artificial Intelligence based algorithms.</p>
                                </div>
                            </div>
                        </Col>

                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12 py-lg-5'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_home px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''><img src={Icon6} alt="Pre-configured
                                            Dashboard Designs-icon" className='' /> </div>   <div className="fs-5 px-3 fw-bold">Automated Testing</div>

                                    </div>
                                    <p className="py-3">Peace of mind with testing apps for validating updates in your cloud platforms.</p>
                                </div>
                            </div>

                        </Col>

                        {/*------------ 2nd 3 coloms ------------------*/}

                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>

                            <div className="obj-backward">



                                <div className="mx-auto card heightof_card_home px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''><img src={Icon7} alt="Pre-configured
                                            Dashboard Designs-icon" className='' /> </div>   <div className="fs-5 px-3 fw-bold">Security Management</div>

                                    </div>
                                    <p className="py-3">Apps built to create efficiencies in your cloud security and licensing.</p>
                                </div>
                            </div>


                        </Col>

                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_home px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''><img src={Icon8} alt="Pre-configured
                                            Dashboard Designs-icon" className='' /></div>   <div className="fs-5 px-3 fw-bold">User Engagement</div>

                                    </div>
                                    <p className="py-3">Motivational feedback and gamification tools that increase participation and engagement.</p>
                                </div>
                            </div>
                        </Col>

                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward last">

                                <div className="mx-auto card heightof_card_home px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''><img src={Icon9} alt="Pre-configured
                                            Dashboard Designs-icon" className='' /></div>   <div className="fs-5 px-3 fw-bold">Productivity Apps</div>

                                    </div>
                                    <p className="py-3">Streamline workflows and improve productivity.</p>
                                </div>
                                <div className='LinesImageContainer'>
                                    <img src={LinesImg} alt="" />
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <div className='plusImageLR'>
                        <img src={plusImagesLR} alt="" />
                    </div>
                </Container>


            </div>
        </>
    )
}

export default Section3