import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Chasma_logo from '../../assets/Chasma logo.svg'
import React from 'react'
import '../../styles/chasma_home_navbar.css'
import { BiChevronDown } from "react-icons/bi";
import Editior from '../../assets/CHasma Editor.png'
import Quale from '../../assets/Chasma Quale.png'
import Lexy from '../../assets/Lexy.png'
import Events from '../../assets/Chasma Event.png'
import NatioDesk from '../../assets/Natio Desk.png'
import Bot_Builder from '../../assets/Botbuilder.png'
import Cible from '../../assets/CIble.png'
import Sumtotal from '../../assets/SumTotal Analytics.png'
import HRBOX from '../../assets/People Analytics with HRBox.png'
import Cornstone from '../../assets/Cornerstone Analytics.png'
import POWER_BI from '../../assets/People Analytics With PowerBI.png'
import Workday from '../../assets/Workday Analytics.png'
import Connect from '../../assets/CHasma Editor.png'
import { Link } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState } from 'react'
import { Container } from 'react-bootstrap';




const Navbarcomponent = () => {
    
    const [showDropdown, setShowDropdown] = useState(false);
   
    const handleMouseEnter = () => {
        
        setShowDropdown(true);
        console.log(showDropdown);
    };

    const handleMouseLeave = () => {


        setShowDropdown(false);
    };
    const [showDropdownCompany, setShowDropdownCompany] = useState(false);
    const handleMouseEnterCompany = () => {
        setShowDropdownCompany(true);
    }
    const handleMouseLeaveCompany = () => {
        setShowDropdownCompany(false);
    }



    const [showDropdownResources, setShowDropdownResources] = useState(false);
    const handleMouseEnterResources = () => {
        setShowDropdownResources(true);
    }
    const handleMouseLeaveResources = () => {
        setShowDropdownResources(false);
    }
        const handleLinkClick = () => {
            setShowDropdown(false);
            setShowDropdownCompany(false);
            setShowDropdownResources(false);
            // Scroll to the top of the page
            window.scrollTo(0, 0);
        };
         // Scroll to the top of the page
    

    return (
        <>
            
            <Navbar expand="lg" className={` bgmd py-3 px-lg-5 px-md-3  px-2 navbar bg-light fixed-top`} id="navbar mynav">
            <Container className=''>
                <Navbar.Brand href="/" className=''>
                    <img
                        src={Chasma_logo}
                        alt="Chasma-logo"
                        className="img-fluid logo"
                        id="logo"
                        style={{ width: '200px', }}

                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav " />
                <Navbar.Collapse  style={{ transition: "0.2s ease-in-out" }} id="basic-navbar-nav d-flex justify-content-center" >
                    <Nav className=" ms-auto">

                        {/*------------------------------------Products---------------------------------*/}

                        <Nav>
                            <NavDropdown className='px-lg-3 px-xl-4 Company'
                                title={
                                    <span className='products' onMouseEnter={()=>{handleMouseEnter()}} onMouseLeave={handleMouseLeave} >
                                        Products <BiChevronDown />
                                    </span>
                                }
                                id="basic-nav-dropdown" show={showDropdown}>
                                <Row className=' size p-3 row-products'  onClick={()=>{setShowDropdown(false)}} >
                                    <Col md={3}>
                                        <NavDropdown.Item className='px-1' as={Link}
                                             to={'/chasmaapps'}  onClick={()=>{handleLinkClick()}} >
                                            <span className='fw-bold nav-fonts' >Apps</span>
                                        </NavDropdown.Item>

                                        <NavDropdown.Item className='px-1'
                                             onClick={handleLinkClick}
                                            as={Link}
                                             to={'/chasmaapps/chasmaeditor'}

                                        >
                                            <img
                                                src={Editior}
                                                alt="Chasma-editor"
                                                className="img-fluid"
                                                id="icons"
                                            />
                                            <span className='px-1 editor nav-fonts' id='nav_links' >  Chasma Editor</span>
                                        </NavDropdown.Item>

                                        <NavDropdown.Item className='px-1' onClick={handleLinkClick}
                                            as={Link}
                                             to={'/chasmaapps/chasmaevent'}
                                        >
                                            <img
                                                src={Events}
                                                alt="Chasma-events"
                                                className="img-fluid"
                                                id="icons"
                                            />
                                            <span className='px-2 editor nav-fonts' id='nav_links' >Chasma Event</span>
                                        </NavDropdown.Item>

                                        <NavDropdown.Item className='px-1' onClick={handleLinkClick}
                                            as={Link}
                                            to={'/chasmaapps/chasmaquale'}>


                                            <img
                                                src={Quale}
                                                alt="Chasma-Quale"
                                                className="img-fluid"
                                                id="icons"
                                            />

                                            <span className='px-2 editor nav-fonts' id='nav_links' >Chasma Quale</span>
                                        </NavDropdown.Item>

                                        <NavDropdown.Item className='px-1' onClick={handleLinkClick}
                                            as={Link}
                                            to={'/chasmaapps/chasmalexy'}
                                        >
                                            <img
                                                src={Lexy}
                                                alt="Chasma-Lexy"
                                                className="img-fluid"
                                                id="icons"
                                            />

                                            <span className='px-2 editor nav-fonts' id='nav_links' >Chasma Lexy</span>

                                        </NavDropdown.Item>

                                        <NavDropdown.Item className='px-1' onClick={handleLinkClick}
                                            as={Link}
                                            to={'/chasmaapps/chasmanatiodesk'}>
                                            <img
                                                src={NatioDesk}
                                                alt="Chasma-NatioDesk"
                                                className="img-fluid"
                                                id="icons" />
                                            <span className='px-2 editor nav-fonts' id='nav_links' >Natio Desk</span>
                                        </NavDropdown.Item>

                                        <NavDropdown.Item className='px-1' onClick={handleLinkClick}
                                            as={Link}
                                            to={'/chasmaapps/chasmabotbuilder'}
                                        >
                                            <img
                                                src={Bot_Builder}
                                                alt="Chasma-Bot-Builder"
                                                className="img-fluid"
                                                id="icons"
                                            />
                                            <span className='px-2 editor nav-fonts' id='nav_links'>Bot Builder</span>


                                        </NavDropdown.Item>

                                        <NavDropdown.Item className='px-1' onClick={handleLinkClick}
                                            as={Link}
                                            to={'/chasmaapps/chasmacible'}>
                                            <img
                                                src={Cible}
                                                alt="Chasma-Cible"
                                                className="img-fluid"
                                                id="icons"
                                            />
                                            <span className='px-2 editor nav-fonts' id='nav_links'>Cible</span>


                                        </NavDropdown.Item>
                                    </Col>

                                    <Col md={5}>

                                        <NavDropdown.Item className='px-1' onClick={handleLinkClick}
                                            as={Link}
                                            to={'/chasmaanalytics'}
                                        >
                                            <span className='fw-bold nav-fonts' >Analytics</span>
                                        </NavDropdown.Item>

                                        <NavDropdown.Item className='px-1' onClick={handleLinkClick}
                                            as={Link}
                                            to={'/chasmaanalytics/sumtotal-analytics'}><img
                                                src={Sumtotal}
                                                alt="Chasma-Sumtotal analytics"
                                                className="img-fluid"
                                                id="icons"
                                            />
                                            <span className='px-2 editor nav-fonts' id='nav_links'>SumTotal Analytics</span>
                                        </NavDropdown.Item>

                                        <NavDropdown.Item className='px-1' onClick={handleLinkClick}
                                            as={Link}
                                            to={'chasmaanalytics/chasmahrbox'}
                                        >


                                            <img
                                                src={HRBOX}
                                                alt="Chasma-HRBOX"
                                                className="img-fluid"
                                                id="icons"
                                            />
                                            <span className='px-2 editor nav-fonts' id='nav_links'>People Analytics with HRBOX</span>

                                        </NavDropdown.Item>

                                        <NavDropdown.Item className='px-1' onClick={handleLinkClick}
                                            as={Link}
                                            to={'/chasmaanalytics/cornerstone-analytics'}
                                        >
                                            <img
                                                src={Cornstone}
                                                alt="Chasma-Cornerstone_Analytics"
                                                className="img-fluid"
                                                id="icons"
                                            />

                                            <span className='px-2 editor nav-fonts' id='nav_links'>Cornerstone Analytics</span>

                                        </NavDropdown.Item>

                                        <NavDropdown.Item className='px-1' onClick={handleLinkClick}
                                            as={Link}
                                            to={'/chasmaanalytics/people-analytics-power-bi'}>
                                            <img
                                                src={POWER_BI}
                                                alt="Chasma-power-bi"
                                                className="img-fluid"
                                                id="icons"
                                            />
                                            <span className='px-2 editor nav-fonts' id='nav_links'>People Analytics with PowerBI</span>
                                        </NavDropdown.Item>

                                        <NavDropdown.Item className='px-1' onClick={handleLinkClick}
                                            as={Link}
                                            to={'/chasmaanalytics/workday-analytics'}>

                                            <img
                                                src={Workday}
                                                alt="Chasma-Workday Analytics"
                                                className="img-fluid"
                                                id="icons"
                                            />
                                            <span className='px-2 editor nav-fonts' id='nav_links'>Workday Analytics</span>


                                        </NavDropdown.Item>
                                    </Col>

                                    <Col md={4}>
                                        <NavDropdown.Item className='px-1'>
                                            <span className='fw-bold nav-fonts'>Connectors</span>
                                        </NavDropdown.Item>

                                        <NavDropdown.Item className='px-1' onClick={handleLinkClick}
                                            as={Link}
                                            to={'/chasmaconnect'}>
                                            <img
                                                src={Connect}
                                                alt="Chasma-connect"
                                                className="img-fluid"
                                                id="icons"
                                            />
                                            <span className='px-2 editor nav-fonts' id='nav_links'>Chasma Connect</span>
                                        </NavDropdown.Item>               

                                    </Col>
                                </Row>

                            </NavDropdown>
                        </Nav>

                        {/*------------------------------------Company---------------------------------*/}

                        <Nav>
                            <NavDropdown className='px-lg-3 px-xl-4 Company' title={
                                <span onMouseEnter={handleMouseEnterCompany} onMouseLeave={handleMouseLeaveCompany}>
                                    Company<BiChevronDown />
                                </span>
                            }
                                id="basic-nav-dropdown" show={showDropdownCompany}>
                                <Row className='d-flex p-3 '>
                                    <Col>
                                        <NavDropdown.Item className='px-1' onClick={handleLinkClick} as={Link}
                                            to={'/aboutus'}><span className='nav-fonts'>About Chasma</span></NavDropdown.Item>
                                        <NavDropdown.Item className='px-1' onClick={handleLinkClick} as={Link}
                                            to={'/Customers'}><span className='nav-fonts'>Customers</span></NavDropdown.Item>
                                    </Col>
                                </Row >
                            </NavDropdown>
                        </Nav>

                        {/*------------------------------------Resources---------------------------------*/}

                        <Nav>
                            <NavDropdown className='px-lg-3 px-xl-4 Company' title={
                                <span onMouseEnter={handleMouseEnterResources} onMouseLeave={handleMouseLeaveResources}>
                                    Resources <BiChevronDown />
                                </span>
                            }
                                id="basic-nav-dropdown" show={showDropdownResources}>
                                <Row className='d-flex p-3'>
                                    <Col>
                                        <NavDropdown.Item className='px-1 nav-fonts' href="http://blog.chasma.io/bloghome">Blog<Link to="/Blog"></Link></NavDropdown.Item>
                                        <NavDropdown.Item className='px-1 nav-fonts' href="http://resources.chasma.io/resourceshome">Resource<Link to="/Resource"></Link> </NavDropdown.Item>

                                    </Col>
                                </Row>
                            </NavDropdown>

                        </Nav>

                        {/*------------------------------------Contact-us---------------------------------*/}

                        <Nav.Link as={Link} to={'/contactus'} onClick={handleLinkClick} className='px-lg-3 px-xl-4'><span className='nav-fonts'>Contact Us</span></Nav.Link>
                    </Nav>


                    {/*------------------------------------Login---------------------------------*/}

                    <Nav className='ms-auto mx-xs-3 '>
                        <a className=' ms-3 ' href="https://app.chasma.io/login" target='blank'> <Button variant="outline-dark" className="mx-lg-2 mx-xl-3 fnt"> Login</Button></a>
                    </Nav>
                </Navbar.Collapse>
               </Container>
            </Navbar>

        </>
    );
}

export default Navbarcomponent;




