import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import './chasma_NatioDesk.css';



// icon images------------

import Quickandeasyadministration from '../../assets/Natio-desk-icons/Layer 25.png'
import CustomizeSLArulesforeachcustomer from '../../assets/Natio-desk-icons/search.png'
import Threepricinglevels from '../../assets/Natio-desk-icons/_x36_79_x2C__Analysis_x2C__currency_x2C__dollar_x2C__graph_x2C__money.png'
import ACustombrandingtomake from '../../assets/Natio-desk-icons/brand.png'
import Dashboardsandreportsto from '../../assets/Natio-desk-icons/fluctuation.png'
import StartwithFAQs from '../../assets/Natio-desk-icons/question-answer.png'



function serviceCards() {
    return (
        <>
            <div className='px-4'>
           
            </div>
            <div className='backgroundColor'>
                <Container className='pt-lg-2 stars-attach' >
                
                   

                    <Row className='justify-content-center align-items-center'>
                        <Col md={8} lg={11} className='col-12 text-white'>

                            <div className='text-center pt-5 px-lg-5 '>

                                <h1 className='fw-bold'>Provide fast, personalized service to your customers and internal user groups.</h1>
                               
                            </div></Col>
                    </Row>
                    <Row className='justify-content-center align-items-center pt-5'>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_natiodesk px-4 pt-5">
                                    <div className=" d-flex align-items-center justify-content-center">

                                        <div className=''>
                                            <img src={Quickandeasyadministration} alt="Quick and easy administration to set up and manage unlimited customer accounts" className='' />
                                        </div>
                                       
                                    </div>
                                    <p className="py-3">Quick and easy administration to set up and manage unlimited customer accounts.</p>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_natiodesk px-4 pt-5">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className=''>
                                            <img src={CustomizeSLArulesforeachcustomer} alt="Customize SLA rules for each customer to make your service flexible and responsive to their needs" className='' />
                                        </div>
                                      
                                    </div>
                                    <p className="py-3">Customize SLA rules for each customer to make your service flexible and responsive to their needs.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_natiodesk px-4 pt-5">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className=''>
                                            <img src={Threepricinglevels} alt="Three pricing levels to fit your budget and support needs." className='' />
                                        </div>
                                       
                                    </div>
                                    <p className="py-3">Three pricing levels to fit your budget and support needs.
                                    </p>
                                </div>
                            </div>

                        </Col>


                        {/*------------ first 3 coloms ------------------*/}
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 py-lg-5 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_natiodesk px-4 pt-5">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className=''>
                                            <img src={ACustombrandingtomake} alt="application uniquely your own." className='' />
                                        </div>
                                    </div>
                                    <p className="py-3">A Custom branding to make the application uniquely your own.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3  py-lg-5 my-lg-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_natiodesk px-4 pt-5">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className=''>
                                            <img src={Dashboardsandreportsto} alt="analyze bottlenecks and gaps." className='' />
                                        </div>
                                        
                                    </div>
                                    <p className="py-3">Dashboards and reports to view status and progress of issues, manage workflow, and analyze bottlenecks and gaps.</p>
                                </div>
                            </div>
                        </Col>

                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12 py-lg-5'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_natiodesk px-4 pt-5">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className=''>
                                            <img src={StartwithFAQs} alt="Q&As to build a customer knowledge" className='' />
                                        </div>
                                      
                                    </div>
                                    <p className="py-3">Start with FAQs and capture Q&As to build a customer knowledge base.</p>
                                </div>
                            </div>

                        </Col>

                    </Row>


                </Container>
            </div></>
    )
}

export default serviceCards