import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './chasma_Cible.css';
import ClintgridcardsCible from './chasma_Cible_clint_cards'
import Carousel from '../chasma_editor/owl_carousel'
import Iframe from 'react-iframe'
const GetredycardCible = () => {
    return (
        <>
            <div className='px-5 container'>

                <Row className=' justify-content-center align-items-center mt-lg-4 mt-md-3 mt-3'>


                    <Col lg={6} md={12} sm={12} xs={12} className="px-xl-4 px-lg-5 px-3 px-md-4 px-sm-3 ">
                        <div className='text-center text-lg-start '>

                            <h1 className="fw-bold">Why Chasma Cible?</h1>
                        </div>

                        <p className="mb-0">Chasma Cible shows you detailed diagrams of user behavior or any other online process so you can see exactly where the trouble spots are. Watch the video to see how you can gain a new understanding of how your people react to the experience you designed for them.

                        </p>
                      
                    </Col>

                    <Col lg={6} md={12} sm={12} xs={12} className="video-container my-lg-0 my-2"  >
                        <div className="video-container">
                            <Iframe height="100%" width="100%" src="https://player.vimeo.com/video/228929858?title=0&byline=0&portrait=0" frameborder="0" allowfullscreen />
                            <div className='plusImageContainer'>
                                
                            </div>
                        </div>

                    </Col>

                    


                </Row>



                <ClintgridcardsCible />

                <Row className='justify-content-center align-items-center'>
                    <div className='mt-lg-5 mt-md-3 mt-2'></div>


                    <Col lg={6} md={12} sm={12} xs={12} className="px-xl-4 px-lg-5 px-3 px-md-4 px-sm-3 ">
                        <div className='text-center text-lg-start '>

                            <h1 className="fw-bold">Explore Chasma Editor</h1>
                        </div>

                        <p className="mb-0 py-3">We are on a mission to address HR technology needs in shorter and faster cycles to maximize your potential, so you can reduce your administrative overhead and focus on strategy.

                        </p>
                        <p>Your technology should not call the tune for you to dance; it should dance to the music in your critical processes.</p>
                       
                    </Col>


                    
                    <Col lg={6} md={12} sm={12} xs={12} className='overflow-hidden ' >
                        <div className='text-center text-lg-start d-flex justify-content-center align-items-center p-5'>
                            <Carousel />
                        </div>
                    </Col>
                </Row>

            </div>
        </>
    )
}

export default GetredycardCible