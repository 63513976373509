import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import './chasma_Lexy.css';



// icon images------------

import FastResponse from '../../assets/Lexy-icons/velocity.png'
import CognitiveAnalytics from '../../assets/Lexy-icons/analytics.png'
import AIAssistedSearch from '../../assets/Lexy-icons/ai-knowledge.png'
import SingleDashboardforHR from '../../assets/Lexy-icons/analytics (1).png'
import CollaborationCompatible from '../../assets/Lexy-icons/puzzle.png'
import IntegratedAssistance from '../../assets/Lexy-icons/help.png'



function serviceCards() {
    return (
        <>
            <div className='backgroundColor'>
                <Container className='pt-lg-2 stars-attach' >
                  

                    <Row className='justify-content-center align-items-center'>
                        <Col md={8} lg={11} className='col-12 text-white'>

                            <div className='text-center pt-5 px-lg-5 '>

                                <h1 className='fw-bold'>Chasma Lexy Advantage</h1>
                                <p className='py-md-4 px-md-5 px-2 '>Lexy by Chasma helps you streamline the user experience while extending the capabilities of your HR technology.</p>

                            </div></Col>
                    </Row>
                    <Row className='justify-content-center align-items-center pt-5'>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_Lexy px-4 pt-5">
                                    <div className=" d-flex align-items-center">

                                        <div className=''>
                                            <img src={FastResponse} alt="Fast Response" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Fast Response</div>

                                    </div>
                                    <p className="py-3">Employees don’t have to wait for a callback or email from overworked staff.</p>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_Lexy px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={CognitiveAnalytics} alt="Cognitive Analytics" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Cognitive Analytics</div>

                                    </div>
                                    <p className="py-3">Capture user questions so you can analyze your organization’s needs.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_Lexy px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={AIAssistedSearch} alt="AI-Assisted Search" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">AI-Assisted Search</div>

                                    </div>
                                    <p className="py-3">Find policies, procedures, documents and forms on your intranet or any storage site.
                                    </p>
                                </div>
                            </div>

                        </Col>


                        {/*------------ first 3 coloms ------------------*/}
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 py-lg-5 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_Lexy px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={SingleDashboardforHR} alt="Single Dashboard for HR Notifications" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Single Dashboard for HR Notifications</div>

                                    </div>
                                    <p className="py-3">Integrated lightweight ticketing portal just for HR needs.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3  py-lg-5 my-lg-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_Lexy px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={CollaborationCompatible} alt="Collaboration Compatible" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Collaboration Compatible</div>

                                    </div>
                                    <p className="py-3">Deploy Lexy in Slack, Microsoft Teams and Skype.</p>
                                </div>
                            </div>
                        </Col>

                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12 py-lg-5'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_Lexy px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={IntegratedAssistance} alt="Integrated Assistance" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Integrated Assistance</div>

                                    </div>
                                    <p className="py-3">Lexy works with Cortana, Siri and Alexa for Business.</p>
                                </div>
                            </div>

                        </Col>

                    </Row>


                </Container>
            </div></>
    )
}

export default serviceCards