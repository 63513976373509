
import React, { useState, useEffect } from 'react'
import './Connect_Carousel.css'

import { BiChevronRight, BiChevronLeft } from "react-icons/bi";


const MAX_VISIBILITY = 3;
const Slides = [
  {
    id: 1,
    url: "Connect1",
    alt: "Chasma Editor Admin Dashboard"
  },
  {
    id: 2,
    url: "Connect2",
    alt: "Chasma_Editor_Manager1"
  },
  {
    id: 3,
    url: "Connect3",
    alt: "Chasma_Editor_Manager2"
  },
  {
    id: 4,
    url: "Connect4",
    alt: "Chasma_Editor_Manager3"
  },
]


const Carousel = ({ children }) => {
  const [active, setActive] = useState(2);
  useEffect(() => {
    const interval = setInterval(() => {
      setActive((prevIndex) => (prevIndex + 1) % Slides.length);
    }, 5000); // Autoscroll every 5 seconds

    return () => clearInterval(interval);
  }, []);
  const count = React.Children.count(children);

  return (
    <div className='carousel'>
      {active > 0 && <button className='nav_bar left' onClick={() => setActive(i => i - 1)}><BiChevronLeft className='icon-color' /></button>}
      {React.Children.map(children, (child, i) => (
        <div className='card-container d-flex align-items-center justify-content-center' style={{
          '--active': i === active ? 1 : 0,
          '--offset': (active - i) / 3,
          '--direction': Math.sign(active - i),
          '--abs-offset': Math.abs(active - i) / 3,
          'pointer-events': active === i ? 'auto' : 'none',
          'opacity': Math.abs(active - i) >= MAX_VISIBILITY ? '0' : '1',
          'display': Math.abs(active - i) > MAX_VISIBILITY ? 'none' : 'block',
        }}>
          {child}
        </div>
      ))}
      {active < count - 1 && <button className='nav_bar right' onClick={() => setActive(i => i + 1)}><BiChevronRight className='icon-color' /></button>}
    </div>
  );
};

const App = () => (
  <div className='app'>
    <Carousel>
      {Slides.map((image) => {
        const { id, url, alt } = image;
        return <img key={id} src={require(`../../assets/ChasmaConnectersCarouselImages/${url}.jpg`)} alt={alt} className='image-resulution' />
      })}

    </Carousel>
  </div>
);



export default App