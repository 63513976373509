

import React, { useEffect } from 'react'


import './chasma_Cible.css';
import AnimatedCardsCible from './chasma_animate_Cible_cards';
import ServiceCardsCible from './chasma _Cible_service_cards'
import GetredycardCible from './chasma_getredypink_Cible_card';

import Banner from '../chasma_analytics/Banner';
import { Seo } from '../SEO/seo';






const ChasmaCible = () => {

    useEffect(() => {
        document.title = 'Chasma Cibil';
    }, []);

    return (
        <>
            <Seo
                title= "Chasma Cible User Flow and Behavior Tracking | Customer behavior tracking "
                description= "Get visual insights into user flow so you can analyze the needs of your customers, employees, or any other user group."
                type= "website"
                name= "Chasma Cible"
                url= 'https://chasma.io/chasmabotbuilder'
                image='https://chasma.io/static/media/chasma-cible.5af841b2.png'
            />

            <Banner heading='Chasma Cible' imgURL='chasma-cible' caption='User Flow and Behavior Analysis for Your Enterprise Applications'/>
            <AnimatedCardsCible />
            <ServiceCardsCible />
            <GetredycardCible />



        </>
    )
}

export default ChasmaCible