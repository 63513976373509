

import React, { useEffect } from 'react'
import EditorImage from '../../assets/PrivacyPolicy.png'
import './privacy_Policy.css'
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/esm/Container';
import ClientCards from'../Cornerstone_Analytics/Cornerstone_Analytics_clint_cards'
import { Seo } from '../SEO/seo';



const PrivacyPolicy = () => {

    useEffect(() => {
        document.title = 'Chasma | Pricacy Policy';
    }, []);

    return (
        <>
            <Seo
                title= "Chasma Place | Privacy Policy"
                description= "Chasma respects your privacy. This privacy policy sets our privacy practices & describes the ways we use the information provided or collected on our website. "
                type= "website"
                name= "Chasma Privacy Policy"
                url= 'https://chasma.io/privacy-policy '
                image='https://chasma.io/static/media/PrivacyPolicy.a0953fc2.png'
            />
            <Container className='bannerPrivacyPolicy'>
                <div className='w-50  d-flex  align-items-center '>
                    <h1>Privacy Policy</h1>
                </div>
                <div className='w-50'><img src={EditorImage} className='img-fluid' alt="chasma-privacy-policy" /></div>
            </Container>
            <div className='mt-4 pt-2 px-5  container'>
                <h4 className='text-center fw-bold mt-4 mb-4 '>Privacy Policy</h4>
                <Row>

                    <p>ChasmaIO ("us", "we", or "our") operates the Chasma.io website (the "Service").</p>
                    <p>This page informs you of our policies regarding the collection, use and disclosure of Personal Information when you use our Service.                    </p>
                    <p>We will not use or share your information with anyone except as described in this Privacy Policy.</p>
                    <p>We use your Personal Information for providing and improving the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible at Chasma.io</p>
                </Row>
                <Row>
                    <h4>Information Collection And Use</h4>
                    <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your email address, name, phone number, postal address, other information ("Personal Information").</p>
                    <p>
                        We collect this information for the purpose of providing the Service, identifying and communicating with you, responding to your requests/inquiries, servicing your purchase orders, and improving our services.

                    </p>


                </Row>
                <Row>
                    <h4>Log Data</h4>
                    <p>
                        We may also collect information that your browser sends whenever you visit our Service ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages and other statistics.

                    </p>
                    <p>
                        In addition, we may use third party services such as Google Analytics that collect, monitor and analyze this type of information in order to increase our Service's functionality. These third party service providers have their own privacy policies addressing how they use such information.

                    </p>

                </Row>
                <Row>
                    <h4>
                        Rights available to the Data Subjects

                    </h4>
                    <p>
                        Data subjects will have the following rights over the data shared-

                    </p>
                    <p>To be informed on the usage of their personal data</p>
                    <p>To access, correct, modify the data and review whether the data is lawfully processed</p>
                    <p>To request erasure of the data in certain situations</p>
                    <p>To object processing of personal data if we:</p>
                    <p>Process the data based on legitimate interests or the performance of a task in the public interest as an exercise of official authority (including profiling);</p>
                    <p>Use the data for direct marketing (including profiling); or</p>
                    <p>Process the data for purposes of scientific/historical research and statistics.</p>
                </Row>
                <Row>
                
                <h4>Cookies</h4>
                <p>Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and transferred to your device. We use cookies to collect information in order to improve our services for you.</p>

                </Row>
                <Row>
                    <ClientCards/>
                </Row>
                    
            </div>
        </>
    )
}

export default PrivacyPolicy