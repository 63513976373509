import React from 'react'
import Icon1 from "../../assets/Editor icon 1.png"
import Icon2 from '../../assets/editor icon2.png'
import Icon3 from '../../assets/eeditor icon 3.png'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/chasma_home_section2.css'


const AnimatedCards = () => {
    return (
        <>
            <div className='px-4 container'>
            <Row className='justify-content-center align-items-center'>
                    <Col md={11} lg={10} className='col-12'>
                    <div className='pt-3 pt-lg-3'></div>
                        <div className='text-center pt-lg-2  '>

                            <h1 className='fw-bold'>Pre-designed dashboards to help create engaging, user-friendly landing pages</h1>

                            <p className='py-3'>Most HR technology provides a way to brand your application home pages or landing pages. Many also provide the tools to link to resources or other applications.
                            </p>
                            <p className=''>
                                But the scope of what you can do is often limited by design or by the skills required to make those home pages a reality. And too often, organizations find they don’t have the resources or design experience to make it happen.
                            </p>
                        </div></Col>
                </Row>
            </div>
            <Container fluid='md' className='my-lg-5 my-3'>
                <div>
               
                    <Row className='justify-content-center align-items-center'>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12 '>
                            <div className="obj-backward">
                                <div className="Redcard"></div>
                                <div className="mx-auto card height-adj px-3 content" id='height-adj' >

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-3  bring-front" src={Icon1} alt="img" />

                                            <p className=" py-4 fs-lg-5 px-3">Make your user portal or landing page a hub of collaborative activity.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">
                                <div className="Redcard"></div>
                                <div className="mx-auto card height-adj px-3 content" id='height-adj' >

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-2  bring-front" src={Icon2} alt="img" />

                                            <p className="py-4 fs-lg-5 px-3">Improve the user experience by eliminating friction.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">
                                <div className="Redcard" ></div>
                                <div className="mx-auto card height-adj px-3 content" id='height-adj'>

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-2  bring-front" src={Icon3} alt="img" />
                                            <p className=" py-4 fs-lg-5 px-3">Achieve greater user adoption inHR management applications.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>

                    </Row>
                </div>
            </Container>


        </>
    )
}

export default AnimatedCards