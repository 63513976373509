

import React, { useEffect } from 'react'
import { Seo } from '../SEO/seo';
import './chasma_Quale.css';
import AnimatedCardsQuale from './chasma_animate_Quale_cards'
import ServiceCardsQuale from './chasma _Quale_service_cards';
import GetredycardQuale from './chasma_getredypink_Quale_card';

import Banner from '../chasma_analytics/Banner';







const ChasmaQuale = () => {

    useEffect(() => {
        document.title = 'Chasma Quale';
    }, []);

    return (
        <>
             <Seo
                title= "Automated Software Tool | SumTotal Update Testing | Chasma Place"
                description= "Eliminate repetitive manual testing and save your time & money with Chasma Quale's automated HR software testing process."
                type= "website"
                name= "Chasma Quale"
                url= 'https://chasma.io/chasmaapps/chasmaquale'
                image='https://chasma.io/static/media/chasma-quale.f6cf2b20.png'
            />

             <Banner heading='Chasma Quale' imgURL='chasma-quale' caption='Automated Cloud Validation Platform'/>
           
        
            <AnimatedCardsQuale />
            <ServiceCardsQuale />
            <GetredycardQuale />



        </>
    )
}

export default ChasmaQuale