import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import "./chasma_connect.css";
import Carousel from "./Connect_Carousel";
import Iframe from "react-iframe";
import ClientCards from './chasma_connect_clint_cards'

const Getredycard = () => {
    return (
        <>
            <Container>
                <Row className=" justify-content-center align-items-center mt-lg-4 mt-md-3 mt-3">
                    <Col
                        lg={6}
                        md={12}
                        sm={12}
                        xs={12}
                        className="px-xl-4 px-lg-5 px-3 px-md-4 px-sm-3 mt-5 "
                    >
                        <div className="text-center text-lg-start ">
                            <h2 className="fw-bold">Why Chasma Connect?</h2>
                        </div>

                        <p className="mb-0 pr-2">
                            You don’t need to spend weeks or months developing custom integrations for your HR application platforms.
                             Watch the video to see how Chasma Connect offers you prefabricated plug and play connectors, 
                             integrators, ready to deploy into your applications.
                        </p>
                    </Col>

                    <Col lg={6} md={12} sm={12} xs={12} className="video-container">
                        <div className="video-container">
                            <Iframe
                                height="100%"
                                width="100%"
                                src="https://player.vimeo.com/video/417196028?h=028e9c3c16&title=0&byline=0&portrait=0"
                                frameborder="0"
                                allowfullscreen
                            />
                        </div>
                    </Col>


                </Row>
                <ClientCards />
                <Row className="justify-content-center align-items-center">
                    <div className="mt-lg-5 mt-md-3 mt-2"></div>

                    <Col
                        lg={6}
                        md={12}
                        sm={12}
                        xs={12}
                        className="px-xl-4 px-lg-5 px-3 px-md-4 px-sm-3 "
                    >
                        <div className="text-center text-lg-start ">
                            <h2 className="fw-bold">Explore Chasma Connectors</h2>
                        </div>

                        <p className="mb-0 py-3">
                            We are on a mission to address HR technology needs in shorter and
                            faster cycles to maximize your potential, so you can reduce your
                            administrative overhead and focus on strategy.
                        </p>
                        <p>
                            Your technology should not call the tune for you to dance; it
                            should dance to the music in your critical processes.
                        </p>
                    </Col>

                    <Col lg={6} md={12} sm={12} xs={12} className="overflow-hidden ">
                        <div className="text-center text-lg-start d-flex justify-content-center align-items-center p-5">
                            <Carousel />
                        </div>
                    </Col>
                </Row>
            </Container>
       
        </>
    );
};

export default Getredycard;
