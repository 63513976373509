import React from 'react'
import Icon1 from "../../assets/Animated-Icons/Quale/quale-icon-1.png"
import Icon2 from "../../assets/Animated-Icons/Quale/quale-icon-2.png"
import Icon3 from "../../assets/Animated-Icons/Quale/quale-icon-3.png"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/chasma_home_section2.css'


const AnimatedCardsQuale = () => {
    return (
        <>
            <Container fluid='md' className='my-lg-4 my-2'>
                <div>
                <Row className='justify-content-center align-items-center'>
                    <Col md={11} lg={10} className='col-12'>
                        <div className='pt-2'></div>
                        <div className='text-center pt-1  '>

                            <h1 className='fw-bold'>An automated testing solution that eliminates repetitive manual testing</h1>

                            <p className='py-3'>With a library of 10,000+ testing scenarios (and growing), Quale handles routine tasks so you can focus on critical, high-value testing.
                            </p>

                        </div></Col>
                </Row>
                    <Row className='justify-content-center align-items-center'>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12 '>
                            <div className="obj-backward">
                                <div className="Redcard"></div>
                                <div className="mx-auto card height-adj px-3 content" id='height-adj'>

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-3  bring-front" src={Icon1} alt="img" />

                                            <p className=" py-4 fs-lg-5 px-3">Reduce labor costs and eliminate human error.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">
                                <div className="Redcard"></div>
                                <div className="mx-auto card height-adj px-3 content" id='height-adj'>

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-2  bring-front" src={Icon2} alt="img" />

                                            <p className="py-4 fs-lg-5 px-3">Create the consistency, thoroughness and transparency that auditors love.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">
                                <div className="Redcard" ></div>
                                <div className="mx-auto card height-adj px-3 content" id='height-adj'>

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-3  bring-front" src={Icon3} alt="img" />
                                             <p className=" py-4 fs-lg-5 px-3">Reduce total cost of ownership.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>

                    </Row>
                </div>
            </Container>


        </>
    )
}

export default AnimatedCardsQuale