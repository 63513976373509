
import React, { useEffect } from 'react'
import './chasma_NatioDesk.css';
import ServiceCardsNatioDesk from './chasma _Natio_Desk_service_cards';
import GetredycardNatioDesk from './chasma_getredypink_Natio_Desk_card';
import AnimatedCardsNatioDesk from './chasma_animate_Natio_Desk_cards'
import Banner from '../chasma_analytics/Banner';
import { Seo } from '../SEO/seo';






const ChasmaNatioDesk = () => {

    useEffect(() => {
        document.title = 'Natio Desk';
    }, []);

    return (
        <>
             <Seo
                title= "Chasma Natio Desk- Support Portal Management | Help Desk Software and Ticketing System"
                description= "Chasma Natio Desk enables you to provide fast, personalized service to your customers and internal user groups."
                type= "website"
                name= "Chasma Natiodesk"
                url= 'https://chasma.io/chasmanatiodesk '
                image='https://chasma.io/static/media/chasma-natiodesk.7056736b.png'
            />
            <Banner heading='Chasma Natio Desk' imgURL='chasma-natiodesk' caption='Support portal management for customers and internal users in one application' />
            <AnimatedCardsNatioDesk />
            <ServiceCardsNatioDesk />
            <GetredycardNatioDesk />



        </>
    )
}

export default ChasmaNatioDesk