import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './chasma_Lexy.css';
import RequestADemo_Lexy from '../../assets/RequestADemo_Lexy.png'
import Iframe from 'react-iframe'
import ClintgridcardsLexy from './chasma_Lexy_clint_cards'

const GetredycardLexy = () => {
    return (
        <>
            <div className='px-5 container'>

                <Row className=' justify-content-center align-items-center mt-lg-4 mt-md-3 mt-3'>


                    <Col lg={6} md={12} sm={12} xs={12} className="px-xl-4 px-lg-5 px-3 px-md-4 px-sm-3 ">
                        <div className='text-center text-lg-start '>

                            <h1 className="fw-bold">Why Chasma Lexy?</h1>
                        </div>

                        <p className="mb-0">Meet Lexy - Your HR Virtual assistant. How much time does your HR staff spend answering simple, repetitive questions from employees, prospective employees, and ex-employees? Watch the video to see how Lexy can help you improve the employee experience in your HR processes.

                        </p>
                       
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12} className="video-container my-lg-0 my-2"  >
                        <div className="video-container">
                            <Iframe height="100%" width="100%" src="https://player.vimeo.com/video/256643684?h=3505cce255&title=0&byline=0&portrait=" frameborder="0" allowfullscreen />
                        </div>

                    </Col>


                </Row>



                <ClintgridcardsLexy/>

                <Row className='justify-content-center align-items-center'>
                    <div className='mt-lg-5 mt-md-3 mt-2'></div>


                    <Col lg={6} md={12} sm={12} xs={12} className="px-xl-4 px-lg-5 px-3 px-md-4 px-sm-3 ">
                        <div className='text-center text-lg-start '>

                            <h1 className="fw-bold">Ease Communication across your enterprise technology landscape</h1>
                        </div>

                        <p className="mb-0 py-3">Lexy is adaptable to most of your core HR applications, collaboration tools, cloud repositories and Artificial Intelligence platforms, so the communication and work flow across your enterprise is effortless.

                        </p>

                    </Col>

                    <Col lg={6} md={12} sm={12} xs={12} className="video-container mx-auto" id='lexyFooter' >
                        <div className="">
                           <img className='img-fluid lexyImage'  src={RequestADemo_Lexy} alt="" />
                        </div>

                    </Col>
                </Row>

            </div>
             </>
    )
}

export default GetredycardLexy