

import React, { useEffect } from 'react'
import '../../styles/Banner.css'
import './chasma_connect.css';
import AnimatedCardsConnect from './chasma_animate_connect_cards'
import ServiceCardsConnect from './chasma _connect_service_cards';
import GetredycardConnect from './chasma_getredypink_connect_card';

import Banner from '../chasma_analytics/Banner';
import { Seo } from '../SEO/seo';






const ChasmaConnect = () => {

    useEffect(() => {
        document.title = 'Chasma Connect';
    }, []);

    return (
        <>

            <Seo
                title= "HR System Integration | HR Technology Integration | Chasma Place"
                description= "Chasma Connect is an integration marketplace that offers prefabricated plug and play connectors, HR integrators, ready to deploy into your HR applications. "
                type= "website"
                name= "Chasma Connect"
                url= 'https://chasma.io/chasmaconnect'
                iumage='https://chasma.io/static/media/Chasma-connevct.957a3165.png'
            />
            <Banner heading='Chasma Connect' imgURL='Chasma-connevct' caption='Integrate your HR Applications data'/>
            <AnimatedCardsConnect />
            <ServiceCardsConnect />
            <GetredycardConnect />



        </>
    )
}

export default ChasmaConnect