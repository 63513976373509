import React from 'react'
import '../../styles/Banner.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/esm/Container';


const Banner = (props) => {
    return (
        <>

            <div className="blue-card-new">
                <div className='animationDivContainer '  >
                    <div className="box"></div>   
                    <div className="radiusCircle"></div>
                    <div className="radiusCircle2"></div>
                     <div className="radiusCircle3"></div>
                    <div className="radiusCircle3"></div>
                </div>

                <Container className='mt-lg-3 mt-xl-3 pt-md-3 py-2 px-0 ' lg={12} md={12} sm={12} xs={12}>
                    <Row className='p-0 homepagecontainer align-items-center  px-lg-3 px-xl-3 px-md-2 px-sm-2 m-0 p-0'>

                        <Col md={6} className="inside-container p-0" id="head1">
                            <h1 className='fw-bold text-white bannerHeading'>{props.heading}</h1>
                            <p className='text-white mb-0'>{props.caption}</p>

                             </Col>

                        <Col md={6} className="py-1 outside-container">



                            <div className='wrapper'>


                                <div className="white-card d-flex align-items-center justify-content-center p-lg-4 main-element" id='card-head'>
                                    <img src={require(`../../assets/Chasma_Images2/${props.imgURL}.png`)} alt="" className='img-fluid' />
                                    <div className='circle-animation first'>
                                        <div className="circle1"></div>
                                        <div className="circle2"></div>
                                        <div className="circle3"></div>
                                    </div>
                                    <div className='circle-animation2 second'>
                                        <div className="circle1"></div>
                                        <div className="circle2"></div>
                                        <div className="circle3"></div>
                                    </div>

                                </div>
                            </div>




                        </Col>


                    </Row>
                </Container>

            </div>

        </>
    )
}

export default Banner