

import React, { useEffect } from 'react'

import './chasma_editor.css';

import AnimatedCards from './chasma_animate_cards'
import ServiceCards from './chasma _editor_service_cards';
import Getredycard from './chasma_getredypink_card';
import Bnanner from '../chasma_analytics/Banner'
import { Seo } from '../SEO/seo';


const Editor = () => {

    useEffect(() => {
        document.title = 'Chasma Editor';
    }, []);

    return (
        <>
            <Seo
                title= "LMS Landing Page Customization | SumTotal Content Editor | Chasma Place "
                description= "Create user friendly application landing pages & engaging user portals for your HR & learning applications without programming with Chasma Editor."
                type= "website"
                name= "Chasma Editor"
                url= 'https://chasma.io/chasmaapps/chasmaeditor'
                image="https://chasma.io/static/media/editor-image.faa9d3d7.png"
            />

            <Bnanner heading='Chasma Editor' imgURL='editor-image' caption='Create engaging user portals for your core HR, talent and learning applications without programming'/>
           
            <AnimatedCards />
            <ServiceCards />
            <Getredycard />



        </>
    )
}

export default Editor