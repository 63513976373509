import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/chasma_home_section2.css'


const AnimatedCardsNatioDesk = () => {
    return (
        <>
            <div className='px-4'>
            <Row className='justify-content-center align-items-center'>
                    <Col md={10} lg={8} className='col-12 '>
                       
                        <div className='text-center py-3  '>

                            <h1 className='fw-bold'>Modernize Your Help Desk Service Delivery</h1>
                            <p className='pt-2'>Trust, timely information, and rapid resolution are the lifeblood of support relationships.
                            </p>

                            <p>Step up to tools you can rely on to deliver results.
                            </p>
                            <p>Chasma Natio Desk enables you to provide fast, personalized service to your customers and internal user groups.
                            </p>

                        </div>
                    </Col>
                </Row>
            </div>
           


        </>
    )
}

export default AnimatedCardsNatioDesk