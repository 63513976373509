import {useState} from 'react'
import React from 'react';
import './whyChasma.css';
import OurCulture from '../../assets/RoundedImages/OurCulture.png'
import OurMission from '../../assets/RoundedImages/OurMission.png'
import Our_VISION from '../../assets/RoundedImages/OurVision.png'
import WeareChasma from '../../assets/RoundedImages/WeareChasma.png'
import PhilosophyPurpose from '../../assets/RoundedImages/PhilosophyPurpose.png'
import OurCultureIcon1 from '../../assets/PhilosophyAndPurpose/OurCulture1.png'
import OurCultureIcon2 from '../../assets/PhilosophyAndPurpose/OurCulture2.png'
import WeBelieve from '../../assets/PhilosophyAndPurpose/WeBelieve.png'
import WeUseTech from '../../assets/PhilosophyAndPurpose/WeUseTech.png'
import WeArePassionate from '../../assets/PhilosophyAndPurpose/WeArePassionate.png'
import WeInnovate from '../../assets/PhilosophyAndPurpose/WeInnovate.png'
function Banner() {

    let [degrees, setDegrees] = useState(0)
    let [Display1, setDisplay1]=useState('block')
    let [Display2, setDisplay2]=useState('block')
    let [Display3, setDisplay3]=useState('none')
    let [Display4, setDisplay4]=useState('block')
    let [Display5, setDisplay5]=useState('block')
  
    let [background,setBackGround]=useState({
        Fcolor:'rgb(32 217 193 / 83%)',
            Lcolor:'rgb(17 135 208'
    })


    let [Image,setImage]=useState(WeareChasma)
    let [content, setContent] = useState(<div className='contentText' style={{textAlign:'start'}}>
    <h4>We are Chasma</h4>
    <p>We call it Chasma. Chasma, from greek khasma, is a market place for apps,
         integrators, visualizations and dashboards. Those tools compliment 
         technology initiatives and help HR organizational designers build
          efficiencies in their processes. An idea in the moment sparked a
           global innovation movement, so join us as we explore ways to help 
           you make technology an enabler of people performence.</p>
</div>)
    
    let [place1,setPlace1]=useState({
      left:'-50',
      top:'-20',
      rotate:'0',
      
    })
    let [place2,setPlace2]=useState({
      left:'22',
      top:'-20',
      rotate:'0'
    })
    let [place3,setPlace3]=useState({
      left:'0',
      top:'0',
      rotate:'0'
    })
    let [place5,setPlace5]=useState({
      left:'-82',
      top:'29',
      rotate:'-1'
    })
    let [place4,setPlace4]=useState({
        top: 29,
        left: 18,
      rotate:'1'
    })
  
  
    let handleOne=()=>{
        setBackGround({
            Fcolor:'#6da7cb',
            Lcolor:'#2054d9d4'
        })
        setDisplay1('none')
        setDisplay2('block')
        setDisplay3('block')
        setDisplay4('block')
        setDisplay5('block')
  
  
      setImage(Our_VISION)
      setDegrees(144);
      
      setPlace2({
        left:'23',
        top:'30',
        rotate:'214'
      })
      setPlace3({
        top: 32,
        left: -67,
        rotate:'-146'
      })
      setPlace4({
        left:'-48',
        top:'-27',
        rotate:'-148'
      })
      setPlace5({
        left:'33',
        top:'-25',
        rotate:'-143'
      })
      setContent(<div className='contentText' style={{textAlign:'start'}}>
      <h4>Our Vision</h4>
      <p>20 years ago, we had vision of interconnected systems - one 
        where people can work efficiantly and business would be empathetic,
         human, and personable. Back then technology often defined work. In 2016,
          a group of folks with experience in people technology met to discuss the
           challenges HR leaders face in creating an engaging employee experience.
            These leaders don't want to be blocked by technology challenges.
             They want help in fulfilling their mission of creating a positive employee experience.
         And we started here.</p>
  </div>)
    }
    
    let handleTwo=()=>{
        setBackGround({
            Fcolor:'rgb(2 65 164 / 97%)',
            Lcolor:'rgb(31 14 155)'
        })

      setDegrees(72);
      setDisplay1('block')
      setDisplay2('none')
      setDisplay3('block')
      setDisplay4('block')
      setDisplay5('block')

      setImage(OurMission)
      setPlace1({
        left:'32',
        top:'-6',
       
        rotate:'-72'
      })
      setPlace3({
        top: 23,
        left: 21,
        rotate:'-72'
      })
      setPlace4({
        left:'-48',
        top:'30',
        rotate:'-70'
      })
      setPlace5({
        left:'-58',
        top:'-41',
        rotate:'-75'
      })
      
  
  
      setContent(<div className='contentText' style={{textAlign:'start'}}>
                    <h4>Our Mission</h4>
                    <p>Instead of allowing technology to drive HR business process,
                         we strive to allow HR leaders to emerge as organizational designers,
                          to drive business and harmless technology to support their people.
                           We are as a quest to adopt technology to the way people think and work, 
                        where people can do what comes naturally to them to accomplish their tasks.</p>
                </div>
      )
    }
    let handleThree=()=>{

        setBackGround({
            Fcolor:'rgb(32 217 193 / 83%)',
            Lcolor:'rgb(17 135 208)'
        })
      setDisplay1('block')
      setDisplay2('block')
      setDisplay3('none')
      setDisplay4('block')
      setDisplay5('block')
      setDegrees(0);
      setImage(WeareChasma)
      setPlace1({
        left:'-50',
      top:'-20',
      rotate:'0'
      })
      setPlace2({
        left:'22',
        top:'-20',
        rotate:'0'
      })
      setPlace4({
        top: 29,
        left: 18,
        rotate:'1'
      })
      setPlace5({
        left:'-82',
        top:'29',
        rotate:'-1'
      })
  
     
  
      setContent(<div className='contentText' style={{textAlign:'start'}}>
                    <h4>We are Chasma</h4>
                    <p>We call it Chasma. Chasma, from greek khasma, is a market place for apps,
                        integrators, visualizations and dashboards. Those tools compliment 
                        technology initiatives and help HR organizational designers build
                        efficiencies in their processes. An idea in the moment sparked a
                        global innovation movement, so join us as we explore ways to help 
                        you make technology an enabler of people performence.</p>
                </div>)
    }
    let handleFour=()=>{
        
        setBackGround({
            Fcolor:'rgb(224 153 55)',
            Lcolor:'rgb(217 78 32 / 96%)'
        })

      setDegrees(-72);
      setDisplay1('block')
      setDisplay2('block')
      setDisplay3('block')
      setDisplay4('none')
      setDisplay5('block')
      setImage(OurCulture)
      setPlace1({
        left:'-38',
      top:'39',
      rotate:'-289'
      })
      setPlace3({
        top: -7,
        left: 22,
        rotate:'-72'
      })
      setPlace2({
        left:'-46',
        top:'-19',
        rotate:'73'
      })
      setPlace3({
        left:'25',
        top:'-9',
        rotate:'71'
      })
      setPlace5({
        left:'25',
        top:'25',
        rotate:'70'
      })
      
  
      setContent(<div className='contentText OurCultureContainer' style={{textAlign:'start'}}>
                    <h4>Our Culture</h4>
                    <div className='d-flex w-75 align-items-center'>
                        <img src={OurCultureIcon1} alt=""  className='OurCultureImages' />
                        <p className='mb-0 mx-4'>
                            We create a company we love through shared beliefs, values and practices.
                        </p>
                    </div>
                    <div className='d-flex w-75 align-items-center'>
                        <img src={OurCultureIcon2} alt="" className='OurCultureImages' />
                        <p className='mb-0 mx-4'>
                            We believe every individual is talented and we seek to nurture that talent in every one of us. 
                        </p>
                    </div>
                </div>)
    }
    let handleFive=()=>{
        setBackGround({
            Fcolor:'rgb(112 2 164 / 97%)',
            Lcolor:'rgb(55 32 217)'
        })
      setDegrees(-144);
      setDisplay1('block')
      setDisplay2('block')
      setDisplay3('block')
      setDisplay4('block')
      setDisplay5('none')
      setImage(PhilosophyPurpose)
    
      setPlace1({
        left:'32',
      top:'25',
      rotate:'-218'
      })
      setPlace2({
        left:'-46',
        top:'33',
        rotate:'144'
      })
      setPlace3({
        top: -20,
        left: -66,
        rotate:'142'
      })
      setPlace4({
        left:'32',
        top:'-6',
        rotate:'-215'
      })
      setContent(<div className='contentText' style={{textAlign:'start',width:'85%'}}>
                        <h4>Philosophy and Purpose</h4>
                        <p>We support your organization's mission to improve performance and productivity through the people 
                            experience. When you have the right tools, you can turn your business dreams into reality.
                        </p>
                        <div className='PPSubContainer'>
                            <div>
                                <img src={WeBelieve} alt="" />
                                <p>We belive technology should bring value to people.</p>
                            </div>
                            <div>
                                <img src={WeUseTech} alt="" />
                                <p>We use technology to solve real world problems.</p>
                            </div>
                            <div>
                                <img src={WeArePassionate} alt="" />
                                <p>We are passionate about our mission. </p>
                            </div>
                            <div>
                                <img src={WeInnovate} alt="" />
                                <p>We innovate to enhance people experiences.</p>
                            </div>
                        </div>
                    </div>)
    }
  
  
    return (
              <div className='BannerContainer' style={{backgroundImage:`linear-gradient(45deg,${background.Fcolor}, ${background.Lcolor})`}}>
                    <h5 className='heading'>Behind the moment that sparked a global innovation</h5>
                  <div className='  container h-100'>
                
                <div className="Banner m-xl-0 w-100" >
                  
                    <div className='container2'>
                    <div className="menuDiv" style={{transform:`rotate(${degrees}deg)`, transition:"0.3s ease-in-out"}}>
                      {/* <span className='dashedLine'>- - - - </span> */}
                    <div className="menuItems" id="firstMenu" onClick={handleOne}>
                     <div className='textHeader'  style={{transform:`rotate(${place1.rotate}deg)`, transition:"0.3s ease-in-out"}}>
                        <p className='text' style={{top:`${place1.top}px`,left:`${place1.left}px`,display:`${Display1}`}}>Our Vision</p>
                        </div>
                    </div>
                    <div className="menuItems" id="secondMenu" onClick={handleTwo} >
                        <div className='textHeader'  style={{transform:`rotate(${place2.rotate}deg)`, transition:"0.3s ease-in-out"}}>
                        <p className='text'   style={{top:`${place2.top}px`,left:`${place2.left}px`,display:`${Display2}`}}>
                            Our Mission
                            </p>
                        
                        </div>
                    </div>
                    <div className="menuItems" id="thirdMenu" onClick={handleThree}>
                        <div className='textHeader'  style={{transform:`rotate(${place3.rotate}deg)`, transition:"0.3s ease-in-out"}}>
                            
                            <p className='text'  style={{top:`${place3.top}px`,left:`${place3.left}px`,display:`${Display3}`}} >
                                We are Chasma
                            </p>
                        </div>
                    </div>
                    <div className="menuItems" id="fourthMenu" onClick={handleFour}>
                        <div className='textHeader'  style={{transform:`rotate(${place4.rotate}deg)`,transition:"0.3s ease-in-out"}}>      
                            <p className='text'  style={{top:`${place4.top}px`,left:`${place4.left}px`,display:`${Display4}`}}  >
                            Our Culture
                            
                            </p>
                        </div>
                    </div>
                    <div className="menuItems" id="fifthMenu" onClick={handleFive}>
                    <div className='textHeader'  style={{transform:`rotate(${place5.rotate}deg)`, transition:"0.3s ease-in-out", whiteSpace:'wrap'}}>      
                            <p className='text'  style={{top:`${place5.top}px`,left:`${place5.left}px`,display:`${Display5}`}} >
                            Purpose & Philosophy
                            
                            </p>
                        </div>
                    </div>
                    
                    </div>
                    <div className='ImageContainer' style={{backgroundColor:`${background.Fcolor}`}}> <img src={Image} className='Image' alt='imag'/></div>
            
                    </div>
                    <p className='mainPara mt-lg-0 mt-sm-5 mt-5' id='contextWhychasma' >{content}</p>
                    
                </div>
            </div>
              </div>
            
                
    );
  }

export default Banner;
