import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import './Workday_Analytics.css';



// icon images------------

import RealTimeVisualizations from '../../assets/Workday_Analytics_Advantage-icons/Real-time_Visualization.png'
import DataVisualizations from '../../assets/Workday_Analytics_Advantage-icons/Data_visualizations_that.png'
import ThreePricingLevels from '../../assets/Workday_Analytics_Advantage-icons/Three_pricing_levels.png'
import KPIsAndMetrics from '../../assets/Workday_Analytics_Advantage-icons/KPIs_and_metrics.png'
import ExpertsWhoCanLead from '../../assets/Workday_Analytics_Advantage-icons/strategic-plan.png'


function ServiseCardsWorkdayAnalytics() {
    return (
        <>
            <div className="px-4 container mt-lg-2 my-2">
                <Row className='justify-content-center align-items-center'>
                    <Col md={11} lg={10} className='col-12'>

                        <div className='text-center pt-2 '>

                            <h1 className='fw-bold textFont'>What's Keeping you from building your Workday reporting libraries?</h1>

                            <p className=''>For many of our Chasma customers, building a comprehensive Workday reporting library always seems to get shoved to the back of the line. Some tell us that they just don't have the time or the skills to get it done. Still others say they can't decide what to measure.
                            </p>

                            <p className=''>At Chasma, We are on a mission to address HR technology needs in shorter and faster cycles to maximize your potential, so you can get it done now, without waiting for priorities to align themselves.
                            </p>
                            <p className=''>Your technology should not call the tune for you to dance; it should dance to the music in your critical processes.
                            </p>
                            <p className=''>That's why we maintain a library of 130+ dashboards and hundreds of data visualizations, ready for you to deploy in Workday. If you don't see what you need, let us know and we'll create it for you.
                            </p>
                        </div></Col>
                </Row>
            </div>
            <Container>

            </Container>
            <div className='backgroundColor'>

                <Container className='pt-lg-2 stars-attach  ' >


                    <Row className='justify-content-center align-items-center'>
                        <Col md={11} lg={10} className='col-12  text-white'>

                            <div className='text-center pt-5 px-lg-5 '>

                                <h1 className='fw-bold textFont'>Workday Analytics Advantage</h1>
                                <p className=' px-md-5 px-2 '>Enjoy the benefits of rapid deployment and low-cost,pre-configured visualizations and dashboards built for Workday.</p>

                            </div></Col>
                    </Row>
                    <Row className='justify-content-center align-items-center pt-5 move'>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className="  align-items-center text-center">

                                        <div className=''>
                                            <img src={RealTimeVisualizations} alt="Real-time visualizations" />
                                        </div>

                                    </div>
                                    <p className="py-3 text-center">Real-time visualizations of critical operational metrics like employee cost versus performance, analysis on your data , and more.</p>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className=" align-items-center text-center">
                                        <div className=''>
                                            <img src={DataVisualizations} alt="Data visualizations" />
                                        </div>

                                    </div>
                                    <p className="py-3 text-center">Data visualizations that help your executives make decisions at the speed of business.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className=" align-items-center text-center">
                                        <div className=''>
                                            <img src={ThreePricingLevels} alt="Three pricing levels" />
                                        </div>

                                    </div>
                                    <p className="py-3 text-center">Three pricing levels to fit your budget and business needs.
                                    </p>
                                </div>
                            </div>

                        </Col>


                        {/*------------ first 3 coloms ------------------*/}
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 py-lg-5 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className="align-items-center text-center">
                                        <div className=''>
                                            <img src={KPIsAndMetrics} alt="KPIs and metrics that matter" />
                                        </div>

                                    </div>
                                    <p className="py-3 text-center">KPIs and metrics that matter most for your talent oragization.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3  py-lg-5 my-lg-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className=" align-items-center text-center">
                                        <div className=''>
                                            <img src={ExpertsWhoCanLead} alt="Experts who can lead you through" className='' />
                                        </div>

                                    </div>
                                    <p className="py-3 text-center">Experts who can lead you through the framework for aligning your metrics to business strategy.</p>
                                </div>
                            </div>
                        </Col>



                    </Row>


                </Container>
            </div></>
    )
}

export default ServiseCardsWorkdayAnalytics