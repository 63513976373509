import React from 'react'
import Icon1 from "../../assets/Animated-Icons/Lexy/Lexy-icon-1.png"
import Icon2 from "../../assets/Animated-Icons/Lexy/Lexy-icon-2.png"
import Icon3 from "../../assets/Animated-Icons/Lexy/Lexy-icon-3.png"

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/chasma_home_section2.css'


const AnimatedCardsLexy = () => {
    return (
        <>
            <Container fluid='md' className='my-lg-4 py-2'>
                <div>
                <Row className='justify-content-center align-items-center'>
                    <Col md={10} lg={8} className='col-12'>
                        <div className='pt-md-2 pt-2'></div>
                        <div className='text-center pt-1  '>

                            <h1 className='fw-bold'>Your HR Virtual Assistant</h1>

                            <p className='py-3'>Your HR staff and your employees spend too much time looking for information and getting answers to simple, repetitive questions. Cognitive computing enables us to assign those tasks to computers so you and your people can focus on more productive work.
                            </p>

                        </div></Col>
                </Row>
                    <Row className='justify-content-center align-items-center my-2'>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12 '>
                            <div className="obj-backward">
                                <div className="Redcard"></div>
                                <div className="mx-auto card height-adj px-3 content" id='height-adj'>

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-3  bring-front" src={Icon1} alt="img" />

                                            <p className=" py-4 fs-lg-5 px-3">Improve the employee experience in your HR processes.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">
                                <div className="Redcard"></div>
                                <div className="mx-auto card height-adj px-3 content" id='height-adj'>

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-2  bring-front" src={Icon2} alt="img" />

                                            <p className="py-4 fs-lg-5 px-3">Enhance your employer brand by improving communication with prospective hires and ex-employees.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">
                                <div className="Redcard" ></div>
                                <div className="mx-auto card height-adj px-3 content" id='height-adj'>

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-2  bring-front" src={Icon3} alt="img" />
                                              <p className=" py-4 fs-lg-5 px-3">Drive higher productivity in your HR team.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>

                    </Row>
                </div>
            </Container>


        </>
    )
}

export default AnimatedCardsLexy