import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import './people_powerbi.css';
import PreConfiguredDashboards from '../../assets/Chasma_Analytics_for_Power_BI-icons/Outer_Lines.png'
import DataVisualizations from '../../assets/Chasma_Analytics_for_Power_BI-icons/Data_visualizations_that.png'
import AdvancedAnalytics from '../../assets/Chasma_Analytics_for_Power_BI-icons/Advanced_Analytics.png'
import CompareCritical from '../../assets/Chasma_Analytics_for_Power_BI-icons/money-management.png'
import PredictiveModelsThatDriveDecisions from '../../assets/Chasma_Analytics_for_Power_BI-icons/Predictive_models_to_drive.png'

function serviceCardsPeopleHRBOX() {
    return (
        <>
                <Container>
                <Row className='justify-content-center align-items-center'>
                    <Col md={10} lg={8} className='col-12'>
                 
                        <div className='text-center  mt-2  '>

                            <h1 className='fw-bold textFont'>Your business runs on information ... Run Faster</h1>

                            <p className='py-3'>Chasma Analytics for Sumtotal provides dashboards and visualizations for hundreds of use cases from in-depth research into over 1000 global oragizations.
                            </p>

                            <p className=''>We provide many ways to deliver KPIs and vital metrics deployed directly into Microsoft Power BI.
                            </p>

                        </div></Col>
                </Row>
                </Container>
            <div className='backgroundColor'>
                <Container className='pt-lg-2 stars-attach' >
             

                    <Row className='justify-content-center align-items-center'>
                        <Col md={8} lg={11} className='col-12 text-white'>

                            <div className='text-center pt-5 px-lg-5 '>

                                <h1 className='fw-bold textFont'>Chasma Analytics for Power BI Advantage</h1>
                                <p className=' px-md-5 px-2 '>Enjoy the benefits of rapid deployment and low-cost, pre-configured visualizations and dashboards.</p>
                                <p className=' px-md-5 px-2 '>We are on a mission to address HR technology needs in shorter and faster cycles to maximize your potential, so you can reduce your administrative overhead and focus on strategy.</p>
                                <p className=' px-md-5 px-2 '>Your technology should not call the tune for you to dance; it should dance to the music in your critical processes.</p>
                            </div></Col>
                    </Row>
                    <Row className='justify-content-center align-items-center pt-5'>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className="  align-items-center text-center">

                                        <div className=''>
                                            <img src={PreConfiguredDashboards} alt="Pre-configured
                                            Dashboard Designs" className='' />
                                        </div>  
                                    </div>
                                    <p className="py-3 text-center">Pre-configured dashboards you can deploy immediately into Microsoft Power BI.</p>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5" >
                                    <div className=" align-items-center text-center">
                                        <div className=''>
                                            <img src={DataVisualizations} alt="Data visualizations" className='' />
                                        </div>   
                                    </div>
                                    <p className="py-3 text-center">Data visualizations that help you make decisions at the speed of business.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className=" align-items-center text-center">
                                        <div className=''>
                                            <img src={AdvancedAnalytics} alt="Advanced analytics to give you deeper insights" className='' />
                                        </div>   
                                      
                                    </div>
                                    <p className="py-3 text-center">Advanced analytics to give you deeper insights into your workforce data.
                                    </p>
                                </div>
                            </div>

                        </Col>


                        {/*------------ first 3 coloms ------------------*/}
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 py-lg-5 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className="align-items-center text-center">
                                        <div className=''>
                                            <img src={CompareCritical} alt="Compare critical opertional metrics" className='' />
                                        </div>  
                                        
                                    </div>
                                    <p className="py-3 text-center">Compare critical opertional metrics like employee cost and performance, learning transfer and productivity, and more.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3  py-lg-5 my-lg-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className=" align-items-center text-center">
                                        <div className=''>
                                            <img src={PredictiveModelsThatDriveDecisions} alt="Predictive models that drive decisions" className='' />
                                        </div>  
                                        
                                    </div>
                                    <p className="py-3 text-center">Predictive models that drive decisions to capitalize on your people potential.</p>
                                </div>
                            </div>
                        </Col>

                      

                    </Row>


                </Container>
            </div></>
    )
}

export default serviceCardsPeopleHRBOX