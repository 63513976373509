import React, { useEffect } from 'react'
import './people_powerbi.css';
import ServiceCardsPeopleHRBOX from './people_powerbi_service_cards';
import GetredycardPeopleHRBOX from './people_powerbi_getredypink_card';
import Banner from '../chasma_analytics/Banner';
import { Seo } from '../SEO/seo';

const People_Analytics_with_PowerBI = () => {

    useEffect(() => {
        document.title = 'People Analytics with PowerBI';
    }, []);

    return (
        <>
             <Seo
                title= "Chasma Analytics for Power BI | Power BI data visualizations and Dashboards "
                description= "Enjoy the benefits of rapid deployment and low-cost, pre-configured visualizations and dashboards, deployed directly into Microsoft Power BI. "
                type= "website"
                name= "Chasma Power BI"
                url= 'https://chasma.io/chasmapeopleanalyticsforpowerbi '
                image='https://chasma.io/static/media/Chasma-Analytics-For-PowerBI.bf615818.png'
            />
            <Banner heading='Chasma Analytics For Power BI' imgURL='Chasma-Analytics-For-PowerBI' caption='Dashboards and visualizations ready to deploy now'/>
            <ServiceCardsPeopleHRBOX />
            <GetredycardPeopleHRBOX />



        </>
    )
}

export default People_Analytics_with_PowerBI