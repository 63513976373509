import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import './chasma_Quale.css';



// icon images------------

import EliminateLaborIntensiveManualTesting from '../../assets/quale-icons/delete.png'
import AutomatedReporting from '../../assets/quale-icons/production.png'
import EnjoyaVastLibrary from '../../assets/quale-icons/_x30_8.png'
import Configuration from '../../assets/quale-icons/layer1.png'
import AutomatedIntegrations from '../../assets/quale-icons/test.png'
import RealTimeStatus from '../../assets/quale-icons/data-management.png'



function serviceCardsQuale() {
    return (
        <>
            <div className='backgroundColor'>
                <Container className='pt-lg-2 stars-attach' >
                 <Row className='justify-content-center align-items-center'>
                        <Col md={8} lg={11} className='col-12 text-white'>

                            <div className='text-center pt-5 px-lg-5 '>

                                <h1 className='fw-bold'>How Quale’s automated testing can save you time and money</h1>
                                <p className='py-md-4 px-md-5 px-2 '>Quale runs the predefined test cases for each of your releases, and thoroughly tests your application, then presents the results in tabular format so you can focus on the areas that need attention.</p>

                            </div></Col>
                    </Row>
                    <Row className='justify-content-center align-items-center pt-5'>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_Quale px-4 pt-5">
                                    <div className=" d-flex align-items-center">

                                        <div className=''>
                                            <img src={EliminateLaborIntensiveManualTesting} alt="Eliminate Labor-Intensive Manual Testing" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Eliminate Labor-Intensive Manual Testing</div>

                                    </div>
                                    <p className="py-3">No more pulling off your people from productive work to validate your processes and integrations.</p>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_Quale px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={AutomatedReporting} alt="Automated Reporting" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Automated Reporting</div>

                                    </div>
                                    <p className="py-3">Detailed reports for each area of the application and the number of cases being executed.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_Quale px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={EnjoyaVastLibrary} alt="Enjoy a Vast Library of Test Scenarios" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Enjoy a Vast Library of Test Scenarios</div>

                                    </div>
                                    <p className="py-3">10,000+ common use cases and scenarios to thoroughly validate your system before we sign off.
                                    </p>
                                </div>
                            </div>

                        </Col>


                        {/*------------ first 3 coloms ------------------*/}
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 py-lg-5 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_Quale px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={Configuration} alt="Configuration" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Configuration</div>

                                    </div>
                                    <p className="py-3">Configure standard scripts to your application in minutes.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3  py-lg-5 my-lg-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_Quale px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={AutomatedIntegrations} alt="Automated Integrations Testing" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Automated Integrations Testing</div>

                                    </div>
                                    <p className="py-3">Build, deploy and validate test cases for your custom integrations.</p>
                                </div>
                            </div>
                        </Col>

                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12 py-lg-5'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_Quale px-4 pt-5" >
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={RealTimeStatus} alt="Real Time Status" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Real Time Status</div>

                                    </div>
                                    <p className="py-3">You can view real time progress of the results and your testing status.</p>
                                </div>
                            </div>

                        </Col>

                    </Row>


                </Container>
            </div></>
    )
}

export default serviceCardsQuale