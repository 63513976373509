import React from 'react'
import Icon1 from "../../assets/Animated-Icons/connect/connect-icon-1.png"
import Icon2 from "../../assets/Animated-Icons/connect/connect-icon-2.png"
import Icon3 from "../../assets/Animated-Icons/connect/connect-icon-3.png"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/chasma_home_section2.css'


const AnimatedCards = () => {
    return (
        <>
            <Container fluid='md' className='my-lg-4 my-3'>
                <div>
                <Row className='justify-content-center align-items-center'>
                    <Col md={10} lg={8} className='col-12'>
                       
                        <div className='text-center   '>

                            <h1 className='fw-bold'>Increase your organizational agility</h1>

                            <p className='py-3'>A community of practitioners who believe technology should be an enabler that breaks down barriers to getting things done.
                            </p>

                        </div></Col>
                </Row>
                    <Row className='justify-content-center align-items-center'>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12 '>
                            <div className="obj-backward">
                                <div className="Redcard"></div>
                                <div className="mx-auto card height-adj px-3 content" id='height-adj' style={{ width: "22rem" }}>

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-3  bring-front" src={Icon1} alt="img" />

                                            <p className=" py-4 fs-lg-5 px-3">Avoid costly programming.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">
                                <div className="Redcard"></div>
                                <div className="mx-auto card height-adj px-3 content" id='height-adj' style={{ width: "22rem" }}>

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-2  bring-front" src={Icon2} alt="img" />

                                            <p className="py-4 fs-lg-5 px-3">Bypass expensive middleware.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">
                                <div className="Redcard" ></div>
                                <div className="mx-auto card height-adj px-3 content" id='height-adj' style={{ width: "22rem" }}>

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-2  bring-front" src={Icon3} alt="img" />
                                            <p className=" py-4 fs-lg-5 px-3">Deploy in days, not weeks.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>

                    </Row>
                </div>
            </Container>


        </>
    )
}

export default AnimatedCards