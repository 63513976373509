import React, { useEffect } from 'react'
import Container from 'react-bootstrap/esm/Container';
import '../chasma_contact_us/contact_us.css';
import Supportform from './Contact_Support_form'
import { Seo } from '../SEO/seo';
import Banner from "../chasma_analytics/Banner"
import SalesContactUsContainer from './contactSupportContainer';

const Contact_Support = () => {
    useEffect(() => {
        document.title = 'Contact Support';
    }, []);
    return (
        <>
            <Seo
                title= "Support | Chasma Place"
                description= " Chasma’s innovative suite of HR apps, analytics &amp; connectors offer agile solutions for your human resources technology challenges &amp; improve your HR efficiency. "
                type= "website"
                name= "Chasma Support"
                url= 'https://chasma.io/support'
                image='https://chasma.io/static/media/Contact-us.3c5c7c97.png'
            />
            <Banner heading='Contact Support' imgURL='Contact-us' caption="We'd love you to reach us and let us know how we can help you."/>
           
            <Container>
            <SalesContactUsContainer/>
            </Container>
            <Supportform/>
            
        </>
    )
}

export default Contact_Support





