import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/esm/Button';
import { FiArrowUpRight } from "react-icons/fi";
import '../../styles/chasma_home_section4.css'
import Rings2 from '../../assets/ring2.png'
import Rings1 from '../../assets/rings1.png'
import Edgeicon from '../../assets/card icon.png'

import Pricingplans from '../../assets/pricing-palns.png'
import Chasmasupport from '../../assets/chasma support.png'
import { BiChevronRight } from "react-icons/bi"; 
import Stars2 from '../../assets/stars2.png'
import { Link } from 'react-router-dom';
import BannerImageChange from '../../assets/BannerImageChange.png'



const Section4 = () => {
    const handleLinkClick = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };
    return (
        <>
            <div className='p-1 container'>
               <Row className=' py-3 px-lg-5  justify-content-center align-items-center'>

                    <Col lg={6} md={12} sm={12} xs={12} className="px-xl-3 px-lg-4 px-5 px-md-5 px-sm-5 py-5 ">
                        <div className='text-center text-lg-start '>
                            <p className='py-1 fs-6 fw-bold'>Why Chasma</p>
                            <h2 className="fw-bold " >A Technology-First Approach To Your People Processes</h2>
                        </div>

                        <p className="mb-0">We are on a mission to address HR technology needs in shorter and faster cycles to maximize your potential, so you can reduce your administrative overhead and focus on strategy.</p>

                        <p className='py-lg-3 py-2'>Your technology should not call the tune for you to dance; it should dance to the music in your critical processes.
                        </p>


                        <a href="https://app.chasma.io/register" target='blank' className='text-white'> <Button className='btn btn-warning rounded-pill text-white'>
                            Start Now <FiArrowUpRight />
                        </Button> </a>
                    </Col>                    
                    <Col lg={6} md={12} sm={12} xs={12} className="video-container mx-auto d-flex justify-content-center align-items-center  py-5 " id='lexyFooter' >
                        <div className="">
                           <img className='img-fluid ' id="BannerImageChange" style={{width:'100%',height:'400px',objectFit:'contain'}} src={BannerImageChange} alt="" />
                        </div>

                    </Col>

                    <div className='my-5' id='cont'>

                        <img src={Edgeicon} alt="" className='position-absolute img-fluid' id='cardicon' />
                        <div className='mb-1 mx-2 text-center p-4' id='card-pink'>

                            <Row className='justify-content-center align-items-center'>
                                <Col md={8} lg={7} className='col-12'>
                                    
                                    <div className='text-center pt-5 px-lg-5 '>

                                        <h2 className='fw-bold'>Ready to get started?</h2>
                                        <p className='py-2'>Explore Chasma's Apps, Analytics and Connectors and get started to elevate your employee experience. We'd love to support your employee centered vision, do contact us.</p>

                                    </div></Col>
                            </Row>
                            <Row className='justify-content-center mt-1'>
                                <Col md={6} className='d-flex justify-content-center'>
                                    <Row className=' justify-content-center'>
                                        <Col md={6} lg={3} className='d-flex align-items-center justify-content-center'>
                                            <img src={Pricingplans} alt="" />

                                        </Col>
                                        <Col md={6} lg={6} className='text-lg-start  text-md-center text-sm-center'>
                                            <h3 className='fw-bold'>Pricing plans</h3>
                                            <p>Choose the one that best suits your organization’s needs.</p>

                                            <Button as={Link}
                                                to={'/contactus'} variant='primary' className='w-75 radius-for-button'   onClick={handleLinkClick}  >Contact <BiChevronRight /> </Button>

                                        </Col>

                                    </Row>
                                </Col>

                                <Col md={6} className='mt-4 mt-lg-0'>
                                <Row className='justify-content-center '>
                                        <Col md={6} lg={3} className='d-flex align-items-center justify-content-center'>
                                        <img src={Chasmasupport} alt="" />

                                        </Col>
                                        <Col md={6} lg={6} className='text-lg-start text-md-center text-sm-center'>
                                            <h3 className='fw-bold'>Chasma support</h3>
                                            <p>Reach us if you have any queries or need any help.</p>

                                            <Button onClick={handleLinkClick}  as={Link}
                                                to={'/support'} variant='primary' className='radius-for-button buttonContactSupport ' style={{width:'100%', fontSize:'13px'}}>Contact Support <BiChevronRight /> </Button>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <div className='row d-flex ring-images w-100'>
                                <div className='col-6 d-flex  justify-content-start'>
                                    <img src={Rings1} alt="imagerings" className='img-fluid rings'/>
                                </div>
                                <div className='col-6 d-flex  justify-content-end'>
                                    <img src={Rings2} alt="imagerings" className='img-fluid rings2'/>
                                </div>
                            </div>

                        </div>
                        <div className='endStars'>
                            <img src={Stars2} alt="imagerings" className='img-fluid' id='star' />
                        </div>
                    </div>
                   
                   
                </Row>
            </div>
            
        </>
    )
}

export default Section4