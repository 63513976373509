import React from 'react'
import Chasmaapps from "../../assets/chasma apps.png"
import ChasmaAnalytics from '../../assets/chasma analytics.png'
import Chasmaconnects from '../../assets/chasma connectors.png'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/chasma_home_section2.css'
import { Link } from 'react-router-dom';
// import { Link } from 'react-bootstrap-icons';


const Section2 = () => {
    const scrollTo=()=>{
        window.scrollTo(0,0)
    }
    return (
        <>
            <Container fluid='md' className='my-lg-3 my-3'>
                <div>
                    <Row className='justify-content-center align-items-center'>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12 my-sm-5'>
                            <div className="obj-backward">
                                <div className="Redcard"></div>
                                <div className="mx-auto card height-adj px-3 content" id='height-adj'>

                                    <div className="text-center">
                                    <Link to="/chasmaapps" className="d-block" onClick={scrollTo}>
                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-2  bring-front" src={Chasmaapps} alt="img" />
                                            <h3 className="fs-3 fw-bold pt-4">Chasma Apps</h3>
                                            
                                            <p className="text-center">Update, extend and complement your HR applications.</p>
                                        </div>
                                        </Link>
                                    </div>
                                    
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12 my-sm-5'>
                            <div className="obj-backward">
                                <div className="Redcard"></div>
                                <div className="mx-auto card height-adj px-3 content" id='height-adj'>

                                    <div className="text-center">
                                    <Link to="/chasmaanalytics" className="d-block" onClick={scrollTo}>
                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-2  bring-front" src={ChasmaAnalytics} alt="img" />
                                            <h3 className="fs-3 fw-bold pt-4">Chasma Analytics</h3>
                                            <p className="text-center">Data-Driven Visual Insight </p>
                                        </div>
                                    </Link>
                                    </div>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12 my-sm-5'>
                            <div className="obj-backward">
                                <div className="Redcard" ></div>
                                <div className="mx-auto card height-adj px-3 content" id='height-adj'>

                                    <div className="text-center">
                                    <Link to="/chasmaconnect" className="d-block" onClick={scrollTo}>
                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-2  bring-front" src={Chasmaconnects} alt="img" />
                                            <h3 className="fs-3 fw-bold pt-4">Chasma Connectors</h3>
                                            <p className="text-center">Integrate your HR Applications data</p>
                                        </div>
                                    </Link>
                                    </div>
                                </div>
                            </div>

                        </Col>

                    </Row>
                </div>
            </Container>


        </>
    )
}

export default Section2