import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import './chasma_connect.css';



// icon images------------

import RapidDeployment from '../../assets/chasma-connect-icons/Rapid_Deployment.png'
import ImprovedDataQuality from '../../assets/chasma-connect-icons/Improved_Data Quality.png'
import PrefabricatedConnectors from '../../assets/chasma-connect-icons/Prefabricated_Connectors.png'
import IndustryStandardProtocols from '../../assets/chasma-connect-icons/Industry_Standard_Protocols.png'
import AnalyticalVisualizations from '../../assets/chasma-connect-icons/Improved_Data Quality.png'
import PredictiveModels from '../../assets/chasma-connect-icons/Complete_Documentation.png'


function serviceCards() {
    return (
        <>
            <div className='backgroundColor'>
                <Container className='pt-lg-2 stars-attach' >
                  
                    <Row className='justify-content-center align-items-center'>
                        <Col md={8} lg={11} className='col-12 text-white'>

                            <div className='text-center pt-5 px-lg-5 '>

                                <h1 className='fw-bold'>Let Chasma Connect Help You Create A More Agile Organization</h1>
                                <p className='py-md-4 px-md-5 px-2 '>Improve the user experience and make an impact far beyond data and administration.</p>

                            </div></Col>
                    </Row>
                    <Row className='justify-content-center align-items-center pt-5 move'>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className=" d-flex align-items-center">

                                        <div className=''>
                                            <img src={RapidDeployment} alt="Rapid Deployment"/>
                                        </div>   <div className="fs-5 px-3 fw-bold">Rapid Deployment</div>

                                    </div>
                                    <p className="py-3">Reduce implementation costs and accelerate your ability to respond to changing business needs.</p>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={ImprovedDataQuality} alt="Improved Data Quality"/>
                                        </div>   <div className="fs-5 px-3 fw-bold">Improved Data Quality</div>

                                    </div>
                                    <p className="py-3">Reduce data preparation time and eliminate reporting errors.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={PrefabricatedConnectors} alt="Prefabricated Connectors"/>
                                        </div>   <div className="fs-5 px-3 fw-bold">Prefabricated Connectors</div>

                                    </div>
                                    <p className="py-3">Eliminate development costs and long deployment schedule.
                                    </p>
                                </div>
                            </div>

                        </Col>


                        {/*------------ first 3 coloms ------------------*/}
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 py-lg-5 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={IndustryStandardProtocols} alt="Industry Standard Protocols"/>
                                        </div>   <div className="fs-5 px-3 fw-bold">Industry Standard Protocols</div>

                                    </div>
                                    <p className="py-3">Reduce maintenance costs and reprogramming effort.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3  py-lg-5 my-lg-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={AnalyticalVisualizations} alt="Analytical Visualizations"/>
                                        </div>   <div className="fs-5 px-3 fw-bold">Improved Data Flow</div>

                                    </div>
                                    <p className="py-3">Get information to the right people when they need it.</p>
                                </div>
                            </div>
                        </Col>

                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12 py-lg-5'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={PredictiveModels} alt="Predictive Models"/>
                                        </div>   <div className="fs-5 px-3 fw-bold">Complete Documentation</div>

                                    </div>
                                    <p className="py-3">Eliminate the risk of obsolescence.</p>
                                </div>
                            </div>

                        </Col>

                    </Row>


                </Container>
            </div></>
    )
}

export default serviceCards