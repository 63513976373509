import React from 'react'
import Icon1 from "../../assets/Animated-Icons/cible/cible-icon-1.png"
import Icon2 from "../../assets/Animated-Icons/cible/cible-icon-2.png"
import Icon3 from "../../assets/Animated-Icons/cible/cible-icon-3.png"
import Icon4 from "../../assets/Animated-Icons/cible/cible-icon-4.png"

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './chasma_cibile_animate.css'


const AnimatedCardsCible = () => {
    return (
        <>
            <div className='px-3'>
                <Row className='justify-content-center align-items-center'>
                                    <Col md={10} lg={8} className='col-12'>
                                        <div className='pt-2'></div>
                                        <div className='text-center pt-2  '>

                                            <h1 className='fw-bold'>One Visual Flow Diagram is worth a thousand user surveys</h1>

                                            <p className='py-3'>One visual flow diagram is worth a thousand user surveys. Chasma Cible gives you direct insight into user flow and interest, giving you an edge in understanding user behavior.
                                            </p>

                                        </div></Col>
                                </Row>
            </div>
            <Container fluid='md' className='my-lg-5 my-3'>
                
           
                <div>
                    
                    <Row className='justify-content-center'>
                        <Col md={6} lg={3} className='d-flex justify-content-center my-2  px-3 col-12 '>
                            <div className="obj-backward_cibil">
                                <div className="Redcard_cibil"></div>
                                <div className="mx-auto card height-adj_cibil  px-lg-1 content_cibil " id='height-adj'>

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect_cibil my-5" id='myDIV'></div>
                                            <img className="py-3  bring-front" src={Icon1} alt="img" />
                                            <h5 className="fw-bold m-0 pt-4">Power Up Your Talent Acquisition Sourcing</h5>
                                            <p className="captionP py-1 px-2">A "post and pray" scattergun approach to talent sourcing can be a waste of resources. Get visual insights into your most valuable channels.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={3} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward_cibil">
                                <div className="Redcard_cibil"></div>
                                <div className="mx-auto card height-adj_cibil px-lg-1 content_cibil" id='height-adj' >

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect_cibil my-5" id='myDIV'></div>
                                            <img className="py-2  bring-front" src={Icon2} alt="img" />
                                            <h5 className=" m-0  fw-bold pt-4">Tune up Your Candidate Journey</h5>

                                            <p className="captionP py-1  px-2">Candidate reviews have a significant impact on your reputation. See where you are falling short with a candidate journey map of every touchpoint.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={3} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward_cibil">
                                <div className="Redcard_cibil" ></div>
                                <div className="mx-auto card height-adj_cibil px-lg-1 content_cibil" id='height-adj'>

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect_cibil my-5" id='myDIV'></div>
                                            <img className="py-2  bring-front" src={Icon3} alt="img" />
                                            <h5 className=" m-0  fw-bold pt-4">Analyze Open Enrollment Behavior</h5>
                                            <p className="captionP py-1 px-2">Analyze your employees' preferences to learn where to target your offerings. Map user behaviors to understand where your people give up on the process and pick up the phone.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={3} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward_cibil">
                                <div className="Redcard_cibil" ></div>
                                <div className="mx-auto card height-adj_cibil px-lg-1 content_cibil" id='height-adj' >

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect_cibil my-5" id='myDIV'></div>
                                            <img className=" m-0 py-2  bring-front" src={Icon4} alt="img" />
                                            <h5 className=" fw-bold pt-4">Boost the Onboarding Experience</h5>
                                            <p className="captionP py-1 px-2">Go beyond checklists and surveys to a deeper understanding of how new employees assimilate into the flow of work.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>

                    </Row>
                </div>
            </Container>


        </>
    )
}

export default AnimatedCardsCible