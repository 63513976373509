

import React, { useEffect } from 'react'
import './chasma_apps.css';
import AnimatedCardsApps from './chasma_animate_apps_cards'
import ChasmaAppsServiceCards from '../chasma_apps/chasmaAppsServiceCards';
import GetredycardApps from './chasma_getredypink_apps_card';

import Banner from '../chasma_analytics/Banner';
import { Seo } from '../SEO/seo';


const ChasmaApps = () => {

    useEffect(() => {
        document.title = 'Chasma Apps';
    }, []);

    return (
        <>
            <Seo
                title= "HCM Technology Apps | HR Technology Implementation | Chasma Place"
                description= "Extend the capability of your HR technology and automate your HR Processes with Chasma's modern HR apps that you can easily plug into your enterprise platforms."
                type= "website"
                name= "Chasma Apps"
                url= 'https://chasma.io/chasmaapps'
                image="https://chasma.io/static/media/chasma-apps.236a88e7.png"
            />

            <Banner heading='Chasma Apps' imgURL='chasma-apps' caption='Update, extend and complement your HR applications' />
            <AnimatedCardsApps />
            <ChasmaAppsServiceCards />
            <GetredycardApps />





        </>
    )
}

export default ChasmaApps