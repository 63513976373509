import React from 'react'
import Icon1 from "../../assets/Animated-Icons/Analytics/analytics-icon-1.png"
import Icon2 from "../../assets/Animated-Icons/Analytics/analytics-icon-2.png"
import Icon3 from "../../assets/Animated-Icons/Analytics/analytics-icon-3.png"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/chasma_home_section2.css'


const AnimatedCards = () => {
    return (
        <>
            <div className='px-4'> <Row className='justify-content-center align-items-center'>
                    <Col md={11} lg={10} className='col-12'>
                        
                        <div className='text-center pt-2  '>

                            <h1 className='fw-bold'>Chasma Analytics for your HR System</h1>

                            <p className='py-3'>Takes you from raw data to visualization without weeks or months of costly development.
                            </p>

                        </div></Col>
                </Row></div>
            <Container fluid='md' className='my-lg-5 my-3'>
                <div>
               
                    <Row className='justify-content-center align-items-center'>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12 '>
                            <div className="obj-backward">
                                <div className="Redcard"></div>
                                <div className="mx-auto card height-adj px-2 content" id='height-adj'>

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-3  bring-front" src={Icon1} alt="img" />

                                            <p className=" py-4 fs-lg-5 px-2">Dashboards and data models that address operational reporting concerns.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">
                                <div className="Redcard"></div>
                                <div className="mx-auto card height-adj px-2 content" id='height-adj'>

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-2  bring-front" src={Icon2} alt="img" />

                                            <p className="py-4 fs-lg-5 px-2">Real-time business intelligence for accurate decision making.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">
                                <div className="Redcard" ></div>
                                <div className="mx-auto card height-adj px-2 content" id='height-adj'>

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-2  bring-front" src={Icon3} alt="img" />
                                            <p className=" py-4 fs-lg-5 px-2">Avoid costly customizations and enjoy rapid deployment.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>

                    </Row>
                </div>
            </Container>


        </>
    )
}

export default AnimatedCards