import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


// clent-cards
import Daiichi from "../../assets/editor-icons/daiichi.png";
import Aabbot from '../../assets/editor-icons/Abbot.png'
import Cabi from '../../assets/editor-icons/cabi.png';
import Kubota from '../../assets/editor-icons/Kubota.png';
import Volkswagen from '../../assets/editor-icons/vw.png';
import Linde from '../../assets/editor-icons/Linde.png'
import Moraine from '../../assets/editor-icons/moraine-(1).png'
import Hcsc from '../../assets/editor-icons/hcsc.png'
import 'react-toastify/dist/ReactToastify.css';
import { IoArrowBack } from "react-icons/io5";
import { useState, useEffect } from 'react';
import FormSuccess from '../../assets/success.gif'
import '../Demo_Sales/popup.css'


const Contact_us_form = () => {

  const [isSubmitted, setSubmitted] = useState(false)
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "1751195",
          formId: "9e910843-3150-41a1-a418-083c159318ad",
          target: '.myFormDemo',
          formInstanceId: '1',
          onFormSubmit: () => {setSubmitted(true);},
          // submitText: 'Submit',
          css: `.myFormDemo{
            color:white !important;
            width:100%;
            display:flex;
            justify-content: center;
            align-items: center;
        }
        #hsForm_9e910843-3150-41a1-a418-083c159318ad_1{
          width:80%;
        }
        .hs-main-font-element, .hs-error-msgs>li{
          background-color: inherit !important;
          color:#FFD609 !important;
        }
        .hs-form-field{
          padding:2% 0%
        }
        
        .hs-input{
          border-radius: 5px;
          border-width: 0;
          padding: 4px 10px !important;
          border-color:none;
        }
        .hs-button{
          padding: 6px 25px;
           border-radius: 26px;
           color:white;
          border-color: inherit;
          background-color:#ffc107;
          outline-offset: none;
          border-width: 0px;
          --bs-btn-color: #000;
          --bs-btn-bg: #ffc107;
          --bs-btn-border-color: #ffc107;
          --bs-btn-hover-color: #000;
          --bs-btn-hover-bg: #ffca2c;
          --bs-btn-hover-border-color: #ffc720;
          --bs-btn-focus-shadow-rgb: 217, 164, 6;
          --bs-btn-active-color: #000;
          --bs-btn-active-bg: #ffcd39;
          --bs-btn-active-border-color: #ffc720;
          --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          --bs-btn-disabled-color: #000;
          --bs-btn-disabled-bg: #ffc107;
          --bs-btn-disabled-border-color: #ffc107;

        }
        .actions{
          text-align:center
        }
        .hs_error_rollup{
          text-align:start !important
        }
        .myFormDemo .submitted-message{
          font-size:30px !important;
          text-align:center;
        }
        @media screen and (max-width:992px) {
          .myFormDemo .submitted-message{
            font-size:20px !important;
            white-space:wrap !important;
          }
          .hs-form-field{
            padding:3% 0%;
            
          }
          #hsForm_9e910843-3150-41a1-a418-083c159318ad_1 .hs_submit.hs-submit{
            margin-top:4%;
            margin-bottom:2%
          }
        }
        @media screen and (max-width:620px) {
          .hs-form-field{
            padding:5% 0%;

            }
            #hsForm_9e910843-3150-41a1-a418-083c159318ad_1 .hs_error_rollup{
              text-align:start;
              margin-bottom:2%
            }
            #hsForm_9e910843-3150-41a1-a418-083c159318ad_1 .hs_submit.hs-submit{
              margin-top:6%
            }
        }
      
        #phone_ext-9e910843-3150-41a1-a418-083c159318ad_1{
          margin-left:-10px
        }
        #phone-9e910843-3150-41a1-a418-083c159318ad_1{
          width:70% !important;
          margin-right:-10px;
        }
        `

       
        })
      }
    });
  }, []);
  const MeetingLink='https://meetings.hubspot.com/natasha-ramdial-roopnarine';
  const CustomPopup = () => (
    <div className="custom-popup">
      <div className='popup'>
        <div className='d-flex flex-column justify-content-evenly'>

          <div className='w-100 d-flex justify-content-center mx-auto'>
            <img src={FormSuccess} alt="" className=' img-fluid successImg mb-4' />
          </div>
          <h2 className='text-center fw-bold' style={{ color: '#3335C4' }}> Thanks for reaching out!</h2>
          <p className='text-center text-dark'>
            A Chasma specialist will be in touch shortly to discuss how we can help you unlock the power of your HR tech.
            In the meantime, explore how Chasma can transform your HR experience!
          </p>
          <p className='text-center d-flex justify-content-center   mt-4'>
            <button className='btn btn-outline-purple backButton mx-1 ' onClick={() => { setSubmitted(false) }}><span><IoArrowBack /></span>Back</button>
            <a href={MeetingLink}> <button className='btn btn--purple mx-1 '>Schedule a FREE consultation</button></a>

          </p>


        </div>


      </div>

    </div>
  );
  return (
    <>
      <div className='mt-5'></div>
      <div className='bg-color-form p-2 pb-3'>
        <Container className=''>
          {isSubmitted && <CustomPopup />}
          <div className='d-flex justify-content-center h-100 w-100'>
            <div className='mt-4 w-100'>
              <h2 className='fw-bold text-center'>Contact Us</h2>
              <div className="myFormDemo"></div>

            </div>

          </div>

        </Container>

      </div>
      <Container className=''>
        {/* client cards */}

        <Row className='d-flex justify-content-center align-items-center mt-md-5 mt-lg-4'>
          <Col md={3} className='d-flex justify-content-center align-items-center px-3 col-6'> <a href="https://www.daiichisankyo.com/" target="_blank" rel="noopener noreferrer"> <img src={Daiichi} alt="Daiichi" className='p-3 img-fluid' /></a></Col>
          <Col md={3} className='d-flex justify-content-center align-items-center px-3 col-6'> <a href="https://www.abbott.com/" target="_blank" rel="noopener noreferrer"> <img src={Aabbot} id="images" alt="Aabbot" className='img-fluid p-3' /></a></Col>
          <Col md={3} className='d-flex justify-content-center align-items-center px-3 col-6'> <a href="https://www.hcsc.com/" target="_blank" rel="noopener noreferrer"> <img src={Hcsc} id="images" alt="Hcsc" className=' img-fluid p-3' /></a></Col>
          <Col md={3} className='d-flex justify-content-center align-items-center px-3 col-6'> <a href="https://www.cabionline.com/" target="_blank" rel="noopener noreferrer"><img src={Cabi} id="images" alt="cabi" className='img-fluid p-3' /></a></Col>

        </Row>
        <Row className='d-flex justify-content-center align-items-center mt-md-5 mt-lg-4 '>
          <Col md={3} className='d-flex justify-content-center align-items-center px-3 col-6'> <a href="https://www.morainepark.edu/" target="_blank" rel="noopener noreferrer"> <img src={Moraine} id="images" alt="Moraine" className=' img-fluid p-3' /></a></Col>
          <Col md={3} className='d-flex justify-content-center align-items-center px-3 col-6'> <a href="https://www.volkswagen-group.com/en" target="_blank" rel="noopener noreferrer"> <img src={Volkswagen} id="images" alt="Volkswagen" className=' img-fluid p-3' /></a></Col>
          <Col md={3} className='d-flex justify-content-center align-items-center px-3 col-6'> <a href="https://www.kubota.com/" target="_blank" rel="noopener noreferrer"> <img src={Kubota} id="images" alt="kubota" className=' img-fluid p-3' /></a></Col>
          <Col md={3} className='d-flex justify-content-center align-items-center px-3 col-6'> <a href="https://www.linde.com/" target="_blank" rel="noopener noreferrer"><img src={Linde} id="images" alt="Linde" className=' img-fluid p-3' /></a></Col>
        </Row>



      </Container>

    </>

  )
}

export default Contact_us_form