import React, { useEffect } from 'react'
import Container from 'react-bootstrap/esm/Container';
import '../chasma_contact_us/contact_us.css';
import Demoform from './Demo_form'
import { Seo } from '../SEO/seo';
import Banner from "./Banner"
import SalesContactUsContainer from './SalesContactUsContainer';

const Demo = () => {
    useEffect(() => {
        document.title = 'Demo';
    }, []);
    return (
        <>
            <Seo
                title= " Demo | Chasma Place"
                description= "Chasma’s innovative suite of HR apps, analytics, connectors offer agile solutions for your human resources technology challenges &amp; improve your HR efficiency."
                type= "website"
                name= "Chasma Demo"
                url= 'https://chasma.io/demo'
                image='https://chasma.io/static/media/Contact-us.3c5c7c97.png'
            />

            <Banner heading='Demo' imgURL='Contact-us' caption="We'd love you to reach us and let us know how we can help you." />

            <Container>

                <SalesContactUsContainer />
            </Container>
            <Demoform />

        </>
    )
}

export default Demo





