

import React, { useEffect } from 'react'
import './people_hrbox.css';
import '../../styles/Banner.css'
import AnimatedCardsPeopleHRBOX from './people_hrbox_animates_cards';
import ServiceCardsPeopleHRBOX from './people_hrbox_service_cards';
import GetredycardPeopleHRBOX from './people_hrbox_getredypink_card';
import { Seo } from '../SEO/seo';
import Banner from '../chasma_analytics/Banner';







const PeopleAnalytics = () => {

    useEffect(() => {
        document.title = 'People Analytics with HRBOX';
    }, []);

    return (
        <>

            <Seo
                title= "People Analytics Dashboards | HR KPI’s | Chasma Place "
                description= "Get profound insights into your HR data without coding with Chasma HRBox, an easy to use design tool to create visual insightful dashboards for your people data. "
                type= "website"
                name= "Chasma HRbox"
                url= 'https://chasma.io/chasmaanalytics/chasmahrbox'
                image='https://chasma.io/static/media/Chasma-Hr-Box.4ff17e55.png'
            />
            <Banner heading='Chasma HRBox' imgURL='Chasma-Hr-Box' caption='Dashboard Design for All your HR systems — without coding'/>
            <AnimatedCardsPeopleHRBOX />
            <ServiceCardsPeopleHRBOX />
            <GetredycardPeopleHRBOX />



        </>
    )
}

export default PeopleAnalytics