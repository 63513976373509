

import React, { useEffect } from 'react'
import EditorImage from '../../assets/PrivacyPolicy.png'
import './SiteMap.css'
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/esm/Container';
import ClientCards from'../Cornerstone_Analytics/Cornerstone_Analytics_clint_cards'
import { Link } from 'react-router-dom';
import { Seo } from '../SEO/seo';

const SiteMap = () => {
        
  const handleLinkClick = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

    useEffect(() => {
        document.title = 'Chasma | Site Map';
    }, []);

    return (
        <>
            <Seo
                title= "Contact US | Chasma Place"
                description= "Chasma’s innovative suite of HR apps, analytics &amp; connectors offer agile solutions for your human resources technology challenges &amp; improve your HR efficiency. "
                type= "website"
                name= "Chasma Site Map"
                url= 'https://chasma.io/site-map'
                image='https://chasma.io/static/media/PrivacyPolicy.a0953fc2.png'
            />
            <Container className='bannerPrivacyPolicy'>
                <div className='w-50  d-flex  align-items-center '>
                    <h1>Site Map</h1>
                </div>
                <div className='w-50'><img src={EditorImage} className='img-fluid' alt="chasma-privacy-policy" /></div>
            </Container>
            <Container className='mt-4 pt-2 px-5 '>
                <Row>
                    <ul className='parentList'>
                        <li> <Link  onClick={handleLinkClick}  to={'/chasmaapps'}>Apps</Link>
                            <ul className='chaildList'>
                                    <li><Link  onClick={handleLinkClick}  to={'/chasmaapps/chasmaeditor'}> Chasma Editor</Link></li>
                                    <li><Link  onClick={handleLinkClick}  to={'/chasmaapps/chasmaevent'}> Chasma Event</Link></li>
                                    <li><Link  onClick={handleLinkClick} to={'/chasmaapps/chasmalexy'}> Chasma Lexy</Link></li>
                                    <li><Link  onClick={handleLinkClick} to={'/chasmaapps/chasmaquale'}> Chasma Quale</Link></li>
                                    <li><Link  onClick={handleLinkClick} to={'/chasmaapps/chasmanatiodesk'}> Natio Desk</Link></li>
                                    <li><Link  onClick={handleLinkClick} to={'/chasmaapps/chasmabotbuilder'}>Bot Builder</Link></li>
                                    <li><Link  onClick={handleLinkClick} to={'/chasmaapps/chasmacible'}> Cible</Link></li>
                                    

                            </ul>
                        </li>
                        <li><li> <Link  onClick={handleLinkClick} to={'/chasmaanalytics'}>Analytics</Link>
                            <ul className='chaildList'>
                                    <li><Link  onClick={handleLinkClick} to={'/chasmaanalytics/sumtotal-analytics'}> Sumtotal Analytics</Link></li>
                                    <li><Link  onClick={handleLinkClick} to={'/chasmaanalytics/chasmahrbox'}> People Analytics With HRBox</Link></li>
                                    <li><Link  onClick={handleLinkClick} to={'/chasmaanalytics/cornerstone-analytics'}> Cornerstone Analytics</Link></li>
                                    <li><Link  onClick={handleLinkClick} to={'/chasmaanalytics/people-analytics-power-bi'}> People Analytics With PowerBI</Link></li>
                                    <li><Link  onClick={handleLinkClick} to={'/chasmaanalytics/workday-analytics'}> Workday Analytics</Link></li>
                            </ul>
                        </li></li>
                        <li><Link  onClick={handleLinkClick} to={'/chasmaconnect'}>Connectors</Link></li>
                        <li><Link  onClick={handleLinkClick} to={'/aboutus'}>About Us</Link></li>
                        <li><Link  onClick={handleLinkClick} to={'/contactus'}>Contact Us</Link></li>
                    </ul>
                </Row>
                <Row>
                    <ClientCards/>
                </Row>
            </Container>
        </>
    )
}

export default SiteMap