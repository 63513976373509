import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/chasma_home_section2.css'


const AnimatedCardsBotbuilder = () => {
    return (
        <>
            <div className='px-4'>
               
            </div>
            <Container fluid='md' className='my-lg-4 my-3'>
                <div>
                <Row className='justify-content-center align-items-center'>
                        <Col md={12} lg={12} className='col-12 '>
                        
                            <div className='text-center pt-2  '>

                                <h1 className='fw-bold'>Boost Customer Service with Intelligent Chatbots</h1>

                                <p className=''>A lot of people today are happy to interact with chatbots. You can deploy a smart, friendly chatbot to do most of the routine tasks that humans do now, and you can do it in such a way that your customers will be delighted.
                                </p>
                                <p className='py-3'>Chasma's Bot Builder gives you all the tools you need to offload the tedious, repetitious work to a smart machine so your agents can focus on service that makes a difference.
                                </p>
                                <p className=''>For example, your Bot can capture account numbers, emails, order numbers, and issue description, then answer questions or determine that a live agent is needed—then make the transition automatically.
                                </p>

                            </div></Col>
                    </Row>
                  
                </div>
            </Container>


        </>
    )
}

export default AnimatedCardsBotbuilder