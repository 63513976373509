
import React, { useState, useEffect } from 'react';
import './HRBox_Carousel.css';
import { BiChevronRight, BiChevronLeft } from "react-icons/bi";

const MAX_VISIBILITY = 3;
const Slides = [
  {
    id: 1,
    url: "HR_Box_Data_Manager",
    alt: "Chasma Editor Admin Dashboard"
  },
  {
    id: 2,
    url: "HR_Learn_Dashboard",
    alt: "Chasma_Editor_Manager1"
  },
  {
    id: 3,
    url: "HRBox_Dashboards",
    alt: "Chasma_Editor_Manager2"
  },
  {
    id: 4,
    url: "HRBox_Risk_Factors",
    alt: "Chasma_Editor_Manager3"
  },
  {
    id: 5,
    url: "HRBox_Visualizations",
    alt: "Chasma_Editor_Learner1"
  },
  {
    id: 6,
    url: "HRBox_Widget_Update",
    alt: "Chasma_Editor_Learner2"
  },
];

const Carousel = ({ children }) => {
  const [active, setActive] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActive((prevIndex) => (prevIndex + 1) % Slides.length);
    }, 5000); // Autoscroll every 10 seconds

    return () => clearInterval(interval);
  }, []);


  const handleClickPrev = () => {
    setActive((prevIndex) => (prevIndex === 0 ? Slides.length - 1 : prevIndex - 1));
  };

  const handleClickNext = () => {
    setActive((prevIndex) => (prevIndex + 1) % Slides.length);
  };

  return (
    <div className='carousel'>
      <button className='nav_bar left' onClick={handleClickPrev}><BiChevronLeft className='icon-color' /></button>
      {React.Children.map(children, (child, i) => (
        <div
          key={i}
          className='card-container d-flex align-items-center justify-content-center'
          style={{
            '--active': i === active ? 1 : 0,
            '--offset': (active - i) / 3,
            '--direction': Math.sign(active - i),
            '--abs-offset': Math.abs(active - i) / 3,
            pointerEvents: active === i ? 'auto' : 'none',
            opacity: Math.abs(active - i) >= MAX_VISIBILITY ? '0' : '1',
            display: Math.abs(active - i) > MAX_VISIBILITY ? 'none' : 'block',
          }}
        >
          {child}
        </div>
      ))}
      <button className='nav_bar right' onClick={handleClickNext}><BiChevronRight className='icon-color' /></button>
    </div>
  );
};

const App = () => (
  <div className='app'>
    <Carousel>
      {Slides.map((image) => {
        const { id, url, alt } = image;
        return <img key={id} src={require(`../../assets/ChasmaHRBoxAnalyticsCarouselImages/${url}.jpg`)} alt={alt} className='image-resulution' />;
      })}
    </Carousel>
  </div>
);

export default App;
