

import React, { useEffect } from 'react'
import EditorImage from '../../assets/PrivacyPolicy.png'
import './Cookie_Policy.css'
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/esm/Container';
import ClientCards from'../Cornerstone_Analytics/Cornerstone_Analytics_clint_cards'
import { Seo } from '../SEO/seo';
const PrivacyPolicy = () => {

    useEffect(() => {
        document.title = 'Chasma | Cookie Policy';
    }, []);

    return (
        <>
             <Seo
                title= " Cookie Policy Online | Chasma Place | Browser Cookies "
                description= "Our Cookies Policy explains what cookies are, how we use cookies, how third-parties we may partner with may use cookies on the Service."
                type= "website"
                name= "Cookie Policy Online"
                url= 'https://chasma.io/cookie-policy '
                image='https://chasma.io/static/media/PrivacyPolicy.a0953fc2.png'
            />
            <Container className='bannerPrivacyPolicy'>
                <div className='w-50  d-flex  align-items-center '>
                    <h1>Cookie Policy</h1>
                </div>
                <div className='w-50'><img src={EditorImage} className='img-fluid' alt="chasma-privacy-policy" /></div>
            </Container>
            <div className='mt-4 pt-2 px-5 container'>
                <h4 className='text-center fw-bold mt-4 mb-4 '>Cookie Policy</h4>
                <Row>
                    <p>
                    Cookies are very small text files saved to your computer used to track user navigation 
                    around a website. At Chasma.io session cookies are used when users log-in to the site.
                     We use such cookies to identify unique users to our sites. They are not cross-referenced 
                    with any other information such that an individual person could be identified.
                    </p>
                    <p>
                    Chasma.io may use a third party to serve advertisements on our sites. 
                    The advertisers may use cookies to track the number of users responding to the campaign.
                     We do not have access to or control of cookies placed by third parties.
                    </p>
                </Row>
               
                <Row>
                    <ClientCards/>
                </Row>
            </div>
        </>
    )
}

export default PrivacyPolicy