import React from 'react'
import Icon1 from "../../assets/Animated-Icons/Hr Box/Hr Box-icon-1.png"
import Icon2 from "../../assets/Animated-Icons/Hr Box/Hr Box-icon-2.png"
import Icon3 from "../../assets/Animated-Icons/Hr Box/Hr Box-icon-3.png"

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/chasma_home_section2.css'


const AnimatedCardsPeopleHRBOX = () => {
    return (
        <>
            <Container fluid='md' className='my-lg-4 my-3'>
                <div>
                <Row className='justify-content-center align-items-center'>
                    <Col md={10} lg={8} className='col-12'>
                     
                        <div className='text-center pt-2  '>
                    
                            <h1 className='fw-bold'>Drag and Drop Dashboard Design</h1>

                            <p className='py-3'>Chasma’s HRBox library of templates enables anyone to create colorful and useful dashboard designs. Use our library of metrics and KPIs to get a head start on insights into your HR operations.
                            </p>

                        </div></Col>
                </Row>
                    <Row className='justify-content-center align-items-center'>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12 '>
                            <div className="obj-backward">
                                <div className="Redcard"></div>
                                <div className="mx-auto card height-adj px-3 content" id='height-adj' style={{ width: "22rem" }}>

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-2  bring-front" src={Icon1} alt="img" />

                                            <p className=" py-4 fs-lg-5 px-3">Create visual insights into your HR operations without programming.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">
                                <div className="Redcard"></div>
                                <div className="mx-auto card height-adj px-3 content" id='height-adj' style={{ width: "22rem" }}>

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-2  bring-front" src={Icon2} alt="img" />

                                            <p className="py-4 fs-lg-5 px-3">Aggregate data from any of your HR applications.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">
                                <div className="Redcard" ></div>
                                <div className="mx-auto card height-adj px-3 content" id='height-adj' style={{ width: "22rem" }}>

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-2  bring-front" src={Icon3} alt="img" />
                                            <p className=" py-4 fs-lg-5 px-3">Share dashboards with anyone in your organization.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>

                    </Row>
                </div>
            </Container>


        </>
    )
}

export default AnimatedCardsPeopleHRBOX