import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import './chasma_events.css';
// icon images------------
import FreeAppforIndividuals from '../../assets/events-icons/mobile-app.png'
import ManageDetails from '../../assets/events-icons/team-management.png'
import UseFreeMobileApps from '../../assets/events-icons/feature.png'
import FasterCheckIn from '../../assets/events-icons/scanner.png'
import AttendanceDashboard from '../../assets/events-icons/web-analysis.png'
import BetterDataQuality from '../../assets/events-icons/data-analytics.png'
function serviceCardEvents() {
    return (
        <>
            <div className='backgroundColor'>
                <Container className='pt-lg-2 stars-attach' >
                    <Row className='justify-content-center align-items-center'>
                        <Col md={8} lg={11} className='col-12 text-white'>

                            <div className='text-center pt-5 px-lg-5 '>

                                <h1 className='fw-bold'>Chasma Event Advantage</h1>

                                <p className='py-md-4 px-md-5 px-2 '>Chasma event makes your events easier to manage and helps you manage all the resources and support that make your happenings memorable.</p>

                            </div></Col>
                    </Row>
                    <Row className='justify-content-center align-items-center pt-5'>
                        {/*------------ first 3 coloms ------------------*/}
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_events px-4 pt-5">
                                    <div className=" d-flex align-items-center">

                                        <div className=''>
                                            <img src={FreeAppforIndividuals} alt="Free App for Individuals" className='img-fluid' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Free App for Individuals</div>

                                    </div>
                                    <p className="py-3">Help managers and team leaders manage team events.</p>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_events px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={ManageDetails} alt="Manage Details" className='img-fluid' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Manage Details</div>

                                    </div>
                                    <p className="py-3">Provide a map and direction on the mobile app. Create to-do lists and task assignments.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_events px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={UseFreeMobileApps} alt="Use Free Mobile Apps" className='img-fluid' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Use Free Mobile Apps</div>

                                    </div>
                                    <p className="py-3">Generate passes for Mobile Wallet, MobilePASS, or other digital authentication.
                                    </p>
                                </div>
                            </div>

                        </Col>


                        {/*------------ second 3 coloms ------------------*/}
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 py-lg-5 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_events px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={FasterCheckIn} alt="Faster Check-In" className='img-fluid' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Faster Check-In</div>

                                    </div>
                                    <p className="py-3">Don’t make attendees stand in line to sign in. A quick scan of a mobile device, printed ticket, or badge is all it takes.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3  py-lg-5 my-lg-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_events px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={AttendanceDashboard} alt="Attendance Dashboard" className='img-fluid' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Attendance Dashboard</div>

                                    </div>
                                    <p className="py-3">Manage invitations and attendance with an easy-to-use dashboard.</p>
                                </div>
                            </div>
                        </Col>

                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12 py-lg-5'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_events px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={BetterDataQuality} alt="Better Data Quality" className='img-fluid' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Better Data Quality</div>

                                    </div>
                                    <p className="py-3">Eliminate messy data caused by manual sign-in sheets, manual data entry, and spreadsheet errors.</p>
                                </div>
                            </div>

                        </Col>
                        {/*------------ third 3 coloms ------------------*/}
                    </Row>


                </Container>
            </div></>
    )
}

export default serviceCardEvents