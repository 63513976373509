

import React, { useEffect } from 'react'


 import Clintgridcards from './chasma_customers_clint_cards'
 import ChasmaCompany from './chasma_company'
import { Seo } from '../SEO/seo'


const Customers = () => {

    useEffect(() => {
        document.title = 'Chasma Customers';
    }, []);

    return (
        <>
            <Seo
                title= "Customers | Chasma Place"
                description= "Enhance your employee experience with Chasma's HR apps that can support your HR systems optimization, HR technology implementation & HR analytics & reporting."
                type= "website"
                name= "Chasma Customers"
                url= 'https://chasma.io/customers'
                image='https://chasma.io/static/media/Chasma%20logo.abc0a6fa.svg'
            />
            <Clintgridcards />
            <ChasmaCompany/>
         



        </>
    )
}

export default Customers