import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import './chasma_analytics.css';



// icon images------------

import PreConfiguredDashboard from '../../assets/chasma-analytics/Pre_configured.png'
import RealTimeVisualization from '../../assets/chasma-analytics/Real-time_Visualization.png'
import DataVisualizations from '../../assets/chasma-analytics/Data_Visualizations.png'
import AdvancedAnalytics from '../../assets/chasma-analytics/Advanced_Analytics.png'
import AnalyticalVisualizations from '../../assets/chasma-analytics/Analytical_Visualizations.png'
import PredictiveModels from '../../assets/chasma-analytics/Predictive_Models.png'
// import Stars from '../../assets/stars.png'


function serviceCards_Analytics() {
    return (
        <>
            <div className='backgroundColor'>
                <Container className='pt-lg-2 stars-attach' >
                 
                    <Row className='justify-content-center align-items-center'>
                        <Col md={12} lg={11} className='col-12 text-white'>

                            <div className='text-center pt-5 px-lg-5 '>

                                <h1 className='fw-bold'>Visualizations That Help You Make Decisions At The Speed Of Business</h1>
                                <p className='py-md-4 px-md-5 px-2 '>Chasma Analytics provides operational dashboards any business can use. We avoid costly customizations by addressing the reporting needs that almost every organization faces and by doing so we can provide them at a rapid pace and at a much lower cost.</p>

                            </div></Col>
                    </Row>
                    <Row className='justify-content-center align-items-center pt-5'>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_analytics px-4 pt-5">
                                    <div className=" d-flex align-items-center">

                                        <div className=''>
                                            <img src={PreConfiguredDashboard} alt="Pre-configured
                                            Dashboard Designs" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Pre-configured
                                            Dashboard Designs</div>

                                    </div>
                                    <p className="py-3">Personalized canvases and dashboards to build-user centered portals and landing pages.</p>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_analytics px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={RealTimeVisualization} alt="Real-time Visualization" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Real-time Visualization</div>

                                    </div>
                                    <p className="py-3">Real-time visualizations of important metrics like employee cost vs performance, headcount analysis and more.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_analytics px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={DataVisualizations} alt="Data Visualizations" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Data Visualizations</div>

                                    </div>
                                    <p className="py-3">Data visualizations that help your executives make decisions at the speed of business.
                                    </p>
                                </div>
                            </div>

                        </Col>


                        {/*------------ first 3 coloms ------------------*/}
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 py-lg-5 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_analytics px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={AdvancedAnalytics} alt="Advanced Analytics" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Advanced Analytics</div>

                                    </div>
                                    <p className="py-3">Advanced analytics to give you deep insights into your workforce data.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3  py-lg-5 my-lg-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_analytics px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={AnalyticalVisualizations} alt="Analytical Visualizations" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Analytical Visualizations</div>

                                    </div>
                                    <p className="py-3">Analytical visualizations that help your talent professionals analyze the impact of their programs and processes.</p>
                                </div>
                            </div>
                        </Col>

                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12 py-lg-5'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_analytics px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={PredictiveModels} alt="Predictive Models" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Predictive Models</div>

                                    </div>
                                    <p className="py-3">Predictive models to increase efficiencies with data driven decisions that capitalize on your people potential.</p>
                                </div>
                            </div>

                        </Col>

                    </Row>


                </Container>
            </div></>
    )
}

export default serviceCards_Analytics