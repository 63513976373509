import React from 'react'
import chama_animated_apps_icon1 from "../../assets/Animated-Icons/Events/events-icon-1.png"
import chama_animated_apps_icon2 from "../../assets/Animated-Icons/Events/events-icon-2.png"
import chama_animated_apps_icon3 from "../../assets/Animated-Icons/Events/events-icon-3.png"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/chasma_home_section2.css'


const AnimatedCardEvents = () => {
    return (
        <>
            <Container fluid='md' className='my-lg-5 my-3'>
                <div>
                    <Row className='justify-content-center align-items-center'>
                        <Col md={11} lg={10} className='col-12'>
                            <div className='pt-md-1 pt-1'></div>
                            <div className='text-center pt-1  '>

                                <h1 className='fw-bold'>Automated Event Management</h1>

                                <p className='py-3'>Chasma Event is mobile event management software for employee events or any event across your organization. It automates invitations, RSVPs, details and directions, and resources for any size gathering.

                                </p>

                            </div></Col>
                    </Row>
                    <Row className='justify-content-center align-items-center'>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12 '>
                            <div className="obj-backward">
                                <div className="Redcard"></div>
                                <div className="mx-auto card height-adj px-3 content" id='height-adj'>

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-3  bring-front" src={chama_animated_apps_icon1} alt="img" />
                                            <p className=" py-4 fs-lg-5 px-3">Manage registration and eligibility for credit.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">
                                <div className="Redcard"></div>
                                <div className="mx-auto card height-adj px-3 content" id='height-adj'>

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-3  bring-front" src={chama_animated_apps_icon2} alt="img" />

                                            <p className="py-4 fs-lg-5 px-3">Automate walk-in registration or enrollment.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">
                                <div className="Redcard" ></div>
                                <div className="mx-auto card height-adj px-3 content" id='height-adj'>

                                    <div className="text-center">

                                        <div className='py-5'>
                                            <div className="box-reflect my-5" id='myDIV'></div>
                                            <img className="py-2  bring-front" src={chama_animated_apps_icon3} alt="img" />
                                            <p className=" py-4 fs-lg-5 px-3">Set up reminders by email and text message.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>

                    </Row>
                </div>
            </Container>


        </>
    )
}

export default AnimatedCardEvents