

import React, { useEffect } from 'react'

import './chasma_Botbuilder.css';
import ServiceCardsBotbuilder from './chasma _Botbuilder_service_cards';
 import AnimatedCardsBotbuilder from './chasma_animate_Botbuilder_cards';
import GetredycardBotbuilder from './chasma_getredypink_Botbuilder_card';
import Banner from '../chasma_analytics/Banner';
import { Seo } from '../SEO/seo';



const ChasmaBotbuilder = () => {

    useEffect(() => {
        document.title = 'Bot builder';
    }, []);

    return (
        <>

            <Seo
                title= "Chasma Bot Builder | Chatbot builder"
                description= "Bot Builder lets you offload repetitious work to a smart machine so your agents can focus on service that makes a difference."
                type= "website"
                name= "Chasma Botbuilder"
                url= 'https://chasma.io/chasmabotbuilder '
                image='https://chasma.io/static/media/chasma-Botbuilder.9be0a6e4.png'
            />
            <Banner heading='Chasma Bot Builder' imgURL='chasma-Botbuilder' caption='Chatbot Management for Non-technical Users'/>
            <AnimatedCardsBotbuilder />
            <ServiceCardsBotbuilder />
            <GetredycardBotbuilder />



        </>
    )
}

export default ChasmaBotbuilder