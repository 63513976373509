import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import './sumtotal_analytics.css';



// icon images------------

import RealTimeVisualizations from '../../assets/Chasma_Analytics_For_Sum_Total-icons/Real-time_Visualization.png'
import PredictiveModelsToDrive from '../../assets/Chasma_Analytics_For_Sum_Total-icons/Predictive_models_to_drive.png'
import AdvancedAnalytics from '../../assets/Chasma_Analytics_For_Sum_Total-icons/Advanced_Analytics.png'
import KPIsandMetricsThatMatter from '../../assets/Chasma_Analytics_For_Sum_Total-icons/KPIs_and_metrics.png'
import DataVisualizationsThatHelpYour  from '../../assets/Chasma_Analytics_For_Sum_Total-icons/Data_visualizations_that.png'
// import Gamification from '../../assets/Gamification.png'
// import Stars from '../../assets/stars.png'


function serviceCardsSumtotalAnalytics() {
    return (
        <>
            <Container>
            <Row className='justify-content-center align-items-center'>
                    <Col md={11} lg={10} className='col-12 '>
                        
                        <div className='text-center py-3  '>

                            <h1 className='fw-bold textFont'>Get new insights right now into your most important metrics and KPIs</h1>

                            <p className=''>Chasma Analytics for SumTotal provides dashboards and visualizations for hundreds of use cases from in-depth research into over 1000 global organizations. We provide variety of ways to deliver KPIs and vital metrics, ready to deploy directly into SumTotal Advanced Reporting.
                            </p>


                        </div></Col>
                </Row>
            </Container>
            <div className='backgroundColor'>
                <Container className='pt-lg-2 stars-attach' >
                   
                    
                    <Row className='justify-content-center align-items-center'>
                        <Col md={11} lg={10} className='col-12 text-white'>

                            <div className='text-center pt-5 px-lg-5 '>

                                <h1 className='fw-bold textFont'>Sumtotal Analytics Advantage</h1>
                                <p className=' px-md-5 px-2 '>Improve the user experience and make an impact far beyond data and administration.</p>

                            </div></Col>
                    </Row>
                    <Row className='justify-content-center align-items-center pt-5'>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className="  align-items-center text-center">

                                        <div className=''>
                                            <img src={RealTimeVisualizations} alt="Real-time visualizations" className='' />
                                        </div>
                                        {/* <div className="fs-5 px-3 fw-bold">Pre-configured
                                            Dashboard Designs</div> */}

                                    </div>
                                    <p className="py-3 text-center">Real-time visualizations of critical operational metrics like employee cost vs. performance, learning vs. performance, and more analysis of your data.</p>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className=" align-items-center text-center">
                                        <div className=''>
                                            <img src={PredictiveModelsToDrive} alt="Predictive models to drive" className='' />
                                        </div>
                                        {/* <div className="fs-5 px-3 fw-bold">Real-time Visualization</div> */}

                                    </div>
                                    <p className="py-3 text-center">Predictive models to drive decisions that capitalize on your people potential.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className=" align-items-center text-center">
                                        <div className=''>
                                            <img src={AdvancedAnalytics} alt="Advanced analytics" className='img-fluid' />
                                        </div>
                                        {/* <div className="fs-5 px-3 fw-bold">Data Visualizations</div> */}

                                    </div>
                                    <p className="py-3 text-center">Advanced analytics that give you deeper insights into your workforce data.
                                    </p>
                                </div>
                            </div>

                        </Col>


                        {/*------------ first 3 coloms ------------------*/}
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 py-lg-5 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className="align-items-center text-center">
                                        <div className=''>
                                            <img src={KPIsandMetricsThatMatter} alt="KPIs and metrics that matter" className='' />
                                        </div>
                                        {/* <div className="fs-5 px-3 fw-bold">Advanced Analytics</div> */}

                                    </div>
                                    <p className="py-3 text-center">KPIs and metrics that matter most for your talent oragization.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3  py-lg-5 my-lg-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className=" align-items-center text-center">
                                        <div className=''>
                                            <img src={DataVisualizationsThatHelpYour } alt="Data visualizations that help your executives" className='' />
                                        </div>
                                        {/* <div className="fs-5 px-3 fw-bold">Analytical Visualizations</div> */}

                                    </div>
                                    <p className="py-3 text-center">Data visualizations that help your executives make decisions at the speed of business.</p>
                                </div>
                            </div>
                        </Col>



                    </Row>


                </Container>
            </div></>
    )
}

export default serviceCardsSumtotalAnalytics