
import React, { useEffect } from 'react'
import './WheelAnimation.css'
import ProductswithaPersonalTouch from './Products_with_a Personal_Touch';
import Banner from './Banner';
import { Seo } from '../SEO/seo';

function Whychasma() {
    useEffect(() => {
        document.title = 'Why Chasma';
    }, []);
    return (
        <>
            <Seo
                title= "About US | Chasma Place"
                description= "Enhance your employee experience with Chasma's HR apps that can support your HR systems optimization, HR technology implementation & HR analytics & reporting."
                type= "website"
                name= "Chasma | About Us"
                url= 'https://chasma.io/aboutus'
                image='https://chasma.io/static/media/Chasma%20logo.abc0a6fa.svg'
            />
            <Banner heading='Chasma Cible' imgURL='chasma-cible' caption='User Flow and Behavior Analysis for Your Enterprise Applications'/>
           
            <ProductswithaPersonalTouch />

        </>
    )
}

export default Whychasma