import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import './chasma_Cible.css';



// icon images------------

import Analyzeasingleapplication from '../../assets/cibil-icons/Analyze.png'
import Viewgraphichighlevelvisitor from '../../assets/cibil-icons/3d-view.png'
import Controlheatmapsinthemaster from '../../assets/cibil-icons/_x31_4.png'
import Viewaflowdiagramofuserentry from '../../assets/cibil-icons/flow-chart.png'
import Mapuserbehaviorstounderstand from '../../assets/cibil-icons/research.png'
// import Gamification from '../../assets/Gamification.png'
// import Stars from '../../assets/stars.png'


function serviceCardsCible() {
    return (
        <>
            <div className='backgroundColor'>
                <Container className='pt-lg-2 stars-attach' >
                 
                    <Row className='justify-content-center align-items-center'>
                        <Col md={10} lg={11} className='col-12 text-white'>

                            <div className='text-center pt-5 px-lg-5 '>

                                <h1 className='fw-bold'>Deploy Cible in your Current Apps and Platforms</h1>
                                <p className='py-md-4 px-md-5 px-2 '>Chasma Cible provides operational dashboards any business can use. We avoid costly customizations by addressing the reporting needs that almost every organization faces and by doing so we can provide them at a rapid pace and at a much lower cost.</p>

                            </div></Col>
                    </Row>
                    <Row className='justify-content-center align-items-center pt-5'>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_cibil px-4 pt-5">
                                    <div className=" d-flex align-items-center justify-content-center">

                                        <div className=''>
                                            <img src={Analyzeasingleapplication} alt="Analyze a single application or a defined group of apps to optimize the user journey." className='' />
                                        </div>   
                                    </div>
                                    <p className="py-3">Analyze a single application or a defined group of apps to optimize the user journey.</p>
                                </div>
                            </div>

                        </Col>




                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_cibil px-4 pt-5">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className=''>
                                            <img src={Viewgraphichighlevelvisitor} alt="View graphic high-level visitor data at group or application level to show visitor traffic, clicks, and heat maps." className='' />
                                        </div>  

                                    </div>
                                    <p className="py-3">View graphic high-level visitor data at group or application level to show visitor traffic, clicks, and heat maps.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_cibil px-4 pt-5" >
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className=''>
                                            <img src={Controlheatmapsinthemaster} alt="Control heat maps in the master details layout" className='' />
                                        </div>   

                                    </div>
                                    <p className="py-3">Control heat maps in the master details layout of each app so you can get an accurate picture of user behavior.
                                    </p>
                                </div>
                            </div>

                        </Col>


                        {/*------------ first 3 coloms ------------------*/}
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 py-lg-5 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_cibil px-4 pt-5" >
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className=''>
                                            <img src={Viewaflowdiagramofuserentry} alt="user flow, and exit for each app to understand" className='' />
                                        </div>  

                                    </div>
                                    <p className="py-3">View a flow diagram of user entry, user flow, and exit for each app to understand why users make decisions along the way.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3  py-lg-5 my-lg-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_cibil px-4 pt-5" >
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className=''>
                                            <img src={Mapuserbehaviorstounderstand} alt="people give up on the process and pick up the phone" className='' />
                                        </div>   

                                    </div>
                                    <p className="py-3">Map user behaviors to understand where your people give up on the process and pick up the phone.</p>
                                </div>
                            </div>
                        </Col>


                    </Row>


                </Container>
            </div></>
    )
}

export default serviceCardsCible