

import React, { useEffect } from 'react'
import EditorImage from '../../assets/PrivacyPolicy.png'
import './Disclaimer.css'
import Row from 'react-bootstrap/Row';
import { Seo } from '../SEO/seo';
import Container from 'react-bootstrap/esm/Container';
import ClientCards from'../Cornerstone_Analytics/Cornerstone_Analytics_clint_cards'
const DisClaimer = () => {

    useEffect(() => {
        document.title = 'Chasma | Disclaimer';
    }, []);

    return (
        <>
             <Seo
                title= "Disclaimer | chasma place | Website Disclaimer"
                description= "Chasma place is an information technology solutions and consulting company and aims at developing next generation products and business consulting services across the globe."
                type= "website"
                name= "Chasma Disclaimer"
                url= 'https://chasma.io/disclaimer'
                image='https://chasma.io/static/media/PrivacyPolicy.a0953fc2.png'
            />
            <Container className='bannerPrivacyPolicy'>
                <div className='w-50  d-flex  align-items-center '>
                    <h1>Disclaimer</h1>
                </div>
                <div className='w-50'><img src={EditorImage} className='img-fluid' alt="chasma-privacy-policy" /></div>
            </Container>
            <div className='mt-4 pt-2 px-5 container-xl '>
                <h4 className='text-center fw-bold mt-4 mb-4 '>Disclaimer</h4>
                <Row>
                    <p>
                    The information contained on https://chasma.io website (the "Service") is for general information purposes only.
                   </p>
                   <p>
                    ChasmaIO assumes no responsibility for errors or omissions in the contents on the Service.

                   </p>
                   <p>
                   In no event shall ChasmaIO be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. ChasmaIO reserves the right to make additions, deletions, or modification to the contents on the Service at any time without prior notice.
                   </p>
                   <p>
                   ChasmaIO does not warrant that the website is free of viruses or other harmful components.
                   </p>
                </Row>
                <Row>
                    <h4>Trademarks</h4>
                    <p>
                    ChasmaIO is an independent technology solution provider. All trademarks, representations and/or content found on Chasma's website are owned by the designated intellectual property holders and governed by all applicable state and federal laws.
                    </p>
                    <p>
                    Unless stated otherwise, Chasma has no joint venture, partnership, association, sponsorship, endorsement or other affiliation with the designated intellectual property holders except as a solutions provider supporting customers in the respective domain.
                    </p>

                </Row>
                <Row>
                    <h4>
                    External links

                    </h4>
                    <p>
                    https://chasma.io website may contain links to external websites that are not provided or maintained by or in any way affiliated with ChasmaIO
                    </p>
                    <p>Please note that the ChasmaIO does not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.</p>
                </Row>
                
                <Row>
                    <ClientCards/>
                </Row>
            </div>
        </>
    )
}

export default DisClaimer