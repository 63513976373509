

import React, { useEffect } from 'react'
import '../../styles/Banner.css'
import './Cornerstone_Analytics.css';
import ServiceCards from './Cornerstone_Analytics_service_cards';
import Getredycard from './chasma_getredypink_card';
import Banner from '../chasma_analytics/Banner';
import { Seo } from '../SEO/seo';

const Cornerstone_Analytics = () => {

    useEffect(() => {
        document.title = 'Cornerstone Analytics';
    }, []);

    return (
        <>
            <Seo
                title= "Chasma Reporting & Analytics for Cornerstone ondemand - Talent Management Analytics"
                description= "Chasma Analytics provides dashboards and visualizations for hundreds of use cases for your KPIs and metrics, ready to deploy directly in Cornerstone. "
                type= "website"
                name= "Chasma Cornerstone Analytics"
                url= 'https://chasma.io/chasmaanalytics/cornerstone-analytics '
                image='https://chasma.io/static/media/cornerstoneAnalytics.e74f0ee9.png'
            />
             <Banner heading='Chasma Analytics For Cornerstone®' imgURL='cornerstoneAnalytics' caption='Preconfigured dashboards ready to deploy now'/>
           
            <ServiceCards />
            <Getredycard />
        </>
    )
}

export default Cornerstone_Analytics