import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/esm/Container';
import './Workday_Analytics.css';
import Iframe from 'react-iframe'
import ClintgridcardsWorkdayAnalytics from './Workday_Analytics_clint_cards'
import Carousel from '../chasma_analytics/Analytics_Carousel'

const GetredycardWorkdayAnalytics = () => {
    return (
        <>
            <Container>

                <Row className=' justify-content-center align-items-center mt-lg-4 mt-md-3 mt-3'>

                <Col lg={6} md={12} sm={12} xs={12} className="px-xl-4 px-lg-5 px-3 px-md-4 px-sm-3 ">
                        <div className='text-center text-lg-start '>

                            <h1 className="fw-bold textFont">Why Chasma Analytics For Workday®?</h1>
                        </div>

                        <p className="mb-0">Pre-configured dashboards ready to deploy right now in your Workday® platform, at a fraction of custom development cost. Watch the video to see how you can help your executives make the decisions at the speed of business with Chasma Analytics for Workday®.

                        </p>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12} className="video-container my-lg-0 my-2"  >
                        <div className="video-container">
                            <Iframe height="100%" width="100%" src="https://player.vimeo.com/video/228929858?h=970737f2b6&title=0&byline=0&portrait=0" frameborder="0" allowfullscreen />
                        </div>

                    </Col>


                   
                </Row>
                <ClintgridcardsWorkdayAnalytics />
                <Row className='justify-content-center align-items-center'>
                    <div className='mt-lg-5 mt-md-3 mt-2'></div>


                    <Col lg={6} md={12} sm={12} xs={12} className="px-xl-4 px-lg-5 px-3 px-md-4 px-sm-3 ">
                        <div className='text-center text-lg-start '>

                            <h1 className="fw-bold textFont">Explore Chasma Analytics</h1>
                        </div>

                        <p className="mb-0 py-3">We are on a mission to address HR technology needs in shorter and faster cycles to maximize your potential, so you can reduce your administrative overhead and focus on strategy.

                        </p>
                        <p>Your technology should not call the tune for you to dance; it should dance to the music in your critical processes.</p>

                    </Col>

                    <Col lg={6} md={12} sm={12} xs={12} className='overflow-hidden ' >
                        <div className='text-center text-lg-start d-flex justify-content-center align-items-center p-5'>
                            <Carousel />
                        </div>
                    </Col>


                </Row>

            </Container>

        </>
    )
}

export default GetredycardWorkdayAnalytics