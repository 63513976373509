import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import './chasma_Botbuilder.css';



// icon images------------

import EmbedComponents from '../../assets/Embed_components.png'
import DragandDropInterface from '../../assets/Drag-and-Drop Interface.png'
import PlugandPlayIntegrations from '../../assets/Plug and Play Integrations.png'
import CollaborationTools from '../../assets/Collaboration Tools.png'
import NoComplexProgramming from '../../assets/No Complex Programming.png'
import Gamification from '../../assets/Gamification.png'


function serviceCardsBotbuilder() {
    return (
        <>
            <Container>
           
            </Container>
            <div className='backgroundColor'>
                <Container className='pt-lg-2 stars-attach' >
               

                    <Row className='justify-content-center align-items-center'>
                        <Col md={8} lg={11} className='col-12 text-white'>

                            <div className='text-center pt-5 px-lg-5 '>

                                <h1 className='fw-bold'>Build Your Own Customer Service Chatbot</h1>

                            </div></Col>
                    </Row>
                    <Row className='justify-content-center align-items-center pt-5'>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_BotBuilder px-4 pt-5">
                                    <div className=" d-flex align-items-center justify-content-center">

                                        <div className=''>
                                            <img src={EmbedComponents} alt="Pre-configured
                                            Dashboard Designs-icon" className='img-fluid' />
                                        </div>
                                      
                                    </div>
                                    <p className="py-3">Create a bot that can naturally interact with users with AI cognitive services.</p>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_BotBuilder px-4 pt-5">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className=''>
                                            <img src={DragandDropInterface} alt="Pre-configured
                                            Dashboard Designs-icon" className='img-fluid' />
                                        </div>

                                    </div>
                                    <p className="py-3">Smooth transition to live chat with an agent. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_BotBuilder px-4 pt-5">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className=''>
                                            <img src={PlugandPlayIntegrations} alt="Pre-configured
                                            Dashboard Designs-icon" className='img-fluid' />
                                        </div>

                                    </div>
                                    <p className="py-3">Deploy your Bot to popular channels, wherever your customers interact.
                                    </p>
                                </div>
                            </div>

                        </Col>


                        {/*------------ first 3 coloms ------------------*/}
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 py-lg-5 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_BotBuilder px-4 pt-5">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className=''>
                                            <img src={CollaborationTools} alt="Pre-configured
                                            Dashboard Designs-icon" className='img-fluid' />
                                        </div>

                                    </div>
                                    <p className="py-3">Your assistant, your brand, with our tools and support.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3  py-lg-5 my-lg-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_BotBuilder px-4 pt-5">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className=''>
                                            <img src={NoComplexProgramming} alt="Pre-configured
                                            Dashboard Designs-icon" className='img-fluid' />
                                        </div>

                                    </div>
                                    <p className="py-3">No need for computer-speak. Use natural language in an open, extensible network.</p>
                                </div>
                            </div>
                        </Col>

                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12 py-lg-5'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_BotBuilder px-4 pt-5">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className=''>
                                            <img src={Gamification} alt="Pre-configured
                                            Dashboard Designs-icon" className='img-fluid' />
                                        </div>

                                    </div>
                                    <p className="py-3">Create a conversational experience while maintaining control of your data and customer flow.</p>
                                </div>
                            </div>

                        </Col>

                    </Row>


                </Container>
            </div></>
    )
}

export default serviceCardsBotbuilder