

import React, { useEffect } from 'react'

import './contact_us.css';
import ContactUsForm from './contact_us_form'


import Banner from '../chasma_analytics/Banner';
import SalesContactUsContainer from '../Demo_Sales/SalesContactUsContainer';
import Container from 'react-bootstrap/esm/Container';

const Contact_us = () => {
    useEffect(() => {
        document.title = 'Contact Us';
    }, []);
    return (
        <>

            <Banner heading='Contact Us' imgURL='Contact-us' caption="We'd love you to reach us and let us know how we can help you."/>

            <Container>
                <SalesContactUsContainer />
            </Container>
            <ContactUsForm />

        </>
    )
}

export default Contact_us





