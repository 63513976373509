import React from 'react'
import '../../styles/footer.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Chasma_white from '../../assets/CHASMA_WHITE.png'
import Phone from '../../assets/Phone.png'
import Mail from '../../assets/Email.png'
import Apps from '../../assets/Apps.png'
import Analytics from '../../assets/Analytics.png'
import Connect from '../../assets/Connect.png'
import Resources from '../../assets/Resources.png'
import About_us from '../../assets/Aboutus.png'
import Contactus from '../../assets/Contactus.png'
import Support from '../../assets/Support.png'
import facebook from '../../assets/FB.png'
import Linkedin from '../../assets/Linked.png'
import Twitter from '../../assets/Twitter.png'
import Youtube from '../../assets/Youtube.png'
import { Link } from "react-router-dom";
import { GoArrowUp } from "react-icons/go";
import HubspotForm from 'react-hubspot-form'

let facebookLink = 'https://www.facebook.com/ChasmaPlace/';
let youtubeLink = 'https://www.youtube.com/channel/UC3doJVlt0x_dYKa9Yi4fZGA'
let xLink = 'https://twitter.com/ChasmaPlace'
let linkedInLink = 'https://www.linkedin.com/company/chasmaplace/'


const handleLinkClick = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
};
const handleLinkClick2 = () => {
    window.scrollTo(0, -80)
}

const Footer = () => (

    <>
        <div className="bg-color mt-4 px-lg-4">
            <div className='px-sm-2 container '>
                <div className=" pt-5 px-lg-4 px-3">
                    <p><img src={Chasma_white} height="35" alt="chasma" /></p>
                    <div className="row justify-content-center text-white " id='myfooter'>

                        <div className="col-md-6 col-lg-4 col-sm-6 col-6">
                            <span> Chasma Inc</span>
                            <p className=''>1333, Corporate Dr, Suite 212,<br /> Irving, TX-75038, USA.</p>

                            <div className='row px-1 px-md-0 col-12 mailPhone '>
                                <div className='mx-md-2 col-md-12 col-lg-8 px-0 '><span>
                                    <a href="mailto:info@chasma.io" className='text-white'><img src={Mail} width='35px' alt="Apps" height="20" className="px-3 px-md-2 img-fluid" id='contact1' /><span>info@chasma.io</span> </a></span>
                                </div>
                                <div className='mx-md-3 col-md-12 col-lg-8 my-3  my-lg-3 px-0'><span>
                                    <a href="tel:+1 (855) 978-6816" className='text-white'><img src={Phone} width='22px' alt="Apps" height="20" className="px-2 px-md-0 img-fluid" id='contact' /><span> +1(855) 978-6816</span></a></span>
                                </div>
                            </div>


                        </div>

                        <div className="col-md-6  col-lg-3 col-sm-6 col-6">
                            <p className='linksP'> <Link onClick={handleLinkClick} to={'./chasmaapps'} className='editor text-white'> <span> <img src={Apps} alt="Apps" height="25" className="px-2" /></span><span className='links'> Apps</span></Link> </p>
                            <p className='linksP'> <Link onClick={handleLinkClick} to={'./chasmaanalytics'} className='editor text-white'> <span> <img src={Analytics} alt="Analytics" height="25" className="px-2" /></span><span className='links'>Analytics</span></Link></p>
                            <p className='linksP'> <Link onClick={handleLinkClick} to={'./chasmaconnect'} className='editor text-white'> <span> <img src={Connect} alt="connect" height="25" className="px-2" /></span><span className='links'>Connect</span></Link></p>
                        </div>

                        <div className="col-md-6 col-lg-2 col-sm-6 col-12 myFinalChange">

                            <p className='linksP'><a href='http://resources.chasma.io/resourceshome' className='editor text-white'> <span> <img src={Resources} alt="Resources" height="25" className="px-2" /></span><span className='links contactUsLink'> Resources</span> </a></p>
                            <p className='linksP'><Link onClick={handleLinkClick} to={'./aboutus'} className='editor text-white'> <span> <img src={About_us} alt="About" height="25" className="px-2" /></span><span className='links'>About Us</span> </Link></p>
                            <p className='linksP'><Link onClick={handleLinkClick} to={'./contactus'} className='editor text-white'><span> <img src={Contactus} alt="Connect" height="25" className="px-2" /></span><span className='links contactUsLink'>Contact Us</span> </Link></p>
                            <p className='linksP'><Link onClick={handleLinkClick} to={'./support'} className='editor text-white'><span> <img src={Support} alt="Support" height="25" className="px-2" /></span><span className='links'>Support</span> </Link></p>

                        </div>

                        <div className="col-md-6 col-lg-3  col-sm-6 col-12">


                            <p className='fw-bold'>Follow us</p>
                            <p>
                                <a as={Link} rel="noopener noreferrer" target="_blank" href={facebookLink}> <img src={facebook} className="px-1" height="25" alt="facebook" /></a>
                                <a as={Link} rel="noopener noreferrer" target="_blank" href={linkedInLink}><img src={Linkedin} className="px-1" height="25" alt="linkedIn" /></a>
                                <a as={Link} rel="noopener noreferrer" target="_blank" href={xLink}><img src={Twitter} className="px-1" height="25" alt="twitter" /></a>
                                <a as={Link} rel="noopener noreferrer" target="_blank" href={youtubeLink}> <img src={Youtube} className="px-1" height="25" alt="Youtube" /></a>
                            </p>
                            <p className="py-1 mb-0">Get Latest Updates and News</p>

                            <div className="mb-4 d-flex w-100 myFirstDiv">
                                <HubspotForm className="w-100 "
                                    portalId='1751195'
                                    formId='0c375adb-890e-4211-9d00-c1ef59e428a1'
                                    css='#label-email-0c375adb-890e-4211-9d00-c1ef59e428a1{
                                        color:white !important;

                                    }
                                   #hsForm_0c375adb-890e-4211-9d00-c1ef59e428a1{
                                    display:flex;
                                    flex-direction: row;
                                    align-items:center;
                                               

                                   }
                                   .hs-form-private.hsForm_0c375adb-890e-4211-9d00-c1ef59e428a1 .hs-button.primary.large{
                                    border-radius: 0px 5px 5px 0px !important;
                                    margin-top:30%
                                }
                                #hsForm_0c375adb-890e-4211-9d00-c1ef59e428a1 ul{
                                    width:fit-content !important;
                                }
                                @media screen and (min-width:1389px){
                                    #hsForm_0c375adb-890e-4211-9d00-c1ef59e428a1 .hs-main-font-element{
                                       
                                        width: 350px !important;
                                    }
                                }
                                @media screen and (min-width:992px) and (max-width:1098px) {
                                    #hsForm_0c375adb-890e-4211-9d00-c1ef59e428a1 .hs-main-font-element{
                                       
                                        width: 253px !important;
                                    }
                                }
                                @media screen and (min-width:576px) and (max-width:625px) {
                                    #hsForm_0c375adb-890e-4211-9d00-c1ef59e428a1 .hs-main-font-element{
                                       
                                        font-size:10px !important;
                                        width: 255px !important;
                                        height: 38px;
                                    }
                                }
                               
                                #label-email-0c375adb-890e-4211-9d00-c1ef59e428a1{
                                    white-space: nowrap;
                                }
                                @media screen and (min-width: 993px) {
                                    #hsForm_0c375adb-890e-4211-9d00-c1ef59e428a1 .hs-main-font-element{
                                        font-size:12px;
                                    }
                                }
                                @media screen and (max-width: 992px) {
                                  
                                    #hsForm_0c375adb-890e-4211-9d00-c1ef59e428a1 ul {
                                            width:fit-content !important;
                                            padding: 0px;
                                        }
                                        #hsForm_0c375adb-890e-4211-9d00-c1ef59e428a1 .hs-main-font-element{
                                            font-size:12px;
                                        }
                                    }
                                   .myFormDemo{
                                    color:white !important;
                                   
                                }
                                .hs-main-font-element, .hs-error-msgs>li{
                                  background-color: inherit !important;
                                  color:#FFD609 !important;
                                  white-space:nowrap !important;
                                  
                                }
                                #hsForm_0c375adb-890e-4211-9d00-c1ef59e428a1 .hs-main-font-element{
                                    position: absolute;
                                    // display:block !important;
                                    left: 0px;
                                    white-space: wrap !important;
                                    bottom: -39px;
                                    width: 300px;
                                    height:34px;
                                }
                              #hsForm_0c375adb-890e-4211-9d00-c1ef59e428a1 .hs_error_rollup>.hs-error-msgs {
                                display:none !important
                              }
                                .hs-form-field{
                                  padding:2% 0%
                                }
                                #email-0c375adb-890e-4211-9d00-c1ef59e428a1{
                                    border-radius:5px 0px 0px 5px;
                                }
                                .hs-input{
                                  border-radius: 5px;
                                  border-width: 0;
                                  padding: 4px 10px !important;
                                  border-color:none;
                                }
                                #hsForm_0c375adb-890e-4211-9d00-c1ef59e428a1 .hs-button{
                                  padding: 4px 10px;
                                  margin-bottom:-1px;
                                  // border-radius: 26px;
                                    color:white;
                                  border-color: inherit;
                                  background-color:#ffc107;
                                  outline-offset: none;
                                  border-width: 0px;
                                  --bs-btn-color: #000;
                                  --bs-btn-bg: #ffc107;
                                  --bs-btn-border-color: #ffc107;
                                  --bs-btn-hover-color: #000;
                                  --bs-btn-hover-bg: #ffca2c;
                                  --bs-btn-hover-border-color: #ffc720;
                                  --bs-btn-focus-shadow-rgb: 217, 164, 6;
                                  --bs-btn-active-color: #000;
                                  --bs-btn-active-bg: #ffcd39;
                                  --bs-btn-active-border-color: #ffc720;
                                  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
                                  --bs-btn-disabled-color: #000;
                                  --bs-btn-disabled-bg: #ffc107;
                                  --bs-btn-disabled-border-color: #ffc107;
                        
                                }
                                .actions{
                                  text-align:center
                                }
                                .hs_error_rollup{
                                  text-align:center
                                }
                                
                                #phone_ext-dcc70b97-5828-46eb-90d0-f250f2b7bd9d_1{
                                  margin-left:-10px
                                }
                                #phone-dcc70b97-5828-46eb-90d0-f250f2b7bd9d_1{
                                  width:70% !important;
                                  margin-right:-10px;
                                }
                                .no-list.hs-error-msgs.inputs-list{
                                    position:absolute;
                                    white-space:no-wrap;
                                }.hs_email.hs-email.hs-fieldtype-text.field.hs-form-field.smart-field{
                                    position:relative;
                                    width:100%;
                                }
                                @media screen and (max-width: 575px) {
                                    #hsForm_0c375adb-890e-4211-9d00-c1ef59e428a1 #email-0c375adb-890e-4211-9d00-c1ef59e428a1{
                                        min-height:30px
                                    }
                                   .hs-form-private.hsForm_0c375adb-890e-4211-9d00-c1ef59e428a1 .hs-button.primary.large{
                                        margin-top:28%;
                                    }
                                    #hsForm_0c375adb-890e-4211-9d00-c1ef59e428a1 .hs-button{
                                        padding: 6px 10px;
                                    }

                                }
                                @media screen and (max-width: 425px) {
                                    #hsForm_0c375adb-890e-4211-9d00-c1ef59e428a1 #email-0c375adb-890e-4211-9d00-c1ef59e428a1{
                                        min-height:31px
                                    }
                                   .hs-form-private.hsForm_0c375adb-890e-4211-9d00-c1ef59e428a1 .hs-button.primary.large{
                                        margin-top:25%;
                                    }
                                    #hsForm_0c375adb-890e-4211-9d00-c1ef59e428a1 .hs-button{
                                        padding: 8px 10px;
                                    }

                                }
                               
                                    '
                                />
                            </div>

                        </div>
                        <div className='d-flex justify-content-between align-items-center  '>
                            <span style={{ width: '47%', height: '2px' }} className='bg-white '></span>
                            <span onClick={handleLinkClick2} className=' mt-sm-0 mt-3 d-flex justify-content-center align-items-center scrollIcon'><GoArrowUp /></span>
                            <span style={{ width: '47%', height: '2px' }} className='bg-white '></span>
                        </div>

                        <Row className='py-3 flex-wrap-reverse col-11 mx-auto justify-content-center ' style={{ fontSize: '13px' }}>
                            <Col md={3} className="p-0 col-12 rights "><div>
                                2024@chasma All Rights Reserved</div></Col>
                            <Col md={9} className="p-0 d-flex justify-content-end">
                                <div className='d-flex flex-wrap'>
                                    <Link className='linksBottom' onClick={handleLinkClick} to={'/privacypolicy'}><span className='px-md-3 px-lg-2 px-2 editor text-white'>Privacy Policy</span></Link> |
                                    <Link className='linksBottom' onClick={handleLinkClick} to={'/refundpolicy'}> <span className='px-md-3 px-lg-2 px-2 editor text-white'>Refund Policy  </span></Link> |
                                    <Link className='linksBottom' onClick={handleLinkClick} to={'/termsConditions'}><span className='px-md-3 px-lg-2 px-2 editor text-white'>Terms and Conditions</span></Link> |
                                    <Link className='linksBottom' onClick={handleLinkClick} to={'/cookiepolicy'}><span className='px-md-3 px-lg-2 px-2 editor text-white'> Cookie Policy </span></Link>  |
                                    <Link className='linksBottom' onClick={handleLinkClick} to={'/disclaimer'}><span className='px-md-3 px-lg-2 px-2 editor text-white'>Disclaimer</span></Link> |
                                    <Link className='linksBottom' onClick={handleLinkClick} to={'/eula'}><span className='px-md-3 px-lg-2 px-2 editor text-white'> EULA</span></Link>  |
                                    <Link className='linksBottom' onClick={handleLinkClick} to={'/sitemap'}><span className='px-md-3 px-lg-2 px-2 editor text-white'>Site Map</span></Link>
                                </div>
                            </Col>

                        </Row>

                    </div>
                </div>
            </div>
        </div>

    </>
)

export default Footer

