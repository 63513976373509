import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/esm/Button';
import { FiArrowUpRight } from "react-icons/fi";
import './chasma_apps.css'
import Carousel from '../chasma_editor/owl_carousel'
import Iframe from 'react-iframe'
import ClintgridcardsApps from './chasma_apps_clint_cards'
const Getredycard = () => {
    return (
        <>
            <div className='px-5 container'>

                <Row className=' justify-content-center align-items-center mt-lg-4 mt-md-3 mt-3'>


                    <Col lg={6} md={12} sm={12} xs={12} className="px-xl-4 px-lg-5 px-3 px-md-5 px-sm-3 ">
                        <div className='text-center text-lg-start '>
                            <h1 className="fw-bold">Why Chasma Apps?</h1>
                        </div>
                        <p className="mb-0">Improve the user experience without expensive upgrades and customization.
                         Give your users instant access to the information and knowledge they need to stay on task.
                          Watch the video to know how 
                        Chasma Editor helps you create a user centric experience across your enterprise technology applications.
                        </p>

                        <Button className='btn btn-warning rounded-pill text-white my-3'>
                            Sign Up <FiArrowUpRight />
                        </Button>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12}  className="video-container my-lg-0 my-2"  >
                        <div  className="video-container">
                            <Iframe height="100%" width="100%"   src="https://player.vimeo.com/video/284219640?h=98992743cd" frameborder="0" allowfullscreen/>
                        </div>    
                    </Col> 
                    
                </Row>
                <ClintgridcardsApps/>
                <Row className='justify-content-center align-items-center'>
                    <div className='mt-lg-5 mt-md-3 mt-2'></div>


                    <Col lg={6} md={12} sm={12} xs={12} className="px-xl-4 px-lg-5 px-3 px-md-5 px-sm-3 ">
                        <div className='text-center text-lg-start '>

                            <h1 className="fw-bold">Explore Chasma Apps</h1>
                        </div>

                        <p className="mb-0 py-3">We are on a mission to address HR technology needs in shorter and faster cycles to maximize your potential, so you can reduce your administrative overhead and focus on strategy.

                        </p>
                        <p>Your technology should not call the tune for you to dance; it should dance to the music in your critical processes.</p>
                        
                    </Col>

                    <Col lg={6} md={12} sm={12} xs={12} className='overflow-hidden ' >
                        <div className='text-center text-lg-start d-flex justify-content-center align-items-center p-5'>
                            <Carousel/>
                        </div>
                    </Col>
                </Row>

            </div>
            
        </>
    )
}

export default Getredycard