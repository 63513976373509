import React, { } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/chasma_home_bannerpage_home01.css'
import Aarp from "../../assets/daiichi-sankyo-logo.png";
import Aabbot from '../../assets/abbott.png'
import Cabi from '../../assets/cabi.png';
import Kubota from '../../assets/kubota.png';
import Volkswagen from '../../assets/Volkswagen-Group-Logo-Transparent-Background (2).png';
import Button from 'react-bootstrap/esm/Button';
import { FiArrowUpRight } from "react-icons/fi";
import Iframe from 'react-iframe'
import plusImagesRL from '../../assets/plusImagesRL.png'
import { Container } from 'react-bootstrap';

const Section = () => {
  

    return (



        <>



            <Container className='p-2 container   pt-3 pt-lg-4' style={{ marginTop: '3%'}}>
              
                <Row className='d-flex justify-content-center align-items-center mt-4 mt-lg-4 px-5' >
                  
                    <Col md={6} lg={2} className='d-flex justify-content-center align-items-center px-3 col-6'><a href="https://www.daiichisankyo.com/" target="_blank" rel="noopener noreferrer"><img src={Aarp} alt="Daiichi" className='px-3' style={{ width: '240px' }} /></a></Col>
                    <Col lg={2} md={3} className='d-flex justify-content-center align-items-center px-3 col-6'> <a href="https://www.abbott.com/" target="_blank" rel="noopener noreferrer"><img src={Aabbot} id="images" alt="Aabbot" className='p-3 img-fluid' /></a></Col>
                    <Col lg={2} md={3} className='d-flex justify-content-center align-items-center px-3 col-6'> <a href="https://www.cabionline.com/" target="_blank" rel="noopener noreferrer"> <img src={Cabi} id="images" alt="cabi" className='p-3 img-fluid' /></a></Col>
                    <Col lg={2} md={3} className='d-flex justify-content-center align-items-center px-3 col-6'> <a href="https://www.kubota.com/" target="_blank" rel="noopener noreferrer"><img src={Kubota} id="images" alt="kubota" className='p-3 img-fluid' /> </a> </Col>
                    <Col lg={2} md={3} className='d-flex justify-content-center align-items-center px-3 col-6'> <a href="https://www.volkswagen-group.com/en" target="_blank" rel="noopener noreferrer"> <img src={Volkswagen} id="images" alt="Volkswagen" className='p-3 img-fluid' /></a> </Col>
                </Row>


                <Row className=' py-lg-5 px-lg-5 px-3 '>

                    <Col lg={6} md={12} sm={12} xs={12} className="px-xl-4 px-lg-5 px-3 px-md-5 px-sm-3 py-lg-0 py-3">
                        <div className='text-center text-lg-start '>
                            <p className='py-3 text-primary fs-5 fw-bold'>Chasma Place</p>
                            <h2 className="fw-bold">Your source for HR apps, analytics and integrators</h2>
                        </div>

                        <p className="">People are the driving force behind the success of the enterprise. An efficient, user-centered experience for your employees is a crucial competitive differentiator.</p>

                        <p className=''>Chasma Marketplace apps are designed as simple, plug and play, easily configurable solutions that can be deployed into your enterprise in short cycles. We do this by creating solutions any enterprise can use, designed to work with your current application or technology.
                        </p>
                        <p>We design them so you can configure them to the unique needs of your people, without a user manual or the need for complex programming. </p>

                        <a href="https://app.chasma.io/register" target='blank' className='text-white'>  <Button className='btn btn-warning rounded-pill text-white'>
                            Sign Up <FiArrowUpRight />
                        </Button></a>
                    </Col>


                    <Col lg={6} md={12} sm={12} xs={12} className="video-container  py-lg-0 py-3 my-lg-0 my-2 "  >
                        <div className="video-container">
                            <Iframe height="100%" width="100%" src="https://player.vimeo.com/video/228929858?title=0&byline=0&portrait=0" frameborder="0" allowfullscreen />
                            <div className='plusImageContainer'>
                                <img src={plusImagesRL} alt="" srcSet="" />
                            </div>
                        </div>

                    </Col>
                </Row>

            </Container>
        </>

    )
}
export default Section;