import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import './Cornerstone_Analytics.css';

// icon images------------

import RealTimeVisualizations from '../../assets/Cornerstone_Analytics-icons/Real-time_Visualization.png'
import DataVisualizationsThat from '../../assets/Cornerstone_Analytics-icons/Data_visualizations_that.png'
import ThreePricingLevels from '../../assets/Cornerstone_Analytics-icons/Three_pricing_levels.png'
import KPIsAndMetricsThatMatter from '../../assets/Cornerstone_Analytics-icons/KPIs_and_metrics.png'
import ExpertsWhoCanLeadYouThrough from '../../assets/Cornerstone_Analytics-icons/Experts_who_can_lead.png'


function serviceCards() {
    return (
        <>
            <div className='px-4 container'>
                  <Row className='justify-content-center align-items-center'>
                        <Col md={10} lg={10} className='col-12 '>
                        
                            <div className='text-center py-lg-3 '>

                                <h1 className='fw-bold textFont'>Get new insights right now into your most important metrics and KPIs</h1>

                                <p className='py-3'>For many of our Chasma customers,building a comprehensive Cornerstone reporting library always seems to the last on the list of priorities.Some tell us that they don't have the time or the skills to get it done.Others say they can't decide what to measure.
                                </p>
                                <p className=''>
                                At Chasma, we are on a mission to address HR technology needs in shorter and faster cycles to maximize your potential,so you can get it done now,without waiting for priorities to align themselves.
                                </p>
                                <p className=''>
                                Your technology should not call the tune for you to dance;it should dance to the music in your critical processes.
                                </p>
                                <p className=''>
                                That's why we maintain a library of 130+ dashboards and hundreds of data visualizations, ready for you to deploy in Cornerstone.If you don't see what you need ,let us know, and we'll create it for you.
                                </p>
                            </div></Col>
                    </Row>
            </div>
            <Container>
              
            </Container>
            <div className='backgroundColor'>
                <Container className='pt-lg-2 stars-attach' >
              

                    <Row className='justify-content-center align-items-center'>
                        <Col md={12} lg={11} className='col-12 text-white'>

                            <div className='text-center pt-5 px-lg-5 '>

                                <h1 className='fw-bold'>Cornerstone Analytics Advantage.</h1>
                                <p className='py-md-4 px-md-5 px-2 '>Enjoy the benefits of rapid deployment and low-cost,pre-configured visualizations and dashboards built for Cornerstone.</p>

                            </div></Col>
                    </Row>
                    <Row className='justify-content-center align-items-center pt-5'>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_editor px-md-4 p-3  pt-5">
                                    <div className="  align-items-center text-center">

                                        <div className=''>
                                            <img src={RealTimeVisualizations} alt="Real-time visualizations" className='' />
                                        </div>  
                                    </div>
                                    <p className="py-3 text-center">Real-time visualizations of critical Operational metrics like employee cost versus performance, analysis on your data,and more.</p>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_editor px-md-4 p-3 pt-5">
                                    <div className="align-items-center text-center">
                                        <div className=''>
                                            <img src={DataVisualizationsThat} alt="Data visualizations that" className='img-fluid' />
                                        </div>   
                                    
                                    </div>
                                    <p className="py-3 text-center">Data visualizations that help your executives make decisions at the speed of business.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_editor px-md-4 p-3 pt-5">
                                    <div className="align-items-center text-center">
                                        <div className=''>
                                            <img src={ThreePricingLevels} alt="Three pricing levels" className='img-fluid' />
                                        </div>   
                                        
                                    </div>
                                    <p className="py-3 text-center">Three pricing levels to fit your budget and business needs.
                                    </p>
                                </div>
                            </div>

                        </Col>


                        {/*------------ first 3 coloms ------------------*/}
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 py-lg-5 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_editor px-md-4 p-3 pt-5" >
                                    <div className="align-items-center text-center">
                                        <div className=''>
                                            <img src={KPIsAndMetricsThatMatter} alt="KPIs and metrics that matter" className='img-fluid' />
                                        </div>  
                                        
                                    </div>
                                    <p className="py-3 text-center">KPIs and metrics that matter most for your talent oragization.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3  py-lg-5 my-lg-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_editor px-md-4 p-3 pt-5">
                                    <div className="align-items-center text-center">
                                        <div className=''>
                                            <img src={ExpertsWhoCanLeadYouThrough} alt="Experts who can lead you through" className='img-fluid' />
                                        </div>   
                                        
                                    </div> 
                                    <p className="py-3 text-center">Experts who can lead you through the framework for aligning your metrics to business strategy.</p>
                                </div>
                            </div>
                        </Col>

                        

                    </Row>


                </Container>
            </div></>
    )
}

export default serviceCards