

import React, { useEffect } from 'react'
import './chasma_analytics.css';
import AnimatedCardsAnalytics from './chasma_animate_analytics_cards'
import ServiceCardsAnalytics from './chasma _analytics_service_cards';
import GetredycardAnalytics from './chasma_getredypink_analytics_card';
import Banner from './Banner';
import { Seo } from '../SEO/seo';






const ChasmaAnalytics = () => {

    useEffect(() => {
        document.title = 'Chasma Analytics';
    }, []);

    return (
        <>
            <Seo
                title= "HR Analytics Dashboards and Data Models | SumTotal Reporting | Chasma Place "
                description= "Get real-time business intelligence, translate your raw data into insightful HR analytics visualizations without weeks or months of costly development with Chasma Analytics."
                type= "website"
                name= "Chasma Analytics"
                url= 'https://chasma.io/chasmaanalytics'
                image='https://chasma.io/static/media/Chasma-analytics.52ff51ff.png'
            />

            <Banner  heading='Chasma Analytics' imgURL='Chasma-analytics' caption='Data-Driven Visual Insight'/>
           
            <AnimatedCardsAnalytics />
            <ServiceCardsAnalytics />
            <GetredycardAnalytics />



        </>
    )
}

export default ChasmaAnalytics