import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom'
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/esm/Container';
import { BiChevronRight } from "react-icons/bi";
import Iframe from 'react-iframe'
import ClientCards from'../Cornerstone_Analytics/Cornerstone_Analytics_clint_cards'
function ProductswithaPersonalTouch() {
    return (
        <>
            <Container>
               
                <Row className='justify-content-center align-items-center  mt-lg-4 mt-md-3 mt-3'>
                    <Col md={10} lg={10} className='col-12'>
                        <div className='text-center pt-2  ' >
                            <h1 className='fw-bold text-primary  '>Create An Employee - Centered Experience Across Your
                                Enterprise - Without Customization.</h1>
                            <p className='py-lg-3 py-xl-3 py-md-3 mb-0'>Chasma gives you simple,
                                plug and play, easily configurable solutions that improve performance and productivity
                                through the people experience.
                                Right tools can turn your business dreams into reality.</p>

                            <Button variant="primary" as={Link} to={'/demo'} className="fnt my-3 mx-1">Sign up for a personal demo <BiChevronRight /></Button>
                            <Button variant="outline-primary" as={Link} to={'/sales'} className="fnt mx-1">Contact Sales <BiChevronRight /></Button>
                        </div>
                        <div className='text-center pt-5  '>

                            <h1 className='fw-bold personalTouch'>Products with a Personal Touch</h1>
                            <span className='underline'></span>
                            <p className='py-3'>Companies are made up of people, HR is strategic driver for enhancing people experiences and efficiencies. And when they have the right tools, they can turn their business dreams into reality.
                            </p>

                        </div></Col>
                </Row>


                <Row className=' justify-content-center align-items-center  mt-lg-4 mt-md-3 mt-3'>


                    <Col lg={6} md={12} sm={12} xs={12} className=" ">
                        <div className='text-center text-lg-start '>

                            <h4 className="fw-bold text-primary py-2">Why Chasma Editor?</h4>
                        </div>

                        <p className="mb-0">Improve the user experience without expensive upgrades and
                            customization. Give your users instant access to the information and knowledge
                            they need to stay on task. Watch the video to know how Chasma Editor helps you
                            create a user centric experience across your enterprise technology applications.
                        </p>
                    </Col>

                    <Col lg={6} md={12} sm={12} xs={12} className="video-container"  >
                        <div className="video-container">
                            <Iframe  height="100%" width="100%" src="https://player.vimeo.com/video/230429413?h=6bb50a936e&title=0&byline=0&portrait=0" frameborder="0" allowfullscreen />
                        </div>
                       
                    </Col>

                </Row>

                <Row className=' justify-content-center align-items-center flex-wrap-reverse  mt-lg-4 mt-md-3 mt-3'>
                    <Col lg={6} md={12} sm={12} xs={12} className="video-container my-lg-0 my-2"  >
                        <div className="video-container">
                            <Iframe height="100%" width="100%" src="https://player.vimeo.com/video/231573767?title=0&byline=0&portrait=0" frameborder="0" allowfullscreen />
                        </div>

                    </Col>


                    <Col lg={6} md={12} sm={12} xs={12} className=" ">
                        <div className='text-center text-lg-start '>

                            <h4 className="fw-bold text-primary py-2">Why Chasma Analytics?</h4>
                        </div>

                        <p className="mb-0">Discover profound insights into the state of your
                            operations and get started on your journey toward a data-driven organization.
                            Watch the video to see how you can achieve reporting excellence without a long
                            deployment cycle or the cost of a custom solution.

                        </p>

                    </Col>


                </Row>
                <Row className=' justify-content-center align-items-center my-lg-4 mt-md-3 mt-3'>


                    <Col lg={6} md={12} sm={12} xs={12} className=" ">
                        <div className='text-center text-lg-start '>

                            <h4 className="fw-bold text-primary py-2">Why Chasma Event?</h4>
                        </div>

                        <p className="mb-0">Administration and management of your enterprise events,
                            instructor-led training, and other meetings could be so frustrating. Watch
                            the video to see how Chasma Event eliminates all the hassles involved in employee event
                            management and help you organize all your events like a pro.
                        </p>
                    </Col>

                    <Col lg={6} md={12} sm={12} xs={12} className="video-container my-lg-0 my-2"  >
                        <div className="video-container">
                            <Iframe height="100%" width="100%" src="https://player.vimeo.com/video/284219640?h=98992743cd" frameborder="0" allowfullscreen />
                        </div>

                    </Col>

                </Row>

                <Row className=' justify-content-center align-items-center flex-wrap-reverse  mt-lg-4 mt-md-3 mt-3'>
                    <Col lg={6} md={12} sm={12} xs={12} className="video-container my-lg-0 my-2"  >
                        <div className="video-container">
                            <Iframe height="100%" width="100%" src="https://player.vimeo.com/video/417196028?title=0&byline=0&portrait=0" frameborder="0" allowfullscreen />
                        </div>

                    </Col>



                    <Col lg={6} md={12} sm={12} xs={12} className="">
                        <div className='text-center text-lg-start '>

                            <h4 className="fw-bold text-primary py-2">Why Chasma Connect?</h4>
                        </div>

                        <p className="mb-0">You don’t need to spend weeks or months developing custom integrations for your HR application platforms.
                            Watch the video to see how Chasma Connect offers you prefabricated plug and play connectors,
                            integrators, ready to deploy into your applications.



                        </p>

                    </Col>


                </Row>
                <Row>
                        <ClientCards />
                </Row>

            </Container>

        </>
    )
}

export default ProductswithaPersonalTouch