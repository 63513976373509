import React from "react";
import ManuFacturingRetail from '../../assets/CustomersPageImages/Kubota.png'
import Automobile from '../../assets/CustomersPageImages/Volkswegan.png'
import Ecommerce from '../../assets/CustomersPageImages/cabi.png'
import PharmaHealthcare from '../../assets/CustomersPageImages/Abbott.png'

import Kubota from '../../assets/editor-icons/Kubota.png';
import Vw from '../../assets/editor-icons/vw.png';
import Cabi from '../../assets/editor-icons/cabi.png';
import Abbot from '../../assets/editor-icons/Abbot.png';

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from "react-bootstrap";
import { BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const ChasmaCompany = () => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    return (
        <>
            <div className="text-center container " style={{marginBottom:'4%'}}>
                <h1 className="fw-bold ">You’re in good company.</h1>
                <h2 className="fw-bold " style={{ color: '#6366F1' }}>Join with other enterprises on Chasma Place.</h2>
            </div>

            <Box sx={{}} className='container'>
                <AppBar position="static">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab label="MANUFACTURING & RETAIL" {...a11yProps(0)} />
                        <Tab label="AUTOMOBILE" {...a11yProps(1)} />
                        <Tab label="ECOMMERCE" {...a11yProps(2)} />
                        <Tab label="PHARMA & HEALTH CARE" {...a11yProps(3)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <div className=" d-flex justify-content-between align-items-center  container ConetentContainer   ">
                            <div className=" position-relative imageContent1  ">
                                <div className="d-flex justify-content-center align-items-center flex-column mainContainer shadow-lg  ">
                                    <div className="parent-image-container">
                                        <img className="img-fluid" src={ManuFacturingRetail} alt="manufacturing and retail" />
                                        <div className="image-center"><img className="img-fluid" src={Kubota} alt="kubota" /></div>

                                    </div>
                                    <div className="my-5 text-center">
                                        <p className="px-2">With Chasma Editor's user centric training dashboards, Kubota's people have been enjoying a personalized user experience at learning.</p>
                                    </div>

                                </div>
                                <div className="rotatedContent"></div>
                            </div>
                            <div className=" text-content imageContent2 pl-2">
                                <h5>
                                    Chasma Editor gives everything you need to create an engaging, personalized user experience across your human capital applications.
                                </h5>
                                <p>Build motivating user experiences with our Editor's a collection of pre-designed templates and enhance your people experience in the digital world. Give instant access to information your people need when they need it, so they can sustain their momentum in work and learning.</p>
                                <p>Browse our library to view and choose your templates or start from scratch to build your own using our drag-and-drop editor.</p>
                                <a rel="noopener noreferrer" href="https://app.chasma.io/register" target="_blank"> <Button variant="primary" className="fnt my-3 mx-1">Start Now <BiChevronRight /></Button></a>
                             
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <div className=" d-flex justify-content-between align-items-center  container ConetentContainer   ">
                            <div className=" position-relative imageContent1  ">
                                <div className="d-flex justify-content-center align-items-center flex-column mainContainer shadow-lg  ">
                                    <div className="parent-image-container">
                                        <img className="img-fluid" src={Automobile} alt="manufacturing and retail" />
                                        <div className="image-center"><img className="img-fluid" src={Vw} alt="kubota" /></div>

                                    </div>
                                    <div className="my-5 text-center">
                                        <p className="px-2">With Chasma Analytics, Volkswagen is able to quickly turn its data into actionable insights that led to value-driven decision making across its L&D operations.</p>
                                    </div>

                                </div>
                                <div className="rotatedContent"></div>
                            </div>
                            <div className=" text-content imageContent2 pl-2">
                                <h5>Translate your people data into actionable insights that enable you to make decisions at the speed of business.</h5>
                                <p>Chasma Analytics gives you a collection of pre-configured dashboards and data models for HCM suites and Analytics platforms that provide real-time business intelligence.</p>
                                <p>Start your journey towards a data-driven organization, now. Get visualizations and profound insights without a long deployment cycle or the cost of a custom solution.</p>
                              
                                <Button variant="primary" className="btn-sm fnt my-3 mx-1" as={Link} to={'/chasmaanalytics'}>Explore Chasma Analytics <BiChevronRight /></Button>
                                <Button variant="outline-primary" as={Link} to={'/chasmaanalytics/sumtotal-analytics'} className="btn-sm fnt mx-1">Chasma Analytics for SumTotal <BiChevronRight /></Button>       
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={2} dir={theme.direction}>
                        <div className=" d-flex justify-content-between align-items-center  container ConetentContainer   ">
                            <div className=" position-relative imageContent1  ">
                                <div className="d-flex justify-content-center align-items-center flex-column mainContainer shadow-lg  ">
                                    <div className="parent-image-container">
                                        <img className="img-fluid" src={Ecommerce} alt="manufacturing and retail" />
                                        <div className="image-center"><img className="img-fluid" src={Cabi} alt="kubota" /></div>

                                    </div>
                                    <div className="my-5 text-center">
                                        <p className="px-2">Chasma Editor gave Cabi a user-friendly canvas to create an engaging, personalized user experience - one its people love.</p>
                                    </div>

                                </div>
                                <div className="rotatedContent"></div>
                            </div>
                            <div className=" text-content imageContent2 pl-2">
                                <h5>
                                Create an engaging experience wherever people interact with technology. Make your landing pages and user portals, engaging and employee centric.  </h5>
                                <p>Give your people instant access to the information, knowledge, and people they need to stay on task and help them stay motivated at work.</p>
                                <p>Configure and plug-in editor landing page templates into your HR and learning portals and launch them right away.</p>
                                <Button variant="primary" className="btn-sm fnt my-3 mx-1" as={Link}  to={'/chasmaapps/chasmaeditor'}>Explore Editor's Collection<BiChevronRight /></Button>
                                <Button variant="outline-primary" as={Link} to={'/suntotalanalytics'} className="btn-sm fnt mx-1">Editor for SumTotal <BiChevronRight /></Button>       
                          
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={3} dir={theme.direction}>
                        <div className=" d-flex justify-content-between align-items-center  container ConetentContainer   ">
                            <div className=" position-relative imageContent1  ">
                                <div className="d-flex justify-content-center align-items-center flex-column mainContainer shadow-lg  ">
                                    <div className="parent-image-container">
                                        <img className="img-fluid" src={PharmaHealthcare} alt="manufacturing and retail" />
                                        <div className="image-center"><img className="img-fluid" src={Abbot} alt="kubota" /></div>

                                    </div>
                                    <div className="my-5 text-center">
                                        <p className="px-2">Chasma Analytics gave Abott an easy path of transition from raw data to insights – one which streamlined critical decision making.</p>
                                    </div>

                                </div>
                                <div className="rotatedContent"></div>
                            </div>
                            <div className=" text-content imageContent2 pl-2">
                                <h5>
                                Give your people deep insights into the power of learning in your business.</h5>
                                <p>With Chasma analytical services you can go beyond what and why — to what will happen.</p>
                                <p>Chasma Analytics brings you a collection of insights and visualizations that you can deploy into your human capital applications immediately, without lengthy development projects or costly BI platforms.</p>
                                <p>Gain Operation reporting excellence in days, improve your business intelligence, and control your budgets.</p>
                                <Button variant="primary" className="btn-sm fnt my-3 mx-1" as={Link} to={'/chasmaanalytics'}>Explore Chasma Analytics<BiChevronRight /></Button>
                                <Button variant="outline-primary" as={Link} to={'/chasmaanalytics/people-analytics-power-bi'} className="btn-sm fnt mx-1">Chasma Analytics for Power BI<BiChevronRight /></Button>       
                          
                            </div>
                        </div>
                    </TabPanel>
                </SwipeableViews>
            </Box>
        

        </>
    )
}
export default ChasmaCompany