import React from 'react'


// client logos
import Daiichi from "../../assets/editor-icons/daiichi.png";
import Aabbot from '../../assets/editor-icons/Abbot.png'
import Cabi from '../../assets/editor-icons/cabi.png';
import Kubota from '../../assets/editor-icons/Kubota.png';
import Volkswagen from '../../assets/editor-icons/vw.png';
import Linde from '../../assets/editor-icons/Linde.png'
import Moraine from '../../assets/editor-icons/moraine-(1).png'
import Hcsc from '../../assets/editor-icons/hcsc.png'

// ----------------
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// css
import '../chasma_editor/chasma_editor.css';
import './customers.css'


function chasma_customer_clint_cards() {
    return (
        <>
            <div className="customersContainer">
                 <div className=' container'>

                <Row className='d-flex justify-content-center align-items-center mt-md-5 mt-lg-4'>
                    <Col md={3} sm={6} className='d-flex justify-content-center align-items-center px-3 my-sm-3 my-3 col-6'> <a href="https://www.daiichisankyo.com/" target="_blank" rel="noopener noreferrer"> <img src={Daiichi} id="images" alt="Daiichi" className=' img-fluid roundedImages' /></a></Col>
                    <Col md={3} sm={6} className='d-flex justify-content-center align-items-center px-3 my-sm-3  my-3 col-6'> <a href="https://www.abbott.com/" target="_blank" rel="noopener noreferrer"> <img src={Aabbot} id="images" alt="Aabbot" className='img-fluid  roundedImages' /></a></Col>
                    <Col md={3} sm={6} className='d-flex justify-content-center align-items-center px-3 my-sm-3 my-3 col-6'> <a href="https://www.hcsc.com/" target="_blank" rel="noopener noreferrer"> <img src={Hcsc} id="images" alt="Hcsc" className=' img-fluid roundedImages' /></a></Col>
                    <Col md={3} sm={6} className='d-flex justify-content-center align-items-center px-3 my-sm-3 my-3 col-6'> <a href="https://www.cabionline.com/" target="_blank" rel="noopener noreferrer"><img src={Cabi} id="images" alt="cabi" className='img-fluid roundedImages' /></a></Col>
                </Row>
                <Row className='d-flex justify-content-center align-items-center mt-md-5 mt-lg-4'>

                    <Col md={3} sm={6} className='d-flex justify-content-center align-items-center px-3 my-sm-3 my-3 col-6'> <a href="https://www.morainepark.edu/" target="_blank" rel="noopener noreferrer"> <img src={Moraine} id="images" alt="Moraine" className=' img-fluid roundedImages' /></a></Col>
                    <Col md={3} sm={6} className='d-flex justify-content-center align-items-center px-3 my-sm-3 my-3 col-6'> <a href="https://www.volkswagen-group.com/en" target="_blank" rel="noopener noreferrer"> <img src={Volkswagen} id="images" alt="Volkswagen" className=' img-fluid roundedImages' /></a></Col>
                    <Col md={3} sm={6} className='d-flex justify-content-center align-items-center px-3 my-sm-3 my-3 col-6'> <a href="https://www.kubota.com/" target="_blank" rel="noopener noreferrer"> <img src={Kubota} id="images" alt="kubota" className=' img-fluid roundedImages' /></a></Col>
                    <Col md={3} sm={6} className='d-flex justify-content-center align-items-center px-3 my-sm-3 my-3 col-6'> <a href="https://www.linde.com/" target="_blank" rel="noopener noreferrer"><img src={Linde} id="images" alt="Linde" className=' img-fluid roundedImages' /></a></Col>
                </Row>
                <div className='rotateDivs1 shadow-lg '></div>
                <div className='rotateDivs2'></div>
            </div></div>


        </>
    )
}

export default chasma_customer_clint_cards