import React, { useEffect } from 'react'
import './sumtotal_analytics.css';
import '../../styles/Banner.css'
import ServiceCardsSumtotalAnalytics from './sumtotal_analytics_service_cards';
import GetredycardSumtotalAnalytics from './sumtotal_analytics_getredypink_card';
import Banner from '../chasma_analytics/Banner'
import { Seo } from '../SEO/seo';






const SumtotalAnalytics = () => {

    useEffect(() => {
        document.title = 'SumTotal Analytics';
    }, []);

    return (
        <>
            <Seo
                title= " Chasma Analytics & Reporting for SumTotal | Data Visualizations for SumTotal "
                description= " Chasma Analytics provides dashboards and visualizations for hundreds of use cases for your KPIs and metrics, ready to deploy directly in SumTotal Advanced Reporting."
                type= "website"
                name= "Chasma SumTotal Analytics"
                url= 'https://chasma.io/chasmasumtotalanalytics '
                image='https://chasma.io/static/media/Chasma-Sumtotal-analytics.6d56bc6e.png'
            />
            <Banner heading='Chasma Analytics For SumTotal' imgURL='Chasma-Sumtotal-analytics' caption='130+ Dashboards and Visualizations for SumTotal Advanced Reporting'/>
            <ServiceCardsSumtotalAnalytics />
            <GetredycardSumtotalAnalytics />

        </>
    )
}

export default SumtotalAnalytics