import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import '../../styles/chasma_home_bannerpage_home01.css'
import Section1 from './chasma_home_section1'
import Section2 from './chasma_home_section2'
import Section3 from './chasma_home_section3';
import Section4 from './chasma_home_section4';
import Bgvideo from '../../assets/Home.mp4'
import BannerVideo from '../../assets/BannerVideoSmallScreens.mp4'






const Bannerpage_home01 = () => {
    useEffect(() => {
        document.title = 'Chasma';
    }, []);


    const [videoSource, setVideoSource] = useState(Bgvideo);

    useEffect(() => {
        window.innerWidth > 800 ? setVideoSource(Bgvideo) : setVideoSource(BannerVideo);
    }, [videoSource, setVideoSource])


    return (
        <>




            <Container fluid="md" className='vodeoContainer' lg={12} md={12} sm={12} xs={12} style={{ height: '375px' }}>
                <Row className={`textContainer justify-content-center pt-lg-3 `} style={{}}>
                    <video className={`bgimg img-fluid`} loop autoPlay muted controls={false} playsInline>
                        <source src={videoSource} type="video/mp4" />
                    </video>

                    <Col lg={8} xl={8} md={10} sm={12} className="text-center  text-over ">
                        <h1 className={`fs-auto  fw-bold text-white`}>Technology For The Most Complex Machine On The Earth
                        </h1>
                        <p className=' text-white'>Companies of all sizes—from startups
                            to Fortune 500s—use Chasma Place to modernize, complement,
                            and enhance their employee experience with ease.
                        </p>

                        <div className={` row justify-content-center`}>
                            <Col className={`col-6 d-flex justify-content-center`}>
                                <a href="https://app.chasma.io/register" target='blank' className='text-white'>
                                    <Button className={`btn btn-warning  text-white`}>
                                        Sign Up
                                    </Button>
                                </a>
                            </Col>


                        </div>


                    </Col>
                </Row>
            </Container>
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
        </>
    )
}


export default Bannerpage_home01;