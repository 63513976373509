import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom'
import Navbar from './Components/Navbar/chasma_home_navbar'
import Home from './Components/Home_page/chasma_home'
import ChasmaEditor from './Components/chasma_editor/chasma_editor'
import CornerstoneAnalytics from './Components/Cornerstone_Analytics/Cornerstone_Analytics'
import ChasmaApps from './Components/chasma_apps/chasma_apps';
import ChasmaAnalytics from './Components/chasma_analytics/chasma_analytics';
import ChasmaConnect from './Components/chasma_connect/chasma_connect';
import ChasmaEvents from './Components/chasma_events/chasma_events';
import ChasmaQuale from './Components/chasma_Quale/chasma_Quale';
import ChasmaLexy from './Components/chasma_Lexy/chasma_Lexy';
import ChasmaNatioDesk from './Components/chasma_Natio_desk/chasma_Natio_Desk';
import SumtotalAnalytics from './Components/sumtotal_analytics/sumtotal_analytics';
import ChasmaBotbuilder from './Components/chasma_Botbuilder/chasma_Botbuilder';
import ChasmaCible from './Components/chasma_cibile/chasma_Cible';
import PeopleAnalyticswithHRBOX from './Components/people_analytics_with_hrbox/people_hrbox';
import PeopleAnalyticswithPowerBi from './Components/people_analytics_with_powerbi/people_powerbi';
import WorkdayAnalytics from './Components/Workday_Analytics/Workday_Analytics';
import Footer from './Components/Footer/chasma_footer'
import Whychasma from './Components/why_chasma_page/WheelAnimation';
import ContactUs from './Components/chasma_contact_us/contact_us';
import Demo from '../src/Components/Demo_Sales/Demo'
import SalesPage from './Components/Demo_Sales/SalesPage';
import PrivacyPolicy from './Components/privacy_Policy/privacy_Policy'
import RefundPolicy from './Components/Refund_Policy/Refund_Policy';
import CookiePolicy from './Components/Cookie_policy/Cookie_Policy';
import TermsAndConditions from './Components/TermsConditions/TermsConditions';
import './styles/chasma_home_navbar.css'
import EULA from './Components/EULA/EULA';
import DisClaimer from './Components/Disclaimer/Disclaimer';
import SiteMap from './Components/Site_Map/SiteMap';
import ContactSupport from './Components/chasma_contact_support/Contact_Support'
import Customers from './Components/chasma_customers/customers'




function App() {
  return (
    <>
    <Navbar/>

      <Routes>
        <Route path="" element={<Home />}> </Route>
        <Route path="/home" element={<Home />}> </Route>
        <Route path="/chasmaapps/chasmaeditor" element={<ChasmaEditor />}> </Route>
        <Route path="/chasmaapps"  element={<ChasmaApps />}></Route>
        <Route path="/chasmaanalytics" element={<ChasmaAnalytics />}></Route>
        <Route path="/chasmaconnect" element={<ChasmaConnect />}></Route>
        <Route path="/aboutus" element={< Whychasma />}></Route>
        <Route path="/chasmaanalytics/cornerstone-analytics" element={<CornerstoneAnalytics />}></Route>
        <Route path='/chasmaapps/chasmaevent' element={<ChasmaEvents />}></Route>
        <Route path="/chasmaapps/chasmaquale" element={<ChasmaQuale />}></Route>
        <Route path='/chasmaapps/chasmalexy' element={<ChasmaLexy />}></Route>
        <Route path="/chasmaapps/chasmanatiodesk" element={<ChasmaNatioDesk />}></Route>
        <Route path="/chasmaanalytics/sumtotal-analytics" element={<SumtotalAnalytics />}></Route>
        <Route path='/chasmaapps/chasmabotbuilder' element={<ChasmaBotbuilder />}></Route>
        <Route path="/chasmaapps/chasmacible" element={<ChasmaCible />}></Route>
        <Route path='/chasmaanalytics/chasmahrbox' element={<PeopleAnalyticswithHRBOX />}></Route>
        <Route path='/chasmaanalytics/people-analytics-power-bi' element={<PeopleAnalyticswithPowerBi />}></Route>
        <Route path='/chasmaanalytics/workday-analytics' element={<WorkdayAnalytics />}></Route>
        <Route path='/contactus' element={<ContactUs/>}></Route>
        <Route path='/demo' element={<Demo/>}></Route>
        <Route path='/support' element={<ContactSupport/>}></Route>
        <Route path='/sales' element={<SalesPage/>}></Route>
        <Route path='privacypolicy' element={<PrivacyPolicy/>}></Route>
        <Route path='/refundpolicy' element={<RefundPolicy/>}></Route>
        <Route path='/cookiepolicy' element={<CookiePolicy/>}></Route>
        <Route path='/termsConditions' element={<TermsAndConditions/>}></Route>
        <Route path='/eula' element={<EULA/>}></Route>
        <Route path='/disclaimer' element={<DisClaimer/>}></Route>
        <Route path='/sitemap' element={<SiteMap/>}></Route>
        <Route path='/customers' element={<Customers/>}></Route>
      </Routes>

      <Footer />
    </>

  );
}

export default App;
