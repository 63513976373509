
import React, { useEffect } from 'react'
import '../../styles/Banner.css'
import './Workday_Analytics.css';
import ServiceCardsWorkdayAnalytics from './Workday_Analytics_service_cards';
import GetredycardWorkdayAnalytics from './Workday_Analytics_getredypink_card';
import Banner from '../chasma_analytics/Banner';
import { Seo } from '../SEO/seo';







const Workday_Analytics = () => {

    useEffect(() => {
        document.title = 'SumTotal Analytics';
    }, []);

    return (
        <>
            <Seo
                title= "Chasma Reporting & Analytics for Workday - HR Analytics and Dashboards"
                description= "Chasma Analytics provides tools in Workday to measure efficiency, create strategic alignment, and drive profitable growth."
                type= "website"
                name= "Chasma Workday Analytics"
                url= 'https://chasma.io/chasmaworkdayanalytics '
                image='https://chasma.io/static/media/chasma-workday.e5cb7cd8.png'
            />
            <Banner heading='Chasma Analytics For Workday®' imgURL='chasma-workday' caption='Rapid Deployment of Human Capital Dashboards and Visualization'/>
            <ServiceCardsWorkdayAnalytics />
            <GetredycardWorkdayAnalytics />

        </>
    )
}

export default Workday_Analytics