import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import './chasma_editor.css';



// icon images------------

import EmbedComponents from '../../../src/assets/editor-icons/Embed-Components.png'
import DragandDropInterface from '../../assets/editor-icons/drag-and-drop.png'
import PlugandPlayIntegrations from '../../assets/editor-icons/plug-and-play.png'
import CollaborationTools from '../../assets/editor-icons/collaboration.png'
import NoComplexProgramming from '../../assets/editor-icons/coding.png'
import Gamification from '../../assets/editor-icons/Gamification.png'


function serviceCards() {
    return (
        <>
            <div className='backgroundColor'>
                <Container className='pt-lg-2 stars-attach' >
                  
                    <Row className='justify-content-center align-items-center'>
                        <Col md={12} lg={11} className='col-12 text-white'>

                            <div className='text-center pt-5 px-lg-5 '>

                                <h1 className='fw-bold'>How Chasma Editor Speeds Up Your Development</h1>
                                <p className='py-md-4 px-md-5 px-2 '>Chasma Editor is an open canvas with drag and drop components you can use to build an experience unique to your enterprise and users. Each embedded Chasma template has user-friendly WYSIWYG design tools that do not require programming skills.</p>

                            </div></Col>
                    </Row>
                    <Row className='justify-content-center align-items-center pt-5'>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_editor px-md-4 p-3  pt-5">
                                    <div className=" d-flex align-items-center">

                                        <div className=''>
                                            <img src={EmbedComponents} alt="Embed Components" className='img-fluid' />
                                        </div>   <div className="fs-5 px-3 p-1 fw-bold">Embed Components</div>

                                    </div>
                                    <p className="py-3">Embed components, widgets, leaderboards and other motivational tools.</p>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_editor px-md-4 p-3 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={DragandDropInterface} alt="Drag-and-Drop Interface" className='img-fluid' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Drag-and-Drop Interface</div>

                                    </div>
                                    <p className="py-3">Add elements to your canvas using a simple drag-and-drop interface.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_editor px-md-4 p-3 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={PlugandPlayIntegrations} alt="Plug and Play Integrations" className='img-fluid' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Plug and Play Integrations</div>

                                    </div>
                                    <p className="py-3">Plug and play integrations with your application data sets. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </p>
                                </div>
                            </div>

                        </Col>


                        {/*------------ first 3 coloms ------------------*/}
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 py-lg-5 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_editor px-md-4 p-3 pt-5" >
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={CollaborationTools} alt="Collaboration Tools" className='img-fluid' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Collaboration Tools</div>

                                    </div>
                                    <p className="py-3">Collaboration tools embedded directly into your dashboards or landing pages.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3  py-lg-5 my-lg-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_editor px-md-4 p-3 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={NoComplexProgramming} alt="No Complex Programming" className='img-fluid' />
                                        </div>   <div className="fs-5 px-3 fw-bold">No Complex Programming</div>

                                    </div>
                                    <p className="py-3">Provide easy access to targeted information without complex programming.</p>
                                </div>
                            </div>
                        </Col>

                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12 py-lg-5'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card_editor px-md-4 p-3 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={Gamification} alt="Gamification" className='img-fluid' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Gamification</div>

                                    </div>
                                    <p className="py-3">Leaderboards and a gamified experience to motivate employees.</p>
                                </div>
                            </div>

                        </Col>

                    </Row>


                </Container>
            </div></>
    )
}

export default serviceCards