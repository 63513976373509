import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import './people_hrbox.css';
import CompleteLibraryOfTemplates from '../../assets/Chasma_HRBox-icons/Complete_Library.png'
import APIDataConnections from '../../assets/Chasma_HRBox-icons/API_Data_Connections.png'
import EasyDashboardDesign from '../../assets/Chasma_HRBox-icons/Eas_Dashboard_Design.png'
import ControlYourData from '../../assets/Chasma_HRBox-icons/Control_Your_Data.png'
import CreateaUnifiedHRDashboard from '../../assets/Chasma_HRBox-icons/Create_a_Unified_HR.png'
import OneClickDashboardSharing from '../../assets/Chasma_HRBox-icons/share.png'



function serviceCardsPeopleHRBOX() {
    return (
        <>
            <div className='backgroundColor'>
                <Container className='pt-lg-2 stars-attach' >
                   <Row className='justify-content-center align-items-center'>
                        <Col md={8} lg={11} className='col-12 text-white'>

                            <div className='text-center pt-5 px-lg-5 '>

                                <h1 className='fw-bold'>Chasma HRBox Advantage</h1>
                                <p className='py-md-4 px-md-5 px-2 '>Use data from any of your HR applications to create visual insights and group them into dashboards without coding.</p>

                            </div></Col>
                    </Row>
                    <Row className='justify-content-center align-items-center pt-5 move'>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2  px-3 col-12'>
                            <div className="obj-backward ">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className=" d-flex align-items-center">

                                        <div className=''>
                                            <img src={CompleteLibraryOfTemplates} alt="Complete Library of Templates" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Complete Library of Templates</div>

                                    </div>
                                    <p className="py-3">No programming skills required to create deep insights into HR operations.</p>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={APIDataConnections} alt="API Data Connections" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">API Data Connections</div>

                                    </div>
                                    <p className="py-3">Connect to any HR application or data source.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={EasyDashboardDesign} alt="Easy Dashboard Design" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Easy Dashboard Design</div>

                                    </div>
                                    <p className="py-3">Drag-and-drop visualizations into visual design framework.
                                    </p>
                                </div>
                            </div>

                        </Col>


                        {/*------------ first 3 coloms ------------------*/}
                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 py-lg-5 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={ControlYourData} alt="Control Your Data" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Control Your Data</div>

                                    </div>
                                    <p className="py-3">Create security groups to control sensitive data.</p>
                                </div>
                            </div>

                        </Col>


                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3  py-lg-5 my-lg-3 col-12'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={CreateaUnifiedHRDashboard} alt="Create a Unified HR Dashboard" className='' />
                                        </div>   <div className="fs-5 px-3 fw-bold">Create a Unified HR Dashboard</div>

                                    </div>
                                    <p className="py-3">Keep managers and business leaders informed of metrics important to them.</p>
                                </div>
                            </div>
                        </Col>

                        <Col md={6} lg={4} className='d-flex justify-content-center my-2 px-3 col-12 py-lg-5'>
                            <div className="obj-backward">

                                <div className="mx-auto card heightof_card px-4 pt-5">
                                    <div className="d-flex align-items-center">
                                        <div className=''>
                                            <img src={OneClickDashboardSharing} alt="One-Click Dashboard Sharing" className='img-fluid' />
                                        </div>   <div className="fs-5 px-3 fw-bold">One-Click Dashboard Sharing</div>

                                    </div>
                                    <p className="py-3">Share dashboards with anyone in your organization.</p>
                                </div>
                            </div>

                        </Col>

                    </Row>


                </Container>
            </div></>
    )
}

export default serviceCardsPeopleHRBOX