import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { BiChevronRight } from "react-icons/bi";
import Sales from '../../assets/chasma-contact-us/sales.png'
import Contact_Us from '../../assets/chasma-contact-us/call.png'
import Help_Support from '../../assets/chasma-contact-us/help&support.png'
import { Link } from "react-router-dom";
const SalesContactUsContainer = () => {
    const handleLinkClick = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };
    return (
        <>
            <Row className='align-items-between row1 mt-4'>
                    <Col className='card-1 card d-flex py-5  rounded-4 card_animation col-8 my-lg-0 my-sm-2 my-2 ' md={10} lg={3} sm={9}>
                        <div className='text-center d-flex flex-column justify-content-between h-100'>
                            <div>
                                 <img
                                src={Sales}
                                alt=""
                                className='img-fluid'
                            />
                            </div>
                            <div>
                                 <h2 className='fw-bold py-3'>Sales</h2>

                            <p className=''>Discover solutions tailored to your needs. Our experienced sales team is here to help! Contact us today.</p>

                            <span>
                                <a href="mailto:info@chasma.io" className='text-primary'> info@chasma.io</a>
                            </span>
                            </div>
                           

                            <div>

                                <Button onClick={handleLinkClick()} as={Link} to={'/sales'} className=' myButton btn btn-warning rounded-pill text-dark my-3'>
                                    Contact sales <BiChevronRight />
                                </Button>
                            </div>

                        </div>

                    </Col>
                    <Col className='card-1 card d-flex py-5  rounded-4 card_animation col-8  my-lg-0 my-sm-2 my-2' md={10} lg={3} sm={9}>
                        <div className='text-center d-flex flex-column justify-content-between h-100'>
                            <div className='mb-4'>
                                 <img
                                src={Contact_Us}
                                alt=""
                                className='img-fluid'
                            />
                            </div>
                            <div>
                                 <h2 className='fw-bold py-3 mt-sm-4 mt-lg-0'>Contact Us</h2>

                            <p className='m-0'>Unleash the Power of People. Let's Talk.</p>
                            <p className='m-0 mb-2'>
                            Need help transforming your HR tech and empowering your workforce? You've arrived at your destination.
                            </p>
                            <span>
                                <a href="tel:+1 (855) 978-6816" className='text-primary'> +1 (855) 978-6816</a>
                            </span>
                            </div>
                           

                            <div>

                            <Button onClick={handleLinkClick()} as={Link} to={'/contactus'} className=' myButton btn btn-warning rounded-pill text-dark my-3'>
                                    Contact Us <BiChevronRight />
                                </Button>
                            </div>

                        </div>

                    </Col>
                    
                    
                    <Col className='card-1 card d-flex py-5  rounded-4 card_animation col-8  my-lg-0 my-sm-2 my-2' md={10} lg={3} sm={9}>
                        <div className='text-center d-flex flex-column justify-content-between h-100'>
                            <div>
                                 <img
                                src={Help_Support}
                                alt=""
                                className='img-fluid'
                            />
                            </div>
                            <div>
                                 <h2 className='fw-bold py-3 '>Help & Support</h2>

                            <p className='mb-lg-0'>Welcome to Chasma Help and Support Center.</p>
                            <p>We are here to help in all your technology requirements</p>

                            <span>
                                <a href="mailto:support@chasma.io" className='text-primary'> support@chasma.io</a>
                            </span>
                            </div>
                           

                            <div>

                            <Button onClick={handleLinkClick()} as={Link} to={'/support'} className='btn btn-warning rounded-pill text-dark my-3 myButton'>
                                    Contact support <BiChevronRight />
                                </Button>
                            </div>

                        </div>

                    </Col>
                    
                </Row>

        </>
    )

}
export default SalesContactUsContainer;