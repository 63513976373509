import React from "react";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import "./chasma_apps.css";

// icon images------------

import OutoftheboxApps from "../../assets/Embed_components.png";
import ModernUserInterface from "../../assets/Modern User Interface.png";
import CloudAdvantage from "../../assets/Cloud Advantage.png";
import RapidImplementation from "../../assets/Rapid Implementation.png";
import SupportTools from "../../assets/Support Tools.png";
import Collaboration from "../../assets/Gamification.png";
import Unifyuserexperience from "../../assets/Unify user experience.png";
import DataQuality from "../../assets/Data Quality.png";
import AutomatedProcesses from "../../assets/Gamification.png";

const chasmaAppsServiceCards =()=> {
  return (
    <>
      <div className="backgroundColor">
        <Container className="pt-lg-2 stars-attach">
        
          <Row className="justify-content-center align-items-center">
            <Col md={8} lg={11} className="col-12">
              <div className="text-center pt-5 px-lg-5 text-white ">
                <h2 className="fw-bold">Chasma Apps Advantage</h2>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center pt-5">
            {/*------------ first 3 coloms ------------------*/}
            <Col
              md={6}
              lg={4}
              className="d-flex justify-content-center my-2  px-3 col-12"
            >
              <div className="obj-backward">
                <div className="mx-auto card heightof_card_apps px-4 pt-5">
                  <div className=" d-flex align-items-center">
                    <div className="">
                      <img
                        src={OutoftheboxApps}
                        alt="Pre-configured
                                            Dashboard Designs-icon"
                        className="img-fluid"
                      />
                    </div>{" "}
                    <div className="fs-5 px-3 fw-bold">Out-of-the-box Apps</div>
                  </div>
                  <p className="py-3">
                    Apps that avoid the disruption and expense of an enterprise
                    system replacement and complement your current software.
                  </p>
                </div>
              </div>
            </Col>
            <Col
              md={6}
              lg={4}
              className="d-flex justify-content-center my-2 px-3 col-12"
            >
              <div className="obj-backward">
                <div className="mx-auto card heightof_card_apps px-4 pt-5">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img
                        src={ModernUserInterface}
                        alt="Pre-configured
                                            Dashboard Designs-icon"
                        className="img-fluid"
                      />
                    </div>{" "}
                    <div className="fs-5 px-3 fw-bold">
                      Modern User Interface
                    </div>
                  </div>
                  <p className="py-3">
                    Remove the bottlenecks and friction of an outdated user
                    interface.
                  </p>
                </div>
              </div>
            </Col>

            <Col
              md={6}
              lg={4}
              className="d-flex justify-content-center my-2 px-3 col-12"
            >
              <div className="obj-backward">
                <div className="mx-auto card heightof_card_apps px-4 pt-5">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img
                        src={CloudAdvantage}
                        alt="Pre-configured
                                            Dashboard Designs-icon"
                        className="img-fluid"
                      />
                    </div>{" "}
                    <div className="fs-5 px-3 fw-bold">Cloud Advantage</div>
                  </div>
                  <p className="py-3">
                    Enjoy the stability and security of the cloud.
                  </p>
                </div>
              </div>
            </Col>

            {/*------------ second 3 coloms ------------------*/}
            <Col
              md={6}
              lg={4}
              className="d-flex justify-content-center my-2 px-3 py-lg-5 col-12"
            >
              <div className="obj-backward">
                <div className="mx-auto card heightof_card_apps px-4 pt-5">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img
                        src={RapidImplementation}
                        alt="Pre-configured
                                            Dashboard Designs-icon"
                        className="img-fluid"
                      />
                    </div>{" "}
                    <div className="fs-5 px-3 fw-bold">
                      Rapid Implementation
                    </div>
                  </div>
                  <p className="py-3">
                    Implement solutions in days or weeks instead of months or
                    years.
                  </p>
                </div>
              </div>
            </Col>

            <Col
              md={6}
              lg={4}
              className="d-flex justify-content-center my-2 px-3  py-lg-5 my-lg-3 col-12"
            >
              <div className="obj-backward">
                <div className="mx-auto card heightof_card_apps px-4 pt-5">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img
                        src={SupportTools}
                        alt="Pre-configured
                                            Dashboard Designs-icon"
                        className="img-fluid"
                      />
                    </div>{" "}
                    <div className="fs-5 px-3 fw-bold">Support Tools</div>
                  </div>
                  <p className="py-3">
                    Reduce support calls by giving your users all the tools they
                    need to get things done in the flow of work.
                  </p>
                </div>
              </div>
            </Col>

            <Col
              md={6}
              lg={4}
              className="d-flex justify-content-center my-2 px-3 col-12 py-lg-5"
            >
              <div className="obj-backward">
                <div className="mx-auto card heightof_card_apps px-4 pt-5">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img
                        src={Collaboration}
                        alt="Pre-configured
                                            Dashboard Designs-icon"
                        className="img-fluid"
                      />
                    </div>{" "}
                    <div className="fs-5 px-3 fw-bold">Collaboration</div>
                  </div>
                  <p className="py-3">
                    Facilitate collaboration inside your organization and with
                    the partners in your value chain.
                  </p>
                </div>
              </div>
            </Col>
            {/*------------ third 3 coloms ------------------*/}

            <Col
              md={6}
              lg={4}
              className="d-flex justify-content-center my-2 px-3 py-lg-5 col-12"
            >
              <div className="obj-backward">
                <div className="mx-auto card heightof_card_apps px-4 pt-5">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img
                        src={Unifyuserexperience}
                        alt="Pre-configured
                                            Dashboard Designs-icon"
                        className="img-fluid"
                      />
                    </div>{" "}
                    <div className="fs-5 px-3 fw-bold">
                      Unify user experience
                    </div>
                  </div>
                  <p className="py-3">
                    Unify user experience with no code dashboards for user
                    portals and landing pages.
                  </p>
                </div>
              </div>
            </Col>

            <Col
              md={6}
              lg={4}
              className="d-flex justify-content-center my-2 px-3  py-lg-5 my-lg-3 col-12"
            >
              <div className="obj-backward">
                <div className="mx-auto card heightof_card_apps px-4 pt-5">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img
                        src={DataQuality}
                        alt="Pre-configured
                                            Dashboard Designs-icon"
                        className="img-fluid"
                      />
                    </div>{" "}
                    <div className="fs-5 px-3 fw-bold">Data Quality</div>
                  </div>
                  <p className="py-3">Improve the quality of your data.</p>
                </div>
              </div>
            </Col>

            <Col
              md={6}
              lg={4}
              className="d-flex justify-content-center my-2 px-3 col-12 py-lg-5"
            >
              <div className="obj-backward">
                <div className="mx-auto card heightof_card_apps px-4 pt-5">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img
                        src={AutomatedProcesses}
                        alt="Pre-configured
                                            Dashboard Designs-icon"
                        className="img-fluid"
                      />
                    </div>{" "}
                    <div className="fs-5 px-3 fw-bold">Automated Processes</div>
                  </div>
                  <p className="py-3">
                    Automate manual processes to save your hours of work.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default chasmaAppsServiceCards;
