

import React, { useEffect } from 'react'
import EditorImage from '../../assets/PrivacyPolicy.png'
import './Refund_Policy.css'
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/esm/Container';
import ClientCards from'../Cornerstone_Analytics/Cornerstone_Analytics_clint_cards'
import { Seo } from '../SEO/seo';

const RefundPolicy = () => {

    useEffect(() => {
        document.title = 'Chasma | Refund Policy';
    }, []);

    return (
        <>
            <Seo
                title= "Chasma Place | Refund Policy"
                description= "Chasma’s innovative suite of HR apps, analytics &amp; connectors offer agile solutions for your human resources technology challenges &amp; improve your HR efficiency. "
                type= "website"
                name= "Chasma Power BI"
                url= 'https://chasma.io/refund-policy'
                image= 'https://chasma.io/static/media/PrivacyPolicy.a0953fc2.png'
            />
            <Container className='bannerPrivacyPolicy'>
                <div className='w-50  d-flex  align-items-center '>
                    <h1>Refund Policy</h1>
                </div>
                <div className='w-50'><img src={EditorImage} className='img-fluid' alt="chasma-privacy-policy" /></div>
            </Container>
            <div className='mt-4 px-5 container '>
                <h4 className='text-center fw-bold mt-4 mb-4 '>Returns and Refunds Policy</h4>
                <Row>

                    <p>Thank you for shopping at Chasma.</p>
                    <p>
                    Please read this policy carefully. This is the Return and Refund Policy of Chasma. This Return and Refund Policy is licensed by TermsFeed Generator to Chasma.
                    </p>
                    </Row>
                <Row>
                    <h4>Digital Products</h4>
                    <p>We issue refunds for digital products within 30 days of the original purchase of the product.</p>
                    <p>
                    We recommend contacting us for assistance if you experience any issues receiving or downloading our products.
                    </p>


                </Row>
                <Row>
                    <h4>Contact Us</h4>
                    <p>
                    If you have any questions about our Returns and Refunds Policy, please contact us: <a href="mailto:support@chasma.io" className='text-primary'> support@chasma.io</a>
                    </p>
                   

                </Row>
                
                <Row>
                    <ClientCards/>
                </Row>
            </div>
        </>
    )
}

export default RefundPolicy